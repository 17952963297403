import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FormTextField } from '../../formComponents/FormTextField';
import { OrderPagePrice } from '../../OrderPageSettingsPage';

type BasePricingFieldsProps = {
  price: OrderPagePrice;
  index: number;
  formIsDisabled: boolean;
  currencySymbol: string;
};

export const BasePricingFields: React.FC<BasePricingFieldsProps> = ({
  price,
  index,
  formIsDisabled,
  currencySymbol,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container alignItems="center">
      <Grid container alignItems="center">
        <Grid item>
          <Typography>{t('editOrderPage.basePrice')}</Typography>
        </Grid>
        <Grid item mx={1}>
          <FormTextField
            type="number"
            isFieldUpdate
            width={100}
            fieldName={`price[${index}].base_price`}
            disabled={formIsDisabled}
            label={''}
            endAdornment={currencySymbol}
          />
        </Grid>
        <Typography>{t('editOrderPage.for')}</Typography>
        <Grid item mx={1}>
          <FormTextField
            type="number"
            isFieldUpdate
            width={50}
            fieldName={`price[${index}].base_value`}
            disabled={formIsDisabled}
            label={''}
          />
        </Grid>
        <Grid item>
          <Typography>
            {price.base_value && price.base_value > 1 ? t('editOrderPage.persons') : t('editOrderPage.person')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container alignItems="center" mt={1}>
        <Typography>{t('editOrderPage.forAdditional')}</Typography>
        <Grid item mx={1}>
          <FormTextField
            type="number"
            isFieldUpdate
            width={100}
            fieldName={`price[${index}].price_per_additional_value`}
            disabled={formIsDisabled}
            endAdornment={currencySymbol}
            label={''}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
