import { ButtonBase, Grid, Icon } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

type MenuButtonProps = {
  name: string;
  icon?: string;
  isSubMenuItem?: boolean;
  setIsSidebarOpen: (isSidebarOpen: boolean) => void;
  itemIsActive?: boolean;
  setIsExpanded?: (isExpanded: boolean) => void;
};

export const MenuButton = ({
  name,
  icon,
  isSubMenuItem,
  setIsSidebarOpen,
  itemIsActive,
  setIsExpanded,
}: MenuButtonProps) => {
  return (
    <Grid
      container
      sx={{
        marginInlineStart: isSubMenuItem ? '16px' : undefined,
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: itemIsActive ? undefined : '#FFFFFF14',
        },
      }}>
      <ButtonBase
        sx={{
          height: isSubMenuItem ? '28px' : '44px',
          '&:active': {
            backgroundColor: 'rgba(103,173,91,0.38)',
          },
          width: '100%',
          fontFamily: 'Assistant',
        }}
        TouchRippleProps={{
          style: { color: '#67AD5B' },
        }}
        onClick={() => (setIsExpanded ? setIsExpanded(true) : setIsSidebarOpen(false))}>
        <Grid container alignItems="center">
          <Icon
            style={{
              fontWeight: itemIsActive ? 700 : undefined,
              color: itemIsActive ? '#67AD5B' : 'white',
            }}
            sx={{
              width: 0,
              direction: 'ltr!important',
              fontSize: isSubMenuItem ? '14px' : '18px',
              paddingX: '16px',
            }}>
            {icon}
          </Icon>
          <Grid
            sx={{
              color: 'white',
              lineHeight: '1.5',
              whiteSpace: 'normal',
              fontSize: '14px',
              paddingInlineStart: isSubMenuItem ? '0px' : '8px',
            }}>
            <Typography
              variant={isSubMenuItem ? 'body2' : 'subtitle2'}
              fontWeight={itemIsActive ? 700 : undefined}
              color={itemIsActive ? '#67AD5B' : 'undefined'}
              fontFamily="Assistant">
              {name}
            </Typography>
          </Grid>
        </Grid>
      </ButtonBase>
    </Grid>
  );
};
