import './SliderUiObject.scss';
import React, { useState } from 'react';
import { Grid, Slider, ThemeProvider, useMediaQuery, useTheme } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import { usePostHog } from 'posthog-js/react';
import { cacheLtr, useLanguage } from '../../common/GeneralUtils';
import NumberSelector from '../../common/ui/NumberSelector';
import { OrderPageTypography } from '../themedComponents/OrderPageTypography';

const SliderUiObject = (props) => {
  const posthog = usePostHog();
  const { uiObject, orderState, setOrderDetailValue, type, isLoadingDates } = props;

  const language = useLanguage();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [sliderValue, setSliderValue] = useState(orderState.details[uiObject.data_type]);
  let minValue = type === 'edit' ? 1 : uiObject.min_value;
  // to allow admins create bookings for even one participant
  let maxValue = uiObject.max_value;

  const onSliderCommit = (val) => {
    posthog.startSessionRecording({});
    setOrderDetailValue(uiObject.data_type, val);
  };
  let marks = [
    {
      value: minValue,
      label: <OrderPageTypography>{minValue}</OrderPageTypography>,
    },
    {
      value: uiObject.max_value,
      label: <OrderPageTypography>{uiObject.max_value}</OrderPageTypography>,
    },
  ];

  if (type === 'edit' && uiObject.dashboardNoLimit) {
    marks = [
      {
        value: 1,
        label: <OrderPageTypography>1</OrderPageTypography>,
      },
      {
        value: 70,
        label: <OrderPageTypography>30</OrderPageTypography>,
      },
    ];
    minValue = 1;
    maxValue = 70;
  }
  const sliderText = uiObject.label[language];
  return (
    <Grid container flexDirection="column">
      <Grid container flexDirection={isSmallScreen ? 'column' : 'row'} justifyContent="space-between" gap={2}>
        <OrderPageTypography variant="h3" bold>
          {sliderText}
        </OrderPageTypography>
        <Grid>
          <NumberSelector
            value={sliderValue}
            setValue={(val) => {
              setSliderValue(val);
              onSliderCommit(val);
            }}
            disabled={minValue === uiObject.max_value || isLoadingDates}
            min={minValue}
            max={uiObject.max_value}
          />
        </Grid>
      </Grid>
      <Grid item my={isSmallScreen ? 0 : 3} mx={3}>
        <CacheProvider value={cacheLtr}>
          <ThemeProvider theme={{ ...theme, direction: 'ltr' }}>
            <Slider
              defaultValue={minValue}
              value={sliderValue}
              min={minValue}
              max={maxValue}
              marks={marks}
              disabled={minValue === uiObject.max_value}
              onChange={(e, val) => setSliderValue(val)}
              onChangeCommitted={(e, val) => onSliderCommit(val)}
              aria-label="Default"
            />
          </ThemeProvider>
        </CacheProvider>
      </Grid>

      {uiObject.post_label && (
        <div className="text-label" dangerouslySetInnerHTML={{ __html: uiObject.post_label[language] }} />
      )}
    </Grid>
  );
};

export default SliderUiObject;
