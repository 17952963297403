/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PluginTypes {
  WEEKEND_AND_ISRAELI_HOLIDAYS = 'weekendAndIsraeliHolidays',
  FREE_ESCORTS = 'freeEscorts',
}
