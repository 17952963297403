import './PersonalDetailsStep.scss';
import React, { MutableRefObject, useState } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Modal,
  ToggleButtonGroup,
  useTheme,
} from '@mui/material';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { shuffle } from 'lodash';
import moment from 'moment';
import { useLanguage } from '../../common/GeneralUtils';
import PluginUtils, { PluginType } from '../PluginUtils';
import { OrderCustomDetails } from './VoucherSummary';
import { OrderPageTextField } from '../themedComponents/OrderPageTextField';
import { OrderPageTypography } from '../themedComponents/OrderPageTypography';
import { useOrderPage } from '../orderPageContext/useOrderPage';
import {
  AccountData,
  CustomPersonalDetailData,
  CustomPersonalDetailTypes,
  HolidayCalendar,
  OrderPageData,
  PaymentIntegrationType,
  ReferralSource,
} from '../../api';
import { RenderMultilineDetail } from './personalDetails/RenderMultilineDetail';
import { RenderBooleanDetail } from './personalDetails/RenderBooleanDetail';
import { FormStyledMenuItem, FormStyledPaper, RenderDropDownDetail } from './personalDetails/RenderDropDownDetail';
import { OrderPageSelect } from '../themedComponents/OrderPageSelect';
import { OrderPageCheckbox } from '../themedComponents/OrderPageCheckbox';
import { OrderPageIntent } from '../OrderPageIntent';
import { OrderPageToggleButton } from '../themedComponents/OrderPageToggleButton';
import CustomStaticDatePicker from './dateAndTimeStep/components/CustomStaticDatePicker';
import { DATE_FORMAT } from '../../calendar/consts';

export enum PageContext {
  CUSTOMER = 'customer',
  ADMIN = 'admin',
}

type PersonalDetailsStepProps = {
  formRef: MutableRefObject<any>;
  firstname?: string;
  lastname?: string;
  email?: string;
  phone?: string;
  custom_personal_details?: string;
  orderPage: any;
  setOrderCustomDetails?: (customDetails: OrderCustomDetails) => void;
  orderPageIntent?: OrderPageIntent;
  pageContext?: PageContext;
  setPreviewName?: (reviewName: string) => void;
  orderCustomDetails?: any;
  voucherGreetingMessage?: string;
  recipientEmail?: string;
  referralSource?: ReferralSource;
  deliveryDate?: string | null; // New prop for delayed sending
  deliveryTime?: string | null;
  holidayCalendar?: HolidayCalendar;
};
export type PersonalDetailsValues = {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  custom_personal_details: object;
  terms_conditions_checked: boolean;
  referral_source?: ReferralSource;
  voucher_greeting_message?: string;
  should_send_to_recipient?: boolean;
  recipient_email?: string;
  delivery_date?: string | null;
  delivery_time?: string | null;
  should_send_delayed?: boolean;
};
const getInitialCustomPersonalDetails = (customMandatoryBooleanFields: CustomPersonalDetailData[]) => {
  if (!customMandatoryBooleanFields.length) {
    return {};
  }
  const customPersonalDetails = {};
  for (const field of customMandatoryBooleanFields) {
    // @ts-ignore
    customPersonalDetails[field.id] = false;
  }
  return customPersonalDetails;
};
const PersonalDetailsStep = ({
  formRef,
  firstname,
  lastname,
  email,
  phone,
  custom_personal_details,
  orderPage,
  setOrderCustomDetails,
  orderPageIntent,
  pageContext = PageContext.CUSTOMER,
  setPreviewName,
  orderCustomDetails,
  voucherGreetingMessage,
  recipientEmail,
  referralSource,
  deliveryDate,
  deliveryTime,
  holidayCalendar,
}: PersonalDetailsStepProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { orderPageState, setOrderPageState } = useOrderPage();
  const isGiftCardIntent = orderPageIntent === OrderPageIntent.GIFT_CARD;

  const language = useLanguage();
  const [weekendAndIsraeliHolidaysChecked, setWeekendAndIsraeliHolidaysChecked] = useState(
    orderCustomDetails?.[PluginType.WEEKEND_AND_ISRAELI_HOLIDAYS] === true,
  );
  const [canSpam, setCanSpam] = useState(true);
  const [isDatePickerOpen, setIsDatePickerOpen] = React.useState(false);

  const account = orderPageState?.account as unknown as AccountData | undefined;
  const accountName = account?.name;
  const accountLabel = account?.label?.[language];
  const terms_conditions_link = account?.terms_conditions_link;
  // grow throw errors when non israeli phone number
  const shouldAllowOnlyIsraeliPhoneNumbers = account?.payment_integration_type === PaymentIntegrationType.GROW_PAYMENTS;
  // Currently supports only boolean mandatory fields
  const customMandatoryBooleanFields = (orderPage as OrderPageData).custom_personal_details?.filter(
    (x) => x.errorLabel,
  );
  const adminForGiftCardsValidationSchema = yup.object().shape({
    phone: yup.string().min(10, t('order_page.phone_valid')).required(t('order_page.phone_required')),
  });
  const emailValidation = yup
    .string()
    .test('is-valid-emails', t('order_page.email_valid'), (value) => {
      if (!value) return false;
      const emails = value.split(',').map((email) => email.trim());
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emails.every((email) => emailRegex.test(email));
    })
    .required(t('order_page.email_required'));
  let validationSchema = yup.object().shape({
    firstname: yup.string().required(t('order_page.firstname_required')),
    lastname: yup.string().required(t('order_page.lastname_required')),
    email: emailValidation,
    phone: yup.string().when('shouldAllowOnlyIsraeliPhoneNumbers', {
      is: true,
      then: yup
        .string()
        .matches(/^(05|972|\+972)[0-9]{8,}$/, t('order_page.phone_valid_israeli'))
        .required(t('order_page.phone_required')),
      otherwise: yup.string().min(10, t('order_page.phone_valid')).required(t('order_page.phone_required')),
    }),
    terms_conditions_checked: yup
      .boolean()
      .oneOf([true], t('order_page.terms_true'))
      .required(t('order_page.terms_true')),
    voucher_greeting_message: yup.string().optional(),
    should_send_to_recipient: yup.boolean().optional(),
    recipient_email: yup.string().when('should_send_to_recipient', {
      is: true,
      then: yup.string().email(t('order_page.email_valid')).required(t('order_page.email_required')),
      otherwise: yup.string().email(t('order_page.email_valid')).optional(),
    }),
  });

  if (customMandatoryBooleanFields?.length && !isGiftCardIntent) {
    const extraValidationSchema = {};
    for (const customField of customMandatoryBooleanFields) {
      // @ts-ignore
      extraValidationSchema[`${customField.id}`] = yup
        .boolean()
        .oneOf([true], customField.errorLabel?.[language])
        .required(customField.errorLabel?.[language]);
    }
    validationSchema = validationSchema.shape({
      custom_personal_details: yup.object().shape(extraValidationSchema),
    });
  }
  const handleSubmit = () => {};

  const initialValues: PersonalDetailsValues & {
    shouldAllowOnlyIsraeliPhoneNumbers: boolean;
  } = {
    firstname: firstname || '',
    lastname: lastname || '',
    email: email || '',
    phone: phone || '',
    custom_personal_details:
      custom_personal_details || getInitialCustomPersonalDetails(customMandatoryBooleanFields || []),
    terms_conditions_checked: pageContext === PageContext.ADMIN,
    shouldAllowOnlyIsraeliPhoneNumbers, // for the validation to be able to use it.
    voucher_greeting_message: voucherGreetingMessage || '',
    recipient_email: recipientEmail || '',
    should_send_to_recipient: !!recipientEmail,
    referral_source: referralSource,
    delivery_date: deliveryDate || moment().format('YYYY-MM-DD'),
    delivery_time: moment(deliveryTime || '15:00', 'HH:mm').format('HH:mm'),
    should_send_delayed: !!deliveryDate,
  };
  // Relevant when buying voucher for weekend prices
  const handleWeekendCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWeekendAndIsraeliHolidaysChecked(event.target.checked);
    if (setOrderCustomDetails) {
      setOrderCustomDetails({ [PluginType.WEEKEND_AND_ISRAELI_HOLIDAYS]: event.target.checked });
    }
  };
  const holidaysPlugin = PluginUtils.getPluginData(orderPage.plugins, PluginType.WEEKEND_AND_ISRAELI_HOLIDAYS);
  const percentString = t('order_page.weekend_checkbox', {
    percent: holidaysPlugin ? ((holidaysPlugin.multiply - 1) * 100.0).toFixed(0) : 0,
  });

  const handleCanSpam = () => {
    const newVal = !canSpam;
    setOrderPageState({ ...orderPageState, canSpam: newVal });
    setCanSpam(newVal);
  };
  const referralOptions = Object.entries(ReferralSource);
  return (
    <div className="personal-details-step">
      <Formik
        enableReinitialize
        onSubmit={handleSubmit}
        initialValues={initialValues}
        innerRef={formRef}
        validationSchema={
          pageContext === PageContext.ADMIN && isGiftCardIntent ? adminForGiftCardsValidationSchema : validationSchema
        }>
        {(formikProps) => {
          const { values, errors, touched, handleChange, handleSubmit, setFieldValue } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              {isGiftCardIntent && holidaysPlugin && (
                <Grid container flexDirection="column">
                  <OrderPageTypography variant="h6">{t('order_page.extras')}</OrderPageTypography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={weekendAndIsraeliHolidaysChecked}
                        onChange={handleWeekendCheckboxChange}
                        sx={{
                          color: theme.customTheme.palette.border,
                          '&.Mui-checked': {
                            color: theme.customTheme.palette.primary,
                          },
                        }}
                      />
                    }
                    label={percentString}
                  />
                </Grid>
              )}
              <Grid
                container
                flexDirection="column"
                border={isGiftCardIntent ? `1px solid ${theme.customTheme.palette.border}` : undefined}
                borderRadius={isGiftCardIntent ? '8px' : undefined}
                p={isGiftCardIntent ? 3 : undefined}
                mt={isGiftCardIntent ? 3 : undefined}>
                {isGiftCardIntent && (
                  <OrderPageTypography variant="h6">{t('order_page.gifter_personal_details')}</OrderPageTypography>
                )}
                <OrderPageTextField
                  fullWidth
                  className="text-field"
                  id="firstname"
                  name="firstname"
                  label={t('order_page.firstname')}
                  value={values.firstname}
                  onChange={(e) => {
                    if (setPreviewName) {
                      setPreviewName(`${e.target.value} ${values.lastname}`);
                    }
                    handleChange(e);
                  }}
                  error={touched.firstname && Boolean(errors.firstname)}
                  helperText={touched.firstname && errors.firstname}
                />
                <OrderPageTextField
                  fullWidth
                  className="text-field"
                  id="lastname"
                  name="lastname"
                  label={t('order_page.lastname')}
                  value={values.lastname}
                  onChange={(e) => {
                    if (setPreviewName) {
                      setPreviewName(`${values.firstname} ${e.target.value}`);
                    }
                    handleChange(e);
                  }}
                  error={touched.lastname && Boolean(errors.lastname)}
                  helperText={touched.lastname && errors.lastname}
                />
                <OrderPageTextField
                  fullWidth
                  className="text-field"
                  id="email"
                  name="email"
                  label={t('order_page.email')}
                  value={values.email}
                  onChange={(e: any) => {
                    e.target.value = e.target.value.trim();
                    handleChange(e);
                  }}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
                <OrderPageTextField
                  fullWidth
                  className="text-field"
                  id="phone"
                  name="phone"
                  label={t('order_page.phone')}
                  type="phone"
                  value={values.phone}
                  onChange={handleChange}
                  error={touched.phone && Boolean(errors.phone)}
                  helperText={touched.phone && errors.phone}
                />

                {orderPage.custom_personal_details?.length > 0 &&
                  !isGiftCardIntent &&
                  (orderPage as OrderPageData).custom_personal_details?.map((custom_personal_detail) => {
                    if (custom_personal_detail.type === CustomPersonalDetailTypes.MULTILINE) {
                      return (
                        <RenderMultilineDetail
                          customPersonalDetail={custom_personal_detail}
                          handleChange={handleChange}
                          values={values}
                        />
                      );
                    }
                    if (custom_personal_detail.type === CustomPersonalDetailTypes.BOOLEAN) {
                      return (
                        <RenderBooleanDetail
                          customPersonalDetail={custom_personal_detail}
                          setFieldValue={setFieldValue}
                          values={values}
                          touched={touched}
                          errors={errors}
                        />
                      );
                    }

                    if (custom_personal_detail.type === CustomPersonalDetailTypes.DROP_DOWN) {
                      return (
                        <RenderDropDownDetail
                          customPersonalDetail={custom_personal_detail}
                          setFieldValue={setFieldValue}
                          values={values}
                        />
                      );
                    }
                    return <div />;
                  })}
              </Grid>
              {isGiftCardIntent && (
                <Grid
                  container
                  flexDirection="column"
                  border={`1px solid ${theme.customTheme.palette.border}`}
                  borderRadius="8px"
                  p={3}
                  mt={3}>
                  <OrderPageTextField
                    multiline
                    fullWidth
                    className="text-field"
                    id="voucher_greeting_message"
                    name="voucher_greeting_message"
                    label={t('order_page.voucher_card_message')}
                    value={values.voucher_greeting_message}
                    onChange={handleChange}
                    error={touched.voucher_greeting_message && Boolean(errors.voucher_greeting_message)}
                    helperText={touched.voucher_greeting_message && errors.voucher_greeting_message}
                  />
                  <FormControlLabel
                    control={
                      <OrderPageCheckbox
                        id="should_send_to_recipient"
                        name="should_send_to_recipient"
                        checked={values.should_send_to_recipient}
                        onChange={() => setFieldValue('should_send_to_recipient', !values.should_send_to_recipient)}
                      />
                    }
                    label={t('order_page.should_send_to_recipient')}
                  />
                  {values.should_send_to_recipient && (
                    <Grid mt={-1}>
                      <OrderPageTextField
                        fullWidth
                        className="text-field"
                        id="recipient_email"
                        name="recipient_email"
                        label={t('order_page.recipient_email')}
                        value={values.recipient_email}
                        onChange={handleChange}
                        error={touched.recipient_email && Boolean(errors.recipient_email)}
                        helperText={touched.recipient_email && errors.recipient_email}
                      />
                      <Grid container flexDirection="column" mt={2}>
                        <ToggleButtonGroup
                          exclusive
                          value={values.should_send_delayed ? 'scheduled' : 'immediate'}
                          onChange={(e, newValue) => {
                            if (newValue !== null) {
                              const isScheduled = newValue === 'scheduled';
                              void setFieldValue('should_send_delayed', isScheduled);
                              void setFieldValue('delivery_date', moment().format(DATE_FORMAT));
                              void setFieldValue('delivery_time', '15:00');
                              if (!isScheduled) {
                                void setFieldValue('delivery_date', null);
                                void setFieldValue('delivery_time', null);
                              }
                            }
                          }}>
                          <OrderPageToggleButton value="immediate" className="toggle-button">
                            {t('order_page.send_immediately')}
                          </OrderPageToggleButton>
                          <OrderPageToggleButton value="scheduled" className="toggle-button">
                            {t('order_page.send_scheduled')}
                          </OrderPageToggleButton>
                        </ToggleButtonGroup>

                        {values.should_send_delayed && (
                          <Grid container spacing={2} mt={2}>
                            <Grid item xs={6} md={4} gap={2}>
                              <OrderPageTextField
                                fullWidth
                                className="text-field"
                                id="delivery_date"
                                name="delivery_date"
                                label={t('order_page.deliveryDate')}
                                value={
                                  values.delivery_date
                                    ? moment(values.delivery_date)
                                        .locale(holidayCalendar === HolidayCalendar.US ? 'en-US' : 'en-GB')
                                        .format(holidayCalendar === HolidayCalendar.US ? 'MM/DD/YYYY' : 'DD/MM/YYYY')
                                    : ''
                                }
                                onClick={() => setIsDatePickerOpen(true)}
                                InputProps={{
                                  readOnly: true,
                                }}
                                error={touched.delivery_date && Boolean(errors.delivery_date)}
                                helperText={touched.delivery_date && errors.delivery_date}
                                InputLabelProps={{ shrink: true }}
                              />
                              <Modal
                                open={isDatePickerOpen}
                                onClose={() => setIsDatePickerOpen(false)}
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}>
                                <CustomStaticDatePicker
                                  referenceDate={moment()}
                                  displayStaticWrapperAs="desktop"
                                  openTo="day"
                                  disablePast
                                  value={values.delivery_date ? moment(values.delivery_date) : null}
                                  minDate={moment()}
                                  maxDate={moment().add(6, 'months')}
                                  slots={{}}
                                  slotProps={{
                                    day: {
                                      sx: ({ disabled }: { disabled: boolean }) => ({
                                        backgroundColor: 'transparent',
                                        '&.Mui-disabled': {
                                          color: `${theme.customTheme.palette.text.disabled} !important`,
                                        },
                                        color: disabled
                                          ? `${theme.customTheme.palette.error} !important`
                                          : theme.customTheme.palette.text.primary,
                                      }),
                                    },
                                  }}
                                  onChange={(date) => {
                                    console.log(date);
                                    void setFieldValue('delivery_date', date?.format(DATE_FORMAT));
                                    setIsDatePickerOpen(false);
                                  }}
                                  shouldDisableDate={() => false}
                                  theme={theme}
                                  dir="ltr"
                                />
                              </Modal>
                            </Grid>

                            <Grid item xs={6} md={4}>
                              <FormControl fullWidth variant="standard">
                                <InputLabel id="delivery-time-select">
                                  <OrderPageTypography>{t('order_page.deliveryTime')}</OrderPageTypography>
                                </InputLabel>
                                <OrderPageSelect
                                  MenuProps={{
                                    PaperProps: {
                                      component: FormStyledPaper,
                                      backgroundColor: theme.customTheme.palette.background,
                                    },
                                  }}
                                  labelId="delivery-time-select"
                                  id="delivery_time"
                                  name="delivery_time"
                                  value={values.delivery_time}
                                  onChange={(e) => {
                                    void setFieldValue('delivery_time', e.target.value);
                                  }}>
                                  {['09', '12', '15', '18', '21', '00'].map((hour) => {
                                    const timeDisplay =
                                      holidayCalendar === HolidayCalendar.US
                                        ? moment(hour, 'HH').format('hh:mm A')
                                        : `${hour}:00`;
                                    return (
                                      <FormStyledMenuItem
                                        key={`hour-${hour}`}
                                        value={`${hour}:00`}
                                        textColor={theme.customTheme.palette.text.primary}
                                        backgroundColor={theme.customTheme.palette.background}>
                                        {timeDisplay}
                                      </FormStyledMenuItem>
                                    );
                                  })}
                                </OrderPageSelect>
                              </FormControl>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              )}
              <Grid container flexDirection="column" item>
                <FormControl fullWidth variant="standard">
                  <InputLabel id={'select-source-input'}>
                    <OrderPageTypography>{t('order_page.refer')}</OrderPageTypography>
                  </InputLabel>
                  <OrderPageSelect
                    MenuProps={{
                      PaperProps: {
                        component: FormStyledPaper,
                        backgroundColor: theme.customTheme.palette.background,
                      },
                    }}
                    labelId={'select-source-input'}
                    id={'referral_source'}
                    name={'referral_source'}
                    value={values.referral_source}
                    onChange={handleChange}
                    renderValue={(v) =>
                      v ? <OrderPageTypography>{t(`order_page.refferOptions.${v}`)}</OrderPageTypography> : ''
                    }>
                    {shuffle(referralOptions).map(([key, value]) => (
                      <FormStyledMenuItem
                        key={`MenuItem-${key}`}
                        value={value}
                        textColor={theme.customTheme.palette.text.primary}
                        backgroundColor={theme.customTheme.palette.background}>
                        {t(`order_page.refferOptions.${value}`)}
                      </FormStyledMenuItem>
                    ))}
                  </OrderPageSelect>
                </FormControl>
              </Grid>
              {pageContext === PageContext.CUSTOMER && (
                <Grid container flexDirection="column">
                  <FormControlLabel
                    control={
                      <OrderPageCheckbox
                        checked={canSpam}
                        onChange={handleCanSpam}
                        sx={{
                          color: theme.customTheme.palette.border,
                          '&.Mui-checked': {
                            color: theme.customTheme.palette.primary,
                          },
                        }}
                      />
                    }
                    label={t('order_page.can_spam', { accountLabel })}
                  />

                  <Grid container item mb={-1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="terms_conditions_checked"
                          name="terms_conditions_checked"
                          checked={values.terms_conditions_checked}
                          onChange={handleChange}
                          sx={{
                            color: theme.customTheme.palette.border,
                            '&.Mui-checked': {
                              color: theme.customTheme.palette.primary,
                            },
                          }}
                        />
                      }
                      label={
                        <Grid container columnGap={1}>
                          {t('order_page.terms.iaprove')}

                          <a
                            style={{ color: theme.customTheme.palette.primary }}
                            href={`${window.location.origin}/terms`}
                            target="_blank"
                            rel="noreferrer">
                            {t('order_page.terms.webooksterms')}
                          </a>

                          {terms_conditions_link && t('order_page.terms.and')}
                          {terms_conditions_link && (
                            <a
                              style={{ color: theme.customTheme.palette.primary }}
                              href={terms_conditions_link}
                              target="_blank"
                              rel="noreferrer">
                              {t('order_page.terms.accountterms', { accountName })}
                            </a>
                          )}
                        </Grid>
                      }
                    />
                  </Grid>
                  {touched.terms_conditions_checked && errors.terms_conditions_checked && (
                    <Grid item color={theme.customTheme.palette.error}>
                      <Typography fontSize="12px">{errors.terms_conditions_checked}</Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PersonalDetailsStep;
