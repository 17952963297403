import { Button, Grid, Paper, useMediaQuery, useTheme } from '@mui/material';

import './Orders.scss';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Add } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { useAuth } from '../session/InternalAuthProvider';
import OrderPageCard from './OrderPageCard';
import { createQueryStringFromArray } from './orderPageQSSelectorUtil';
import { useLanguage } from '../common/GeneralUtils';
import { UseAuthPermissions } from '../session/UseAuthPermissions';
import { getOrderPages } from '../common/AccountUtils';

const OrderPages = () => {
  const language = useLanguage();
  const { readOnly } = UseAuthPermissions();

  const { authState } = useAuth();
  const { t } = useTranslation();
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const orderPages = getOrderPages(authState.account);
  const [selectedOrderPageIds, setSelectedOrderPageIds] = useState(orderPages.map((x) => x.id));

  const handleOrderPageCardCheck = (orderPageId) => {
    if (selectedOrderPageIds.includes(orderPageId)) {
      // If the orderPageId is already selected, remove it
      setSelectedOrderPageIds(selectedOrderPageIds.filter((id) => id !== orderPageId));
    } else {
      // If it's not selected, add it to the selectedOrderPageIds array
      setSelectedOrderPageIds([...selectedOrderPageIds, orderPageId]);
    }
  };
  return (
    <Paper sx={{ padding: '18px' }}>
      <HelmetProvider>
        <Helmet>
          <title>Monkeybook - {t('main_sidebar.order_pages')}</title>
        </Helmet>
      </HelmetProvider>

      <Grid container mb={4} gap={2} justifyContent="space-between">
        <Grid item flexWrap="nowrap">
          <Link className="no-style-link" to={readOnly ? null : `/dashboard/create-experience`}>
            <Button
              disabled={readOnly}
              variant="outlined"
              onClick={() => {
                setSelectedOrderPageIds([]);
              }}>
              <Grid container gap={1}>
                <Add />
                <Typography>{t('order_pages.add_new_order_page')}</Typography>
              </Grid>
            </Button>
          </Link>
        </Grid>
        <Grid item mb={4}>
          <Link
            className="no-style-link"
            to={`/order/${authState.account.id}?${createQueryStringFromArray(
              'orderpages',
              selectedOrderPageIds,
            )}&language=${language}`}
            target="_blank">
            <Button variant="outlined">
              <Typography>{t('order_pages.choose_order')}</Typography>
            </Button>
          </Link>
          <Button
            sx={{ marginLeft: '10px' }}
            variant="outlined"
            onClick={() => {
              setSelectedOrderPageIds([]);
            }}>
            <Typography>{t('order_pages.remove_marked')}</Typography>
          </Button>
        </Grid>
      </Grid>

      <Grid container gap={4} flexDirection={smallScreen ? 'column' : 'row'}>
        {orderPages.map((orderPage, index) => {
          return (
            <OrderPageCard
              key={index}
              orderPage={orderPage}
              isSelected={selectedOrderPageIds.includes(orderPage.id)}
              onSelect={handleOrderPageCardCheck}
            />
          );
        })}
      </Grid>
    </Paper>
  );
};

export default OrderPages;
