import './OrderCompletedPage.scss';
import { CheckCircle } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react';
import TagManager from 'react-gtm-module';
import uuid from 'react-uuid';
import ReactPixel from 'react-facebook-pixel';
import { Grid, useTheme } from '@mui/material';
import TTLLocalStorage from '../../common/TTLLocalStorage';
import { AccountData, OrderData, OrderService, VoucherOut, VoucherService } from '../../api';
import { getOrderPageLabel } from '../../calendar/dialogs/PrivateExperianceAvailability/PrivateUnavailability/UnavailabilityOrderPagesMultiSelect';
import { deleteUtmDataFromLocalStorage, getUtmDataFromLocalStorage } from './utmLocalStorage';
import { getOrderPage, getOrderPages } from '../../common/AccountUtils';
import { useAccountData } from '../AccountSpecificThemeWrapper';
import RotatingLoader from '../../common/ui/RotatingLoader';
import MonkeybookWidgetFooter from '../../common/ui/MonkeybookWidgetFooter';
import { OrderPageTypography } from '../themedComponents/OrderPageTypography';
import { useLanguage } from '../../common/GeneralUtils';
import { useGA4Tracking } from './useGA4Tracking';

const FBPixel = ReactPixel;

function reportTransactionFB(account?: AccountData, transactionData?: VoucherOut | OrderData) {
  if (!transactionData || !account || !account.marketing_integrations?.fb_pixel_id) {
    return;
  }
  const orderPageName = getOrderPageLabel('en', getOrderPages(account), transactionData.order_page_id);
  const options = {
    autoConfig: true, // set pixel's auto config
    debug: true, // enable logs
  };

  FBPixel.init(account.marketing_integrations.fb_pixel_id, undefined, options);
  FBPixel.track('Purchase', {
    value: transactionData.price,
    currency: account.currency,
    content_ids: [transactionData.id],
    content_type: 'product',
    contents: [
      {
        id: transactionData.id,
        quantity: 1,
        item_name: `${orderPageName}-${transactionData.order_page_id}`,
        utm_data: getUtmDataFromLocalStorage(),
      },
    ],
    ...getUtmDataFromLocalStorage(),
  });
}

const OrderCompletedPage = () => {
  const theme = useTheme();
  const language = useLanguage();
  const { initializeGA4, reportTransactionGA4, isInitializedGA4 } = useGA4Tracking();

  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [tagManagerIsInitialized, setTagManagerIsInitialized] = useState(false);
  const [transactionEntity, setTransactionEntity] = useState<VoucherOut | OrderData | undefined>();
  const orderPageId = searchParams.get('order_page_id') || undefined;
  const transactionType = searchParams.get('transaction_type') || undefined;
  const entityId = searchParams.get('entity_id') || undefined;
  const postHog = usePostHog();

  const account = useAccountData();
  const orderPage = getOrderPage(account, orderPageId || '');

  const elementRef = useCallback((node: any) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => {
      const iframeHeight = document.body.offsetHeight;
      window.parent.postMessage({ height: iframeHeight }, '*');
    });
    resizeObserver.observe(node);
  }, []);
  useEffect(() => {
    if (account) {
      postHog.setPersonPropertiesForFlags({ accountId: account.id });
      postHog.identify(uuid(), {
        accountId: account.id,
        orderPageId,
        environment: import.meta.env.VITE_REACT_APP_ENVIRONMENT || 'local',
        context: 'order-page-completed',
      });
    }
  }, [account]);

  useEffect(() => {
    if (isInitializedGA4 && transactionEntity && account) {
      reportTransactionGA4(transactionEntity, account);
    }
  }, [isInitializedGA4]);

  const utmParams = getUtmDataFromLocalStorage();
  const gtmTrackingEnabled = useFeatureFlagEnabled('direct-gtm-tracking-enabled');
  const iframeGtmTrackingEnabled = useFeatureFlagEnabled('iframe-gtm-tracking-enabled');
  const shouldUseNewTracking = gtmTrackingEnabled && account?.marketing_integrations?.gtm_id;

  useEffect(() => {
    postHog.startSessionRecording({});
  }, []);

  useEffect(() => {
    if (transactionType && entityId && account) {
      const getEntity = async () => {
        const fetchTransactionEntity = async () => {
          if (transactionType === 'voucher') {
            return VoucherService.getVoucherById(entityId);
          }
          if (transactionType === 'order') {
            return OrderService.getOrder(entityId);
          }
          return undefined;
        };

        const transactionEntity = await fetchTransactionEntity();

        setTransactionEntity(transactionEntity);
        if (account?.marketing_integrations?.ga4_id) {
          initializeGA4(account?.marketing_integrations?.ga4_id);
        }
        if (account?.marketing_integrations?.fb_pixel_id) {
          reportTransactionFB(account, transactionEntity);
        }
        postHog.capture('Transaction completed', { ...transactionEntity, account: account?.name, utmParams });
      };

      void getEntity();
    }
  }, [transactionType, entityId, account]);

  useEffect(() => {
    if (shouldUseNewTracking) {
      TagManager.initialize({
        // @ts-ignore
        gtmId: account?.marketing_integrations?.gtm_id,
      });
      setTagManagerIsInitialized(true);
    }
  }, [gtmTrackingEnabled, shouldUseNewTracking]);

  useEffect(() => {
    if (account && transactionEntity) {
      if (shouldUseNewTracking) {
        // should wait for initialization
        if (!tagManagerIsInitialized) {
          return;
        }
        const event = {
          event: 'purchase',
          ecommerce: {
            transaction_id: transactionEntity.id || uuid(),
            affiliation: 'Monkeybook.io',
            value: transactionEntity.price,
            tax: '',
            shipping: '',
            currency: account?.currency,
            coupon: '',
            items: [
              {
                item_name: getOrderPageLabel('en', getOrderPages(account), orderPageId!),
                item_id: orderPageId,
                price: transactionEntity.price,
                category: transactionType,
                variant: '',
                quantity: 1,
              },
            ],
          },
          customer: {
            customer_id: uuid(),
            customer_first_name: transactionEntity.firstname,
            customer_last_name: transactionEntity.lastname,
            customer_email: transactionEntity.email,
            customer_phone: transactionEntity.phone,
          },
          ...utmParams,
        };
        TagManager.dataLayer({
          dataLayer: event,
        });
        console.log('sent event', event);
        TTLLocalStorage.removeByPrefix('order-page-state-');
      } else {
        try {
          console.log('Going to send event to parent window');
          const message = {
            order_id: transactionEntity.id || uuid(),
            revenue: transactionEntity.price, // Total transaction value (incl. tax and shipping)

            products: [
              {
                name: getOrderPageLabel('en', getOrderPages(account), orderPageId!),
                id: orderPageId,
                price: transactionEntity.price,
                category: transactionType,
                variant: '',
                quantity: 1,
              },
            ],
            customer: {
              customer_first_name: transactionEntity.firstname,
              customer_last_name: transactionEntity.lastname,
              customer_email: transactionEntity.email,
              customer_phone: transactionEntity.phone,
              customer_country: 'US',
              customer_state: 'MA',
              customer_zipcode: '',
            },
          };
          if (iframeGtmTrackingEnabled) {
            window.parent.postMessage({ ...message, name: 'monkeybook_transactionComplete' }, '*');
          } else {
            window.parent.postMessage({ ...message, name: 'transacationComplete' }, '*');
          }
        } catch (e) {
          console.log('Error occured when trying to send event');
          console.log(e);
        }
        TTLLocalStorage.removeByPrefix('order-page-state-');
        deleteUtmDataFromLocalStorage();
      }
    }
  }, [tagManagerIsInitialized, account, transactionEntity]);

  if (!account || !orderPage) {
    return (
      <Grid container justifyContent="space-around" mt={3}>
        <Grid
          container
          minHeight={336}
          sx={{
            borderRadius: '12px',
            width: '322px',
          }}>
          <RotatingLoader />
        </Grid>
      </Grid>
    );
  }
  TTLLocalStorage.removeByPrefix('order-page-state-');
  return (
    <>
      <Grid
        container
        display="flex"
        justifyContent="space-around"
        sx={{ '::-webkit-scrollbar': { display: 'none' } }}
        style={{
          height: '100%',
          width: '100%',
        }}>
        <Grid
          ref={elementRef}
          container
          flexDirection="column"
          alignItems="center"
          alignContent="center"
          p={6}
          gap={1}
          style={{
            border: `2px solid ${theme.customTheme.palette.border}`,
            borderRadius: '12px',
            background: theme.customTheme.palette.background,
            height: '100%',
            width: '100%',
            maxWidth: '710px',
          }}>
          <Grid mb={2}>
            <CheckCircle style={{ color: theme.customTheme.palette.primary, width: '64px', height: '64px' }} />
          </Grid>
          <OrderPageTypography variant="h1" bold>
            {t('order_completed_page.title', { orderNumber: transactionEntity?.order_number })}
          </OrderPageTypography>
          <OrderPageTypography variant={'h3'}>{t('order_completed_page.sub_text')}</OrderPageTypography>

          <OrderPageTypography variant={'h3'}>
            {account?.order_completed_page_settings?.title?.[language]}
          </OrderPageTypography>
          <OrderPageTypography variant={'h3'}>
            {account?.order_completed_page_settings?.subtitle?.[language]}
          </OrderPageTypography>
        </Grid>
      </Grid>
      <Grid mt={2}>
        <MonkeybookWidgetFooter accountId={account.id} />
      </Grid>
    </>
  );
};

export default OrderCompletedPage;
