import './DateAndTimeStep.scss';
import React, { useEffect, useState } from 'react';
import { Alert, Box, Fade, Grid, Grow, Tooltip, useTheme } from '@mui/material';
import moment, { Moment } from 'moment';
import 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PickersDay, pickersDayClasses } from '@mui/x-date-pickers';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { isEmpty } from 'lodash';
import { usePostHog } from 'posthog-js/react';
import { useLanguage } from '../../../common/GeneralUtils';
import TimeUtils from '../../../common/TimeUtils';
import 'animate.css';
import { calculatePrice } from '../../OrderUtils';
import PluginUtils from '../../PluginUtils';
import { OrderPageButton } from '../../themedComponents/OrderPageButton';
import { OrderPageTypography } from '../../themedComponents/OrderPageTypography';
import { OrderPageToggleButton } from '../../themedComponents/OrderPageToggleButton';
import RotatingLoader from '../../../common/ui/RotatingLoader';
import WeekendAndIsraeliHolidaysWarning from '../../WeekendAndIsraeliHolidaysWarning';
import { CustomDay } from './components/CustomDay';
import { AccountData, AccountLocationData, DateAvailabilityOut, OrderPageData } from '../../../api';
import { CondensedDatePicker } from './components/CondensedDatePicker';
import { Troubleshoot } from './components/Troubleshoot';
import { Legend } from './components/Legend';
import { OrderPageDivider } from '../../themedComponents/OrderPageDivider';
import { useFilteredLocations } from '../../useFilteredLocations';
import { getLocationNameById } from '../../MultiLocationDetails';
import { OrderPageIntent } from '../../OrderPageIntent';
import CustomStaticDatePicker from './components/CustomStaticDatePicker';
import { getTimeFormat } from '../../../common/getDateAndTimeFormat';

export const OccupiedPickersDay = styled(PickersDay)(() => ({
  textDecoration: 'line-through',
}));
const BorderlessAlert = styled(Alert)({
  border: 'none',
  fontWeight: 'bold',
});
export const AvailablePickersDay = styled(PickersDay)(({ theme }) => ({
  background: theme.customTheme.palette.calendarPicker.availableDay.background,
  '&:hover': {
    background: theme.customTheme.palette.calendarPicker.availableDay.hover,
  },
  [`&&.${pickersDayClasses.selected}`]: {
    backgroundColor: theme.customTheme.palette.calendarPicker.availableDay.selected,
  },
}));

export const NotAvailablePickersDay = styled(PickersDay)(({ theme }) => ({
  background: theme.customTheme.palette.calendarPicker.fullyBookedDay.background,
  '&:hover': {
    background: theme.customTheme.palette.calendarPicker.fullyBookedDay.hover,
  },
  [`&&.${pickersDayClasses.selected}`]: {
    backgroundColor: theme.customTheme.palette.calendarPicker.fullyBookedDay.selected,
  },
}));

export const RunningOutPickersDay = styled(PickersDay)(({ theme }) => ({
  background: theme.customTheme.palette.calendarPicker.runningOutDay.background,
  '&:hover': {
    background: theme.customTheme.palette.calendarPicker.runningOutDay.hover,
  },
  [`&&.${pickersDayClasses.selected}`]: {
    backgroundColor: theme.customTheme.palette.calendarPicker.runningOutDay.selected,
  },
}));

const isAfter18InAccountTimezone = (account?: AccountData): boolean => {
  if (!account) {
    return false;
  }
  const now = moment.tz(account.timezone);
  const eighteenOClock = moment.tz(account.timezone).set({ hour: 18, minute: 0, second: 0, millisecond: 0 });
  return now.isAfter(eighteenOClock);
};
export type OrderDetails = {
  persons: number;
  duration: number;
  price: number;
  rooms: number;
  custom_details: Record<string, any>;
};
const DateAndTimeStep = ({
  shouldHideWeekends,
  orderPageIntent,
  orderPage,
  date,
  time,
  isLoadingDates,
  waitingListUrl,
  orderDetails,
  setDateTimeAndPrice,
  setTime,
  availableDates,
  noMinDate,
  specificEvent,
  setShouldDisableNext,
  troubleshoot,
  locations,
  initialAvailableDates,
  preSelectedDate,
  account,
}: {
  orderPage: OrderPageData;
  shouldHideWeekends?: boolean;
  orderPageIntent: OrderPageIntent;
  date?: string | null;
  time?: number | null;
  isLoadingDates: boolean;
  waitingListUrl?: string;
  orderDetails: OrderDetails;
  setDateTimeAndPrice: ({
    newDate,
    newTime,
    newPrice,
  }: {
    newDate?: string | null;
    newTime?: number | null;
    newPrice?: number;
  }) => void;
  setTime: (newTime: number) => void;
  availableDates: DateAvailabilityOut[];
  initialAvailableDates: DateAvailabilityOut[];
  noMinDate?: boolean;
  troubleshoot?: boolean;
  specificEvent?: boolean;
  setShouldDisableNext?: (shouldDisableNext: boolean) => void;
  locations: AccountLocationData[];
  preSelectedDate?: string;
  account?: AccountData;
}) => {
  const posthog = usePostHog();

  const theme = useTheme();
  // for some reason direction in theme is cool in iederpage but not here so hacking it manually
  const { i18n } = useTranslation();
  const dir = i18n.dir();
  const timeFormat = getTimeFormat(account);
  const initAvailableDatesDict = (availableDates: DateAvailabilityOut[]) => {
    const result = {};
    availableDates.forEach((dateObj) => {
      if (shouldHideWeekends && PluginUtils.isWeekendOrHoliday(dateObj.date)) {
        return;
      }
      // @ts-ignore
      result[dateObj.date] = dateObj;
    });
    return result;
  };

  const availableDatesDict = initAvailableDatesDict(availableDates || []);

  const firstAvailableDate = availableDates?.find((x) => !x.isDisabled);
  const defaultFullObjDate = date ? availableDates?.find((obj) => obj.date === date) : firstAvailableDate;
  const [localDate, setLocalDate] = useState<null | Moment>(date ? moment(date) : moment());
  const referenceDate = moment(firstAvailableDate?.date);
  const [troubleshootVisible, setTroubleshootVisible] = useState(false);

  const [dateFullObj, setDateFullObj] = useState<null | undefined | DateAvailabilityOut>(defaultFullObjDate);

  const { t } = useTranslation();
  const language = useLanguage();
  const minDateForOrderBasedOnAcctPref = noMinDate
    ? moment().startOf('day')
    : moment()
        .startOf('day')
        .add(
          isAfter18InAccountTimezone(account) ? (orderPage.min_date_in_days || 0) + 1 : orderPage.min_date_in_days,
          'days',
        );

  const minDate = minDateForOrderBasedOnAcctPref.isSameOrAfter(moment(firstAvailableDate?.date))
    ? minDateForOrderBasedOnAcctPref
    : moment(firstAvailableDate?.date);
  const currentDateStartOfMonth = moment().startOf('month');
  const maxDate = moment().startOf('day').add(orderPage.max_date_in_days, 'days');

  const [selectedDateHasAllTimesDisabled, setSelectedDateHasAllTimesDisabled] = useState(false);
  const [ticketsLeftForSelectedTime, setTicketsLeftForSelectedTime] = useState<number>(0);
  const shouldShowDuration = orderPage.show_duration;
  const { numberOfTotalLocations } = useFilteredLocations({
    initialAvailableDates,
    defaultLocationId: orderPage.location_id,
  });
  const shouldShowLocation = numberOfTotalLocations > 1;
  const [selectedLocationId, setSelectedLocationId] = useState<string | undefined>();
  const clearDateAndTime = () => {
    if (specificEvent) {
      return;
    }
    setDateTimeAndPrice({ newDate: null, newTime: null });

    setLocalDate(null);
    setDateFullObj(null);
    setTroubleshootVisible(false);
  };

  useEffect(() => {
    if (preSelectedDate) {
      return;
    }
    if (!availableDates?.find((obj) => obj.date === date)) {
      clearDateAndTime();
      // setLocalDate(moment(availableDates?.find((x) => !x.isDisabled)?.date));
      return;
    }
    const dateObj = availableDates.find((obj) => obj.date === date);
    const foundTime = dateObj?.times.find((t) => t.seconds === time);
    if (!foundTime || foundTime.isDisabled) {
      clearDateAndTime();
    }
  }, [isLoadingDates]);

  const shouldDisableDate = (value: Moment) => {
    const formattedDate = value.format('YYYY-MM-DD');
    return (
      !moment(formattedDate).isSameOrAfter(moment.min(minDate, minDateForOrderBasedOnAcctPref)) ||
      // @ts-ignore
      !availableDatesDict[formattedDate] ||
      // @ts-ignore
      (availableDatesDict[formattedDate].isDisabled && (!orderPage.waiting_list || !orderPage.waiting_list.enabled))
    );
  };

  const onDateChange = (newValue: Moment | null) => {
    posthog.startSessionRecording({});
    setLocalDate(newValue);
    setTroubleshootVisible(false);
    const formattedDate = newValue?.format('YYYY-MM-DD');
    const dateFullObjTemp: DateAvailabilityOut | undefined = availableDates?.find((obj) => obj.date === formattedDate);

    let time;
    if (dateFullObjTemp?.times.length === 1) {
      time = dateFullObjTemp?.times[0].seconds;
      setTicketsLeftForSelectedTime(dateFullObjTemp.times[0].ticketsLeft || 0);
      if (setShouldDisableNext) {
        setShouldDisableNext(dateFullObjTemp.times[0].isDisabled);
      }
      setSelectedLocationId(dateFullObjTemp.times[0].location_id);
    }
    setSelectedDateHasAllTimesDisabled(!dateFullObjTemp?.times.find((x) => !x.isDisabled));

    const tempDateDetails = {
      date: formattedDate,
      time,
    };

    const price: number = calculatePrice(orderPage, orderDetails, tempDateDetails);
    setDateTimeAndPrice({ newDate: tempDateDetails.date, newTime: tempDateDetails.time, newPrice: price });
    setDateFullObj(dateFullObjTemp);

    setTimeout(() => {
      document.getElementById('times-container')?.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });
    }, 200);
  };

  useEffect(() => {
    if (preSelectedDate) {
      onDateChange(moment(preSelectedDate));
    }
  }, [preSelectedDate, isLoadingDates, availableDates]);
  const currentMonth = moment().month();
  // Get the next month, considering December (11) wraps to January (0)
  const nextMonth = (currentMonth + 1) % 12;

  let currentMonthCount = 0;
  let nextMonthCount = 0;
  let currentMonthTicketsLeft = 0;

  availableDates.forEach((date) => {
    const dateMonth = moment(date.date).month();
    if (dateMonth === currentMonth) {
      currentMonthCount++;
      date.times.forEach((time) => {
        currentMonthTicketsLeft += (time.ticketsLeft || 0) > 0 ? time.ticketsLeft || 0 : 0;
      });
    } else if (dateMonth === nextMonth) {
      nextMonthCount++;
    }
  });
  const shouldShowLastTicketsNotification = currentMonthTicketsLeft > 0 && currentMonthTicketsLeft <= 5;
  // Check if both months have 5 or less counts
  const shouldShowCondensePicker = currentMonthCount <= 5 && nextMonthCount <= 5;
  const ChosenTimeComponent = time ? (
    <Fade in={!!time} timeout={1000}>
      <Grid container flexDirection="column">
        <Grid mb={1}>
          <OrderPageDivider />
        </Grid>
        {shouldShowLocation && selectedLocationId && (
          <Grid container gap={1}>
            <OrderPageTypography bold>{t('order_page.location')}</OrderPageTypography>
            <OrderPageTypography bold>
              {getLocationNameById(selectedLocationId, locations, language)}
            </OrderPageTypography>
          </Grid>
        )}
        <Grid container gap={1}>
          <OrderPageTypography bold>{t('order_page.time')}</OrderPageTypography>
          <Grid dir="ltr">
            <OrderPageTypography>
              {`${moment.unix(time).tz('utc').format(timeFormat)} - ${moment
                .unix(time)
                .tz('utc')
                .add(orderDetails.duration, 'seconds')
                .format(timeFormat)}`}
            </OrderPageTypography>
          </Grid>
        </Grid>
        {shouldShowDuration && (
          <Grid container gap={1}>
            <OrderPageTypography bold> {t('order_page.duration')}</OrderPageTypography>
            <OrderPageTypography>{TimeUtils.formatDuration(orderDetails.duration, t)}</OrderPageTypography>
          </Grid>
        )}
        <Grid my={1}>
          <OrderPageDivider />
        </Grid>
      </Grid>
    </Fade>
  ) : null;
  return (
    <div className="date-and-time-step">
      <div className="dates-container">
        <OrderPageTypography variant="h3" bold>
          {t('order_page.choose_date')}
        </OrderPageTypography>
        <WeekendAndIsraeliHolidaysWarning
          orderPage={orderPage}
          orderPageIntent={orderPageIntent}
          shouldHideWeekends={shouldHideWeekends}
        />
        {isEmpty(availableDates) && isLoadingDates ? (
          <Grid container justifyContent="space-around" mt={3}>
            <Grid
              container
              minHeight={336}
              sx={{
                backgroundColor: theme.customTheme.palette.background,
                borderRadius: '12px',
                width: '322px',
                border: `1px solid ${theme.customTheme.palette.border}`,
              }}>
              <RotatingLoader />
            </Grid>
          </Grid>
        ) : isEmpty(availableDates) && !isLoadingDates ? (
          <Grid container justifyContent="space-around" mt={3}>
            <Grid
              container
              minHeight={336}
              justifyContent={'center'}
              alignItems={'center'}
              sx={{
                backgroundColor: theme.customTheme.palette.background,
                borderRadius: '12px',
                width: '322px',
                border: `1px solid ${theme.customTheme.palette.border}`,
              }}>
              <OrderPageTypography color="button.outlined" variant="h3">
                {t('order_page.noDates')}
              </OrderPageTypography>
            </Grid>
          </Grid>
        ) : shouldShowCondensePicker ? (
          <CondensedDatePicker
            localDate={localDate}
            availableDatesDict={availableDatesDict}
            minDate={moment.min(minDate, minDateForOrderBasedOnAcctPref)}
            onDateChange={onDateChange}
          />
        ) : (
          <Grid container justifyContent="center">
            {shouldShowLastTicketsNotification && (
              <OrderPageTypography color="button.outlined" variant="h3">
                {t('order_page.lastTickets', {
                  ticketsAmount: currentMonthTicketsLeft,
                  month: moment().format('MMMM'),
                })}
              </OrderPageTypography>
            )}

            <CustomStaticDatePicker
              referenceDate={referenceDate}
              displayStaticWrapperAs="desktop"
              openTo="day"
              disablePast
              value={localDate}
              minDate={currentDateStartOfMonth}
              maxDate={maxDate}
              slots={{
                // @ts-ignore
                day: CustomDay,
              }}
              slotProps={{
                day: {
                  // @ts-ignore
                  date: localDate,
                  minDate,
                  availableDatesDict,
                  minDateForOrderBasedOnAcctPref,
                  troubleshoot,
                },
              }}
              onChange={onDateChange}
              shouldDisableDate={shouldDisableDate}
              theme={theme}
              dir={dir}
            />
          </Grid>
        )}
        <Grid>{dateFullObj?.times.length === 1 && ChosenTimeComponent}</Grid>
        <Legend orderPage={orderPage} />
      </div>

      {dateFullObj && dateFullObj.times.length !== 1 && (
        <Grow in timeout={1500}>
          <Grid container flexDirection="column" className="times-container" id="times-container" mt={1}>
            {dateFullObj.times.length !== 1 && (
              <Grid container flexDirection="column">
                <Grid item className="text-label">
                  {t('order_page.choose_time')}
                </Grid>
                {orderPage.times_warning && (
                  <BorderlessAlert severity="warning" variant="outlined">
                    <OrderPageTypography color="warning">{orderPage.times_warning[language]}</OrderPageTypography>
                  </BorderlessAlert>
                )}
                <Grid container justifyContent="center" className="times-buttons-container">
                  {dateFullObj.times
                    .sort((a, b) => a.seconds - b.seconds)
                    .map((availableTime, index) => {
                      const selected = time === availableTime.seconds;
                      return (
                        <Tooltip
                          title={availableTime.isDisabled ? t('order_page.time_not_available') : ''}
                          placement="top"
                          arrow>
                          <OrderPageToggleButton
                            customDetails={availableTime.custom_details}
                            key={`time-${index}`}
                            className={`time-button${availableTime.isDisabled ? ' disabled-time-button' : ''}`}
                            troubleshoot={availableTime.isDisabled && troubleshoot}
                            value={availableTime}
                            selected={selected}
                            disabled={availableTime.isDisabled && !troubleshoot}
                            onChange={() => {
                              // for troubleshooting time
                              if (availableTime.isDisabled) {
                                setTime(availableTime.seconds);
                                setTroubleshootVisible(true);
                                return;
                              }
                              setTroubleshootVisible(false);
                              setTime(availableTime.seconds);
                              setSelectedLocationId(availableTime.location_id);
                            }}>
                            {moment.unix(availableTime.seconds).tz('utc').format(timeFormat)}
                          </OrderPageToggleButton>
                        </Tooltip>
                      );
                    })}
                </Grid>
                {troubleshootVisible && (
                  <Troubleshoot timeSlotResponseOut={dateFullObj.times.find((x) => x.seconds === time)} />
                )}
              </Grid>
            )}

            {dateFullObj?.times.length !== 1 && <Grid mt={1}>{ChosenTimeComponent}</Grid>}
          </Grid>
        </Grow>
      )}
      {dateFullObj && selectedDateHasAllTimesDisabled && orderPage.waiting_list && orderPage.waiting_list.enabled && (
        <Box className="waiting-list-block">
          {ticketsLeftForSelectedTime <= 0 ? (
            <Box className="warning">
              <Typography variant="h5">{t('order_page.waiting_list.no_tickets')}</Typography>
            </Box>
          ) : null}
          {ticketsLeftForSelectedTime && ticketsLeftForSelectedTime > 0 ? (
            <Box className="warning">
              {t('order_page.waiting_list.not_enough_tickets', {
                ticketsLeft: ticketsLeftForSelectedTime,
              })}
            </Box>
          ) : null}
          {waitingListUrl && (
            <Link to={`${waitingListUrl}/${dateFullObj.date}`}>
              <OrderPageButton variant="contained">
                <OrderPageTypography variant="h6" color="button.contained">
                  {t('order_page.waiting_list.link')}
                </OrderPageTypography>
              </OrderPageButton>
            </Link>
          )}
        </Box>
      )}
    </div>
  );
};

export default DateAndTimeStep;
