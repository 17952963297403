import React, { useEffect, useState } from 'react';
import './App.scss';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './i18n';
import { useTranslation } from 'react-i18next';
import { Alert, Snackbar, ThemeProvider, useTheme } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { StyleSheetManager } from 'styled-components';
import rtlPlugin from 'stylis-plugin-rtl';
import { SWRConfig } from 'swr';
import qs from 'qs';
import { heIL } from '@mui/x-date-pickers/locales';

import { cacheLtr, cacheRtl, useLanguage } from './common/GeneralUtils';
import routes from './common/Routes';
import 'moment/dist/locale/he';
import { Language, OpenAPI } from './api';

export const router = createBrowserRouter(routes);

function App() {
  OpenAPI.BASE = import.meta.env.VITE_REACT_APP_API_BASE_URL || '';
  OpenAPI.WITH_CREDENTIALS = true;
  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    const { search } = window.location;
    setQueryParams(qs.parse(search, { ignoreQueryPrefix: true }));
  }, []);

  const { i18n, t } = useTranslation();
  const theme = useTheme();
  const language = useLanguage();
  const [errorOpen, setErrorOpen] = useState(false);
  const handleCloseError = () => {
    setErrorOpen(false);
    window.location.href = import.meta.env.VITE_REACT_APP_AUTH_URL;
  };
  useEffect(() => {
    const lang = queryParams.language || localStorage.getItem('calendar_language');

    if (lang) {
      void i18n.changeLanguage(lang);
    } else if (language !== 'he' && language !== 'en') {
      void i18n.changeLanguage('en').then(() => {
        window.location.reload();
      });
    }
  }, [queryParams]);

  theme.direction = i18n.dir();
  document.body.dir = i18n.dir();
  let cache = cacheLtr;
  if (theme.direction === 'rtl') {
    document.dir = 'rtl';
    cache = cacheRtl;
  }
  moment.locale(language);

  return (
    <div className="App">
      <SWRConfig
        value={{
          onError: (error) => {
            if (error.status === 401 && window.location.pathname.includes('dashboard')) {
              setErrorOpen(true);
            }
          },
        }}>
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          localeText={
            language === Language.HE ? heIL.components.MuiLocalizationProvider.defaultProps.localeText : undefined
          }>
          <CacheProvider value={cache}>
            <ThemeProvider theme={theme}>
              <StyleSheetManager stylisPlugins={[rtlPlugin]}>
                <RouterProvider router={router} />
              </StyleSheetManager>
            </ThemeProvider>
          </CacheProvider>
        </LocalizationProvider>
      </SWRConfig>
      <Snackbar
        open={errorOpen}
        onClose={handleCloseError}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          {t('unauth')}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;
