import './TableUiObject.scss';
import './Ribbon.scss';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Table, TableBody, TableCell, TableHead, TableRow, ToggleButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useLanguage } from '../../common/GeneralUtils';
import { calculatePrice } from '../OrderUtils';
import { OrderPageTypography } from '../themedComponents/OrderPageTypography';
import { priceFormatter } from '../utils';

const TableUiObject = (props) => {
  const { uiObject, orderState, setOrderState, isEditOrder } = props;
  const { t } = useTranslation();
  const language = useLanguage();

  const defaultCell = 'cell-0-0';
  const isEqualCurrentValues = (values) => {
    for (const value of values) {
      if (value.data_type === 'custom') {
        if (orderState.details.custom_details[value.name] !== value.value) {
          return false;
        }
      } else if (orderState.details[value.data_type] !== value.value) {
        return false;
      }
    }
    return true;
  };

  const getCell = () => {
    let returnValue = defaultCell;
    uiObject.options.forEach((row, i) => {
      row.forEach((item, j) => {
        if (isEqualCurrentValues(item.values)) {
          returnValue = `cell-${i.toString()}-${j.toString()}`;
        }
      });
    });

    return returnValue;
  };
  const [cellValue, setCellValue] = useState(getCell());

  const getNewOrderDetails = (valuesToChange) => {
    const tempDetails = JSON.parse(JSON.stringify(orderState.details));
    for (const valueToChange of valuesToChange) {
      if (valueToChange.data_type === 'custom') {
        tempDetails.custom_details[valueToChange.name] = valueToChange.value;
      } else {
        tempDetails[valueToChange.data_type] = valueToChange.value;
      }
    }
    return tempDetails;
  };

  const getSplittedCellValue = (val) => {
    const splittedValue = val.split('-');
    const i = parseInt(splittedValue[1], 10);
    const j = parseInt(splittedValue[2], 10);
    return [i, j];
  };

  const onValueChange = (val) => {
    if (val != null) {
      // for deselection
      console.log(`set value to ${val}`);
      setCellValue(val);
      const [i, j] = getSplittedCellValue(val);

      const orderDetails = getNewOrderDetails(uiObject.options[i][j].values);
      const calculatedPrice = calculatePrice(orderState.orderPage, orderDetails, orderState.dateAndTimeDetails);
      const newState = {
        ...orderState,
        details: { ...orderState.details, ...orderDetails },
        isLoadingDates: true,
        availableDates: [],
      };
      // when editing order we dont want to allow price overrides
      if (isEditOrder) {
        newState.calculatedPrice = calculatedPrice;
        if (orderState.order_id === '') {
          newState.details.price = calculatedPrice;
        }
      } else {
        newState.details.price = calculatedPrice;
      }
      setOrderState(newState);
    }
  };

  const getPrice = (values) => {
    const tempDetails = getNewOrderDetails(values);
    return calculatePrice(orderState.orderPage, tempDetails, orderState.dateAndTimeDetails);
  };

  const isPassingRestriction = (restriction) => {
    if (restriction.cls === 'TableUiObjectPersonsRestriction') {
      let passMin = true;
      let passMax = true;
      if (restriction.min) {
        passMin = orderState.details.persons >= restriction.min;
      }
      if (restriction.max) {
        passMax = orderState.details.persons <= restriction.max;
      }
      return passMin && passMax;
    }

    return true;
  };

  const getFirstNotPassingRestriction = (restrictions, i, j) => {
    for (const restriction of restrictions) {
      if (!isPassingRestriction(restriction)) {
        const [currentI, currentJ] = getSplittedCellValue(cellValue);

        // sorry not sorry
        // return to default value if the value is not passing restrictions anymore
        if (currentI === i && j === currentJ) {
          setTimeout(() => {
            onValueChange(defaultCell);
          }, 700);
        }

        return restriction;
      }
    }
    return null;
  };

  return (
    <Box className="table-ui-object" sx={{ marginBottom: 0 }}>
      <OrderPageTypography variant="h3" bold>
        {uiObject.label[language]}
      </OrderPageTypography>

      <Box sx={{ margin: '20px 0' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ padding: '4px 0' }} />
              {uiObject.header_y.map((head, index) => {
                return (
                  <TableCell key={index} align="center" sx={{ padding: '4px 0' }}>
                    <OrderPageTypography variant="body2" color="primary">
                      {head[language]}
                    </OrderPageTypography>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {uiObject.header_x.map((head, index) => {
              return (
                <TableRow>
                  <TableCell
                    key={`row-${index}`}
                    align="center"
                    sx={{
                      paddingX: 1,
                      borderInlineStart: '1px solid #ccc',
                    }}>
                    {head[language].split(' ').map((part) => (
                      <OrderPageTypography variant="body2" color="primary">
                        {part}
                      </OrderPageTypography>
                    ))}
                  </TableCell>
                  {uiObject.options[index].map((cell, j) => {
                    const selected = `cell-${index.toString()}-${j.toString()}` === cellValue;
                    return (
                      <TableCell
                        key={`cell-${index}-${j}`}
                        sx={{
                          padding: 0,
                        }}>
                        <Grid
                          sx={{
                            padding: 0,

                            color: (theme) =>
                              selected ? theme.customTheme.palette.railFill : theme.customTheme.palette.text.primary,
                            backgroundColor: (theme) =>
                              selected
                                ? theme.customTheme.palette.toggleButton.selectedBg
                                : theme.customTheme.palette.background,
                            borderColor: (theme) => theme.customTheme.palette.text.primary,

                            height: '100%', // Take full height of TableCell
                            width: '100%',
                          }}
                          container
                          justifyContent="center"
                          alignItems="center">
                          {getFirstNotPassingRestriction(uiObject.options[index][j].restrictions, index, j) === null ? (
                            <ToggleButton
                              sx={{
                                width: '100%',
                                border: 0,
                              }}
                              onClick={() => onValueChange(`cell-${index.toString()}-${j.toString()}`)}
                              className="toggle-button"
                              color="primary"
                              selected={selected}
                              value={`cell-${index.toString()}-${j.toString()}`}>
                              <Grid container flexDirection="column">
                                <Grid item hidden={j !== 1} className="ribbon">
                                  <OrderPageTypography variant="body2" color="primary">
                                    {t('recommended')}
                                  </OrderPageTypography>
                                </Grid>

                                <div>
                                  <OrderPageTypography variant="body2" color="primary">
                                    {priceFormatter(getPrice(uiObject.options[index][j].values), orderState.account)}
                                  </OrderPageTypography>
                                </div>
                                <div className="secondary-text">
                                  <OrderPageTypography variant="body2" color="primary">
                                    {`${priceFormatter(
                                      Math.round(
                                        getPrice(uiObject.options[index][j].values) / orderState.details.persons,
                                      ),
                                      orderState.account,
                                    )}/ ${t('order_page.table.participant')}`}
                                  </OrderPageTypography>
                                </div>
                              </Grid>
                            </ToggleButton>
                          ) : (
                            <Close />
                            // <Alert icon={!isSmallScreen && <ErrorOutline />} severity="error" className="error-text">
                            //   <OrderPageTypography color="error" variant="body2">
                            //     {
                            //       getFirstNotPassingRestriction(uiObject.options[index][j].restrictions, index, j)
                            //         .error[language]
                            //     }
                            //   </OrderPageTypography>
                            // </Alert>
                          )}
                        </Grid>
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default TableUiObject;
