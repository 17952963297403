import TTLLocalStorage from '../../common/TTLLocalStorage';

const UTL_LOCAL_STORAGE_KEY = 'monkeybook-UTM';
export const getUTParameters = (): UtmData => {
  const params = new URLSearchParams(window.location.search);
  return {
    utm_source: params.get('utm_source') || undefined,
    utm_medium: params.get('utm_medium') || undefined,
    utm_campaign: params.get('utm_campaign') || undefined,
    utm_term: params.get('utm_term') || undefined,
    utm_content: params.get('utm_content') || undefined,
  };
};

type UtmData = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
};

export const setUTMData = () => {
  try {
    const existingDataJSON = TTLLocalStorage.getItem(UTL_LOCAL_STORAGE_KEY);
    const existingData: UtmData = existingDataJSON ? JSON.parse(existingDataJSON) : {};
    const newData = getUTParameters();
    const mergedData: UtmData = {
      utm_source: newData.utm_source || existingData.utm_source,
      utm_medium: newData.utm_medium || existingData.utm_medium,
      utm_campaign: newData.utm_campaign || existingData.utm_campaign,
      utm_term: newData.utm_term || existingData.utm_term,
      utm_content: newData.utm_content || existingData.utm_content,
    };

    TTLLocalStorage.setItem(UTL_LOCAL_STORAGE_KEY, JSON.stringify(mergedData));
  } catch (e) {
    console.error(e);
  }
};

export const getUtmDataFromLocalStorage = (): UtmData | undefined => {
  const item = TTLLocalStorage.getItem(UTL_LOCAL_STORAGE_KEY);
  if (item) {
    return JSON.parse(item);
  }
  return undefined;
};
export const deleteUtmDataFromLocalStorage = () => {
  TTLLocalStorage.removeItem(UTL_LOCAL_STORAGE_KEY);
};
