import { max, min } from 'lodash';
import uuid from 'react-uuid';
import { CalculatedUserAvailabilityOut, EnrichedEventData, OrderData, ShiftOut } from '../api';
import { ItemCssDimension } from './calendar-items/CalendarCalculator';

export type UnifiedShiftAndUserAvailability = {
  shift?: ShiftOut;
  userAvailability?: CalculatedUserAvailabilityOut;
  start_time: number;
  end_time: number;
  id: string;
  user_id: string;
  shiftCss?: ItemCssDimension;
  orders?: OrderData[];
  events?: EnrichedEventData[];
  ordersCss?: { id: string; css: ItemCssDimension }[];
  eventsCss?: { id: string; css: ItemCssDimension }[];
};

const orderEventFilter = (item: OrderData | EnrichedEventData, user_id: string, startTime?: number, endTime?: number) =>
  // only relevant employees
  item.employee_ids.includes(user_id) &&
  // has to fit fully in the slot.
  (item.time || 0) >= (startTime || 0) &&
  (item.time || 0) + item.duration <= (endTime || 0);
export const unifySlot = (
  shifts: ShiftOut[],
  userAvailabilities: CalculatedUserAvailabilityOut[],
  filteredOrders: OrderData[],
  filteredEvents: EnrichedEventData[],
) => {
  let filteredOrdersLeft = [...filteredOrders];
  let filteredEventsLeft = [...filteredEvents];
  const unifiedSlot: UnifiedShiftAndUserAvailability[] = [];
  userAvailabilities.forEach((userAvailability) => {
    const shift = shifts.find((shift) => shift.employee_id === userAvailability.user_id);

    const unifiedStartTime = min([shift?.start_time, userAvailability.start_time]) || 0;
    const unifiedEndTime = max([shift?.end_time, userAvailability.end_time]) || 0;
    // we want only orders that fit in the unified slot.
    const orders = filteredOrders.filter((order) =>
      orderEventFilter(order, userAvailability.user_id, unifiedStartTime, unifiedEndTime),
    );
    const events = filteredEvents.filter((order) =>
      orderEventFilter(order, userAvailability.user_id, unifiedStartTime, unifiedEndTime),
    );
    filteredOrdersLeft = filteredOrdersLeft.filter((order) => !orders.includes(order));
    filteredEventsLeft = filteredEventsLeft.filter((event) => !events.includes(event));
    unifiedSlot.push({
      events,
      orders,
      user_id: userAvailability.user_id,
      shift,
      userAvailability,
      start_time: unifiedStartTime,
      end_time: unifiedEndTime,
      id: uuid(),
    });
  });
  shifts.forEach((shift) => {
    if (!unifiedSlot.find((x) => x.shift?.id === shift.id)) {
      const orders = filteredOrders.filter((order) =>
        orderEventFilter(order, shift.employee_id, shift.start_time, shift.end_time),
      );
      const events = filteredEvents.filter((event) =>
        orderEventFilter(event, shift.employee_id, shift.start_time, shift.end_time),
      );
      filteredOrdersLeft = filteredOrdersLeft.filter((order) => !orders.includes(order));
      filteredEventsLeft = filteredEventsLeft.filter((event) => !events.includes(event));

      unifiedSlot.push({
        events,
        orders,
        user_id: shift.employee_id,
        shift,
        start_time: shift.start_time,
        end_time: shift.end_time,
        id: shift.id,
      });
    }
  });
  return { unifiedSlot, filteredOrdersLeft, filteredEventsLeft };
};
