import { Alert, Grid, Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import RotatingLoader from '../common/ui/RotatingLoader';

import { TabsPage } from '../common/components/TabsPage';
import { useGetAccountAuthenticated } from '../session/swr/useGetAccountAuthenticated';
import { AccountSyncSettings } from './AccountSyncSettings';
import { AccountIntegrationsSettings } from './AccountIntegrationsSettings';
import { AccountLocationsSettings } from './accountLocationsSettings/AccountLocationsSettings';
import { AccountGeneralSettings } from './AccountGeneralSettings';
import { OrderCompletionPageSettings } from './OrderCompletionPageSettings';

enum AccountSettingsTabs {
  SYNC = 'sync',
  INTEGRATIONS = 'integrations',
  LOCATIONS = 'locations',
  GENERAL = 'general',
  ORDER_COMPLETE_PAGE = 'ordercompletepage',
}

export const AccountSettingsPage = () => {
  const { t } = useTranslation();
  const { account, isLoading, updateLocalAccount } = useGetAccountAuthenticated({});
  const [isUpdatedSnackOpen, setIsUpdatedSnackOpen] = useState(false);

  if (isLoading) {
    return (
      <Grid
        container
        sx={{
          minHeight: '200px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <RotatingLoader />
      </Grid>
    );
  }

  const tabContent = {
    [AccountSettingsTabs.SYNC]: (
      <AccountSyncSettings
        account={account}
        updateLocalAccount={updateLocalAccount}
        setIsUpdatedSnackOpen={setIsUpdatedSnackOpen}
      />
    ),
    [AccountSettingsTabs.INTEGRATIONS]: (
      <AccountIntegrationsSettings
        account={account}
        updateLocalAccount={updateLocalAccount}
        setIsUpdatedSnackOpen={setIsUpdatedSnackOpen}
      />
    ),
    [AccountSettingsTabs.LOCATIONS]: (
      <AccountLocationsSettings
        account={account}
        updateLocalAccount={updateLocalAccount}
        setIsUpdatedSnackOpen={setIsUpdatedSnackOpen}
      />
    ),
    [AccountSettingsTabs.ORDER_COMPLETE_PAGE]: (
      <OrderCompletionPageSettings
        account={account}
        updateLocalAccount={updateLocalAccount}
        setIsUpdatedSnackOpen={setIsUpdatedSnackOpen}
      />
    ),
    [AccountSettingsTabs.GENERAL]: (
      <AccountGeneralSettings
        account={account}
        updateLocalAccount={updateLocalAccount}
        setIsUpdatedSnackOpen={setIsUpdatedSnackOpen}
      />
    ),
  };

  return (
    <Grid container flexDirection="column" height="100vh" flexWrap="nowrap">
      <Snackbar
        open={isUpdatedSnackOpen}
        onClose={() => setIsUpdatedSnackOpen(false)}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert sx={{ width: '100%' }} onClose={() => setIsUpdatedSnackOpen(false)} severity="success" variant="filled">
          {t('editOrderPage.orderPageTypeSuccess')}
        </Alert>
      </Snackbar>
      <TabsPage tabContent={tabContent} />
    </Grid>
  );
};
