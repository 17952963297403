import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { BuildingBlockType, EmailTemplateType } from '../../../api';
import { DraggableAddItem } from './DraggableAddItem';

type AddItemsPanelProps = {
  isDisabled: boolean;
  templateType: EmailTemplateType;
};
export const AddItemsPanel = ({ isDisabled, templateType }: AddItemsPanelProps) => {
  const { t } = useTranslation();

  const items = [BuildingBlockType.TEXT, BuildingBlockType.LINE_BREAK, BuildingBlockType.IMAGE];
  if (templateType === EmailTemplateType.POST) {
    items.push(BuildingBlockType.RETURNING_CUSTOMER);
  }
  return (
    <Grid container flexDirection="column" gap={1} maxWidth={'150px'} alignItems="center">
      <Typography variant="h6">{t('emailTemplates.blockTypes')}</Typography>
      {items.map((item) => (
        <DraggableAddItem item={item} isDisabled={isDisabled} key={item} />
      ))}
    </Grid>
  );
};
