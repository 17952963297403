/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PriceTypes {
  ENUM = 'enum',
  NUMBER = 'number',
  BOOL = 'bool',
  GT = 'gt',
  MULTIPLE_TICKETS_PRICE = 'multiple_tickets_price',
}
