import { Alert, Divider, Fade, Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import i18n from 'i18next';
import RotatingLoader from '../common/ui/RotatingLoader';
import { OrderPageTypography } from '../order_page/themedComponents/OrderPageTypography';
import {
  Currency,
  Language,
  PaymentLinkPaymentStatus,
  PaymentLinkResponseDTO,
  PaymentLinkService,
  Tip,
  TipType,
} from '../api';
import PaymentIframe from '../order_page/steps/PaymentIframe';
import { priceFormatterFromCurrency } from '../order_page/utils';
import { TipComponent } from '../order_page/steps/payments/TipComponent';

enum Errors {
  NO_ERROR,
  NOT_FOUND,
  ALREADY_PAID,
  ARCHIVED,
}

type PaymentLinksWizardProps = {
  setLanguages: (languages: Language[]) => void;
};

export const PaymentLinksWizard = ({ setLanguages }: PaymentLinksWizardProps) => {
  const posthog = usePostHog();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation();
  const [paymentLinkResponse, setPaymentLinkResponse] = useState<PaymentLinkResponseDTO | undefined>();
  const changeLang = (lang: any) => {
    void i18n.changeLanguage(lang);
    document.body.dir = i18n.dir();
    theme.direction = i18n.dir();
  };
  useEffect(() => {
    if (paymentLinkResponse?.languages) {
      setLanguages(paymentLinkResponse?.languages);
    }
    if (paymentLinkResponse?.default_system_language) {
      changeLang(paymentLinkResponse.default_system_language);
    }
  }, [paymentLinkResponse]);
  const [loadingPaymentLink, setLoadingPaymentLink] = useState(false);
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(Errors.NO_ERROR);
  const paymentLinkId = searchParams.get('linkid');
  const [tip, setTip] = useState<Tip | undefined>(undefined);
  const setTipInternal = (type: TipType, value: number) => {
    setTip({ type, value });
  };
  useEffect(() => {
    posthog.startSessionRecording({});
  }, [posthog]);
  const getPaymentLink = async () => {
    if (paymentLinkId) {
      setLoadingPaymentLink(true);
      try {
        const retrievedPaymentLink = await PaymentLinkService.buildPaymentLink({ payment_link_id: paymentLinkId, tip });
        if (retrievedPaymentLink.payment_link.payment_status === PaymentLinkPaymentStatus.PAID) {
          setError(Errors.ALREADY_PAID);
        } else if (retrievedPaymentLink.archived) {
          setError(Errors.ARCHIVED);
        } else {
          setPaymentLinkResponse(retrievedPaymentLink);
        }
      } catch (e) {
        setError(Errors.NOT_FOUND);
        console.error(e);
      }
      setLoadingPaymentLink(false);
    }
  };
  useEffect(() => {
    void getPaymentLink();
  }, [paymentLinkId, tip]);

  function getErrorText(error: Errors): string {
    switch (error) {
      case Errors.NOT_FOUND:
        return t('paymentLinks.errors.notfounc');
      case Errors.ALREADY_PAID:
        return t('paymentLinks.errors.alreadypaid');
      case Errors.ARCHIVED:
        return t('paymentLinks.errors.archived');
      case Errors.NO_ERROR:
      default:
        return 'No error.';
    }
  }

  function calculateTip(tip?: Tip, price?: number) {
    if (!tip || !price) {
      return 0;
    }
    if (tip.type === TipType.FIXED) {
      return tip.value;
    }
    return (price * tip.value) / 100;
  }

  const stripe_integration_publishable_key = !paymentLinkResponse?.stripe_account_id
    ? undefined
    : paymentLinkResponse?.payment_link.link?.includes('cs_test')
    ? import.meta.env.VITE_REACT_APP_TEST_STRIPE_PUBLISHABLE_KEY
    : import.meta.env.VITE_REACT_APP_STRIPE_PUBLISHABLE_KEY;
  return loadingPaymentLink ? (
    <Grid container height="500px">
      <RotatingLoader />
    </Grid>
  ) : (
    <Grid
      container
      flexDirection="column"
      alignContent="center"
      p={isSmallScreen ? 2 : 4}
      alignItems="center"
      gap={isSmallScreen ? 2 : 6}>
      {paymentLinkResponse?.logo && <img width="120" src={paymentLinkResponse?.logo} />}
      {paymentLinkResponse?.payment_link.title && (
        <OrderPageTypography variant="h3">{paymentLinkResponse?.payment_link.title}</OrderPageTypography>
      )}
      {paymentLinkResponse?.should_show_tip_component && (
        <Grid flexDirection="column" mb={2} alignContent="center">
          <Grid item mb={2}>
            <Divider />
          </Grid>
          <TipComponent tipInitial={tip?.value} setTip={setTipInternal} initialType={tip?.type} />
          {tip?.value && tip?.value > 0 ? (
            <Grid container item gap={2} mt={2}>
              <OrderPageTypography>Gratuity:</OrderPageTypography>
              <OrderPageTypography bold>
                {priceFormatterFromCurrency(calculateTip(tip, paymentLinkResponse?.payment_link.price), Currency.USD)}
              </OrderPageTypography>
            </Grid>
          ) : null}
          <Grid item mt={3}>
            <Divider />
          </Grid>
        </Grid>
      )}
      {paymentLinkResponse?.payment_link.link && (
        <Grid container flexDirection="column">
          <PaymentIframe
            paymentUrl={paymentLinkResponse?.payment_link.link}
            stripe_integration_publishable_key={stripe_integration_publishable_key}
            stripeAccountId={paymentLinkResponse.stripe_account_id}
          />
        </Grid>
      )}
      {error !== Errors.NO_ERROR && (
        <Fade in timeout={1000}>
          <Alert sx={{ width: '230px' }} severity="error" variant="outlined">
            <OrderPageTypography color="error">{getErrorText(error)}</OrderPageTypography>
          </Alert>
        </Fade>
      )}
    </Grid>
  );
};
