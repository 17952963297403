import React from 'react';
import './RotatingLoader.css';
import { Grid, useMediaQuery, useTheme } from '@mui/material';

type RotatingLoaderProps = {
  useRegualarColor?: boolean;
  width?: string;
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const RotatingLoader = ({ useRegualarColor, width = '50px' }: RotatingLoaderProps) => {
  const theme = useTheme();
  // const darkLogo = useRegualarColor ? false : theme.customTheme?.darkLogo;
  // used to have two colors when it was named webook
  // src={`/${darkLogo ? 'favicon_white' : 'favicon'}.png`}
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid className="rotating-image">
        <img src={`/favicon-rotating.png`} alt="Rotating logo" width={isSmallScreen ? undefined : width} />
      </Grid>
    </Grid>
  );
};

export default RotatingLoader;
