import { Grid } from '@mui/material';
import './UnavailabilityBox.scss';
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import { AccountData, OrderPageData, PrivateSlotOut, PrivateSlotType } from '../../api';
import { useLanguage } from '../../common/GeneralUtils';
import { useAuth } from '../../session/InternalAuthProvider';
import { useCalendar } from '../CalendarContext/CalendarContext';
import { getOrderPages } from '../../common/AccountUtils';
import { getTimeFormat } from '../../common/getDateAndTimeFormat';
import { WorkshopCircle } from '../../common/ui/WorkshopCircle';

type PrivateSlotAvailabilityBoxPros = {
  privateSlotAvailability: PrivateSlotOut;
  viewType?: 'month' | 'day';
  css?: any;
};
export const PrivateSlotAvailabilityBox = ({
  privateSlotAvailability,
  viewType,
  css,
}: PrivateSlotAvailabilityBoxPros) => {
  const { t } = useTranslation();
  const language = useLanguage();
  const { openExperienceAvailabilityDrawer } = useCalendar();
  const { authState } = useAuth();

  const account = authState.account as unknown as AccountData;
  const timeFormat = getTimeFormat(account);

  const orderPagesWithArchived = getOrderPages(account, true);

  const getIntervalTimeText = () => {
    if (privateSlotAvailability.start_time && privateSlotAvailability.end_time) {
      return `${moment.unix(privateSlotAvailability.start_time).tz('utc').format(timeFormat)} - ${moment
        .unix(privateSlotAvailability.end_time)
        .tz('utc')
        .format(timeFormat)}`;
    }

    return null;
  };
  if (viewType === 'month') {
    console.error('not implemented for month');
    return null;
  }
  return (
    <Grid
      sx={{
        position: 'absolute',
        width: `calc(${css.width}% - 2px)`,
        left: `${css.left}%`,
        marginTop: `${css.top}px`,
        height: `${css.height - 5}px`,
        border: '1px solid black',
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        overflow: 'hidden',
        alignItems: 'center',
        background: '#e6cde0',
      }}>
      <Grid
        container
        flexDirection="column"
        alignItems="center"
        onClick={() => openExperienceAvailabilityDrawer({ privateSlotAvailability })}
        height={'100%'}>
        <Grid item>
          <Typography variant={'subtitle2'}>
            {privateSlotAvailability.description ? privateSlotAvailability.description : t('calendar.oneOff')}
          </Typography>
        </Grid>

        <Grid item>
          <WorkshopCircle
            withTooltip
            account={account}
            orderPageId={
              orderPagesWithArchived.find(
                (orderPage: OrderPageData) => orderPage.id === privateSlotAvailability.order_page_id,
              )?.id
            }
            language={language}
          />
        </Grid>
        {privateSlotAvailability.type === PrivateSlotType.INTERVAL ? (
          <Grid item>
            <Typography variant="caption" fontWeight="100">
              {getIntervalTimeText()}
            </Typography>
          </Grid>
        ) : (
          <Grid container flexDirection={'column'} alignItems="center">
            <Typography variant="caption" fontWeight="100">
              {privateSlotAvailability.start_seconds?.length || 0 > 1
                ? t('calendar.startTimes')
                : t('calendar.startTime')}
            </Typography>
            {privateSlotAvailability.start_seconds
              ?.sort((a, b) => a - b)
              .map((startSecond, index) => {
                return (
                  <Typography key={index} variant="caption" fontWeight="100">
                    {moment.unix(startSecond).tz('utc').format(timeFormat)}
                  </Typography>
                );
              })}
          </Grid>
        )}

        {/* {privateSlotAvailability.custom_details && ( */}
        {/*  <Grid item> */}
        {/*    {Object.keys(privateSlotAvailability.custom_details).map((key) => { */}
        {/*      return ( */}
        {/*        <Grid> */}
        {/*          <Typography variant={'subtitle2'}> */}
        {/*            {`${privateSlotAvailability.custom_details[key]}-${ */}
        {/*              key === MAX_PARALLEL_PERSON_CAPACITY ? t('availabilitySettings.blockResources') : key */}
        {/*            }`} */}
        {/*          </Typography> */}
        {/*        </Grid> */}
        {/*      ); */}
        {/*    })} */}
        {/*  </Grid> */}
        {/* )} */}
      </Grid>
    </Grid>
  );
};
