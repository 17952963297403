import { Button, Divider, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { KeyedMutator } from 'swr/_internal';
import { AccountData, AccountService, UpdateAccountGeneralInput } from '../api';
import { FormCheckbox } from '../experienceSettings/formComponents/FormCheckBox';
import { FormTextField } from '../experienceSettings/formComponents/FormTextField';

type AccountGeneralSettingsProps = {
  account: AccountData;
  updateLocalAccount: KeyedMutator<AccountData>;
  setIsUpdatedSnackOpen: (value: boolean) => void;
};
export const AccountGeneralSettings = ({
  account,
  updateLocalAccount,
  setIsUpdatedSnackOpen,
}: AccountGeneralSettingsProps) => {
  const { t } = useTranslation();
  const [isUpdatingSettings, setIsUpdatingSettings] = React.useState(false);

  const initialValues: UpdateAccountGeneralInput = {
    should_send_waiting_list_notifications: account?.should_send_waiting_list_notifications || false,
    returning_customer_discount_enabled: !!account?.returning_customer_settings,
    returning_customer_discount_percentage: account?.returning_customer_settings?.value || 20,
    returning_customer_discount_days: account?.returning_customer_settings?.valid_days || 14,
  };

  const validationSchema = yup.object().shape({
    should_send_waiting_list_notifications: yup.boolean().required(),
    returning_customer_discount_enabled: yup.boolean().required(),
    returning_customer_discount_percentage: yup.number().when('returning_customer_discount_enabled', {
      is: true,
      then: yup.number().required().min(0).max(100),
    }),
    returning_customer_discount_days: yup.number().when('returning_customer_discount_enabled', {
      is: true,
      then: yup.number().required(),
    }),
  });

  const onSubmit = async (values: UpdateAccountGeneralInput) => {
    setIsUpdatingSettings(true);
    const updatedAccount = await AccountService.generalSettings(values);
    await updateLocalAccount(updatedAccount);
    setIsUpdatingSettings(false);
    setIsUpdatedSnackOpen(true);
  };

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ handleSubmit, values }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container p={0} flexWrap="nowrap" alignItems="flex-start">
            <Grid container flexDirection="column">
              <Grid item mb={2}>
                <Typography variant="h4">{t('accountSettings.generaltitle')}</Typography>
              </Grid>
              <Grid item>
                <FormCheckbox
                  fieldName={'should_send_waiting_list_notifications'}
                  disabled={false}
                  isFieldUpdate
                  label={t('accountSettings.shouldSendWaitlist')}
                />
              </Grid>
              <Grid item my={3}>
                <Divider />
              </Grid>
              <Grid item>
                <Typography variant="h4">{t('accountSettings.returningcustomer')}</Typography>
              </Grid>
              <Grid item mb={2}>
                <Typography variant="body1" color="grey">
                  {t('accountSettings.returningcustomercaption')}
                </Typography>
              </Grid>
              <Grid item>
                <FormCheckbox
                  fieldName={'returning_customer_discount_enabled'}
                  disabled={false}
                  isFieldUpdate
                  label={t('accountSettings.enableReturningCustomerDiscount')}
                />
              </Grid>
              <Grid container gap={1} flexWrap="nowrap" alignItems="center" mb={1}>
                <Grid>
                  <Typography>{t('accountSettings.returningCustomerDiscountPercentage')}</Typography>
                </Grid>
                <Grid>
                  <FormTextField
                    width={80}
                    endAdornment="%"
                    type="number"
                    fieldName={'returning_customer_discount_percentage'}
                    disabled={!values.returning_customer_discount_enabled}
                    isFieldUpdate
                  />
                </Grid>
              </Grid>
              <Grid container gap={1} flexWrap="nowrap" alignItems="center">
                <Grid>
                  <Typography>{t('accountSettings.returningCustomerDiscountDays')}</Typography>
                </Grid>
                <Grid>
                  <FormTextField
                    width={100}
                    type="number"
                    fieldName={'returning_customer_discount_days'}
                    disabled={!values.returning_customer_discount_enabled}
                    isFieldUpdate
                    endAdornment={t('accountSettings.days')}
                  />
                </Grid>
                <Grid>
                  <Typography>{t('accountSettings.until')}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end" mt={2} xs={2}>
              <Button type="submit" variant="contained" disabled={isUpdatingSettings}>
                {t('save')}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
