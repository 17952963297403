import moment from 'moment-timezone';
import { DEFAULT_START_OFFSET } from '../calendar/calendar-items/CalendarCalculator';
import { AccountData } from '../api';

const formatDuration = (seconds: number, tFunc: any) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  let result = '';
  if (hours === 0 && minutes === 30) {
    return tFunc('duration.just_30min');
  }
  if (hours === 1) {
    result += ` ${tFunc('duration.1hr')} `;
  }
  if (hours === 2) {
    result += ` ${tFunc('duration.2hrs')} `;
  }
  if (hours > 2) {
    result += ` ${hours} ${tFunc('duration.hrs')} `;
  }
  if (minutes === 30) {
    result += tFunc('duration.30min');
  } else if (minutes > 0) {
    result += `${minutes} ${tFunc('duration.min')}`;
  }
  return result;
};
export const isSummerTime = (account: AccountData) => {
  return moment.tz(moment(), account.timezone).isDST();
};
const getAllDayHours = (account: AccountData) => {
  const shouldHaveSummerTimeOffset = isSummerTime(account);
  const totalOffset = shouldHaveSummerTimeOffset ? DEFAULT_START_OFFSET + 1 : DEFAULT_START_OFFSET;

  const result = [];
  let startTime = moment()
    .tz(account.timezone || 'utc')
    .startOf('day');
  startTime.add(totalOffset, 'hours');
  for (let i = 0; i < 24 - totalOffset; i++) {
    result.push(startTime);
    startTime = startTime.clone();
    startTime = startTime.add(1, 'hours');
  }
  return result;
};

const getLocalTime = (utcTimeString: string, timeZone: string): moment.Moment => {
  return moment.utc(utcTimeString).tz(timeZone);
};
const TimeUtils = {
  formatDuration,
  getAllDayHours,
  getLocalTime,
};

export default TimeUtils;
