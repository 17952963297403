/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountData } from '../models/AccountData';
import type { CreateLocationInput } from '../models/CreateLocationInput';
import type { CreateOrderPageInput } from '../models/CreateOrderPageInput';
import type { EmailBlockData } from '../models/EmailBlockData';
import type { EmailSettingsUpdateData } from '../models/EmailSettingsUpdateData';
import type { EmailTemplatesContent } from '../models/EmailTemplatesContent';
import type { EmailTemplatesSettingsUpdateData } from '../models/EmailTemplatesSettingsUpdateData';
import type { EmailTemplateType } from '../models/EmailTemplateType';
import type { GcalConnectDTO } from '../models/GcalConnectDTO';
import type { Language } from '../models/Language';
import type { OrderCompletedPageSettingsData } from '../models/OrderCompletedPageSettingsData';
import type { UpdateAccountGeneralInput } from '../models/UpdateAccountGeneralInput';
import type { UpdateAccountIntegrationsInput } from '../models/UpdateAccountIntegrationsInput';
import type { UpdateAccountSyncInput } from '../models/UpdateAccountSyncInput';
import type { UpdateLocationInput } from '../models/UpdateLocationInput';
import type { UpdateOrderPageInput } from '../models/UpdateOrderPageInput';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccountService {
  /**
   * Get Account
   * @param accountId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getAccount(accountId: any): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/account',
      query: {
        account_id: accountId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Account Typed
   * @param accountId
   * @returns AccountData Successful Response
   * @throws ApiError
   */
  public static getAccountTyped(accountId: any): CancelablePromise<AccountData> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/account/dontuseiterrors',
      query: {
        account_id: accountId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Authenticated Account
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getAccountAuthenticated(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/account/authenticated',
    });
  }

  /**
   * Update Order Page
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static updateOrderPage(requestBody: UpdateOrderPageInput): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/account/order-page',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Order Page
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static createOrderPage(requestBody: CreateOrderPageInput): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/account/order-page',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Archive Order Page
   * @param orderPageId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static archiveOrderPage(orderPageId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/account/order-page',
      query: {
        order_page_id: orderPageId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Account Settings
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static syncSettings(requestBody: UpdateAccountSyncInput): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/account/sync-settings',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Account Settings
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static integrationsSettings(requestBody: UpdateAccountIntegrationsInput): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/account/integrations-settings',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Account Settings
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static generalSettings(requestBody: UpdateAccountGeneralInput): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/account/general-settings',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Gcal Connect
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static accountGcalConnect(requestBody: GcalConnectDTO): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/account/gcal-connect',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Email Templates Parts
   * @returns EmailTemplatesContent Successful Response
   * @throws ApiError
   */
  public static getEmailTemplatesParts(): CancelablePromise<EmailTemplatesContent> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/account/email-templates-parts',
    });
  }

  /**
   * Update Email Templates
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static updateEmailTemplate(requestBody: EmailSettingsUpdateData): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/account/email-templates',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Email Templates
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static updateEmailTemplatesSettings(requestBody: EmailTemplatesSettingsUpdateData): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/account/email-templates-settings',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Email Templates Parts
   * @param orderPageId Order page id
   * @param orderPageType End date (YYYY-MM-DD)
   * @param language End date (YYYY-MM-DD)
   * @param email Email to send test to
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static sendTestEmail(
    orderPageId?: string,
    orderPageType?: EmailTemplateType,
    language?: Language,
    email?: string,
    requestBody?: Array<EmailBlockData>,
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/account/test-email',
      query: {
        order_page_id: orderPageId,
        order_page_type: orderPageType,
        language: language,
        email: email,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Stripe Integration
   * @param code Authorization
   * @param isTestMode Is test mode
   * @returns any Successful Response
   * @throws ApiError
   */
  public static createStripeIntegration(code?: string, isTestMode: boolean = false): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/account/create-stripe-integration',
      query: {
        code: code,
        is_test_mode: isTestMode,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Location
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static updateLocation(requestBody: UpdateLocationInput): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/account/location',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Location
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static createLocation(requestBody: CreateLocationInput): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/account/location',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Archive Location
   * @param locationId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deleteLocation(locationId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/account/location',
      query: {
        location_id: locationId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Get Account Marketing Integrations
   * @param accountId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static getMarketingIntegrations(accountId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/account/marketing-integrations',
      query: {
        account_id: accountId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Update Order Completed Page Settings
   * @param requestBody
   * @returns any Successful Response
   * @throws ApiError
   */
  public static updateOrderCompletedPageSettings(requestBody: OrderCompletedPageSettingsData): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/account/order-completed-page-settings',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
