import { useEffect, useState } from 'react';
import { useBlocker } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import ConfirmationDialog from '../../../common/ui/ConfimationDialog';

export const useNavigationGuard = (hasChanges: boolean) => {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [setNextLocation] = useState<any>(null);
  const blocker = useBlocker(() => hasChanges);

  useEffect(() => {
    if (blocker.state === 'blocked') {
      setNextLocation(blocker.location);
      setOpenDialog(true);
    }
  }, [blocker]);

  const onClose = (actionConfirmed: boolean) => {
    if (actionConfirmed) {
      // @ts-ignore
      blocker.proceed();
    } else {
      // @ts-ignore
      blocker?.reset();
    }
    setOpenDialog(false);
  };

  const NavigationGuardDialog = () => (
    <ConfirmationDialog
      ctaText={t('navigationdialog.navigate')}
      isOpen={openDialog}
      onClose={onClose}
      title={t('navigationdialog.navigateTitle')}
      content={<Typography>{t('navigationdialog.youhavechanges')}</Typography>}
    />
  );

  return { NavigationGuardDialog };
};
