// src/hooks/useGA4Tracking.ts
import ReactGA from 'react-ga4';
import { AccountData, OrderData, VoucherOut } from '../../api';
import { getOrderPages } from '../../common/AccountUtils';
import { getOrderPageLabel } from '../../calendar/dialogs/PrivateExperianceAvailability/PrivateUnavailability/UnavailabilityOrderPagesMultiSelect';

const GA4Pixel = ReactGA;

export const useGA4Tracking = () => {
  const initializeGA4 = (ga4Id: string) => {
    GA4Pixel.initialize(ga4Id);
  };

  const reportTransaction = (transactionData: VoucherOut | OrderData, account: AccountData) => {
    if (!GA4Pixel.isInitialized) {
      return;
    }

    const orderPageName = getOrderPageLabel('en', getOrderPages(account), transactionData.order_page_id);
    GA4Pixel.event('purchase', {
      transaction_id: transactionData.id,
      category: 'Ecommerce',
      label: orderPageName,
      value: transactionData.price,
      currency: account.currency,
      items: [
        {
          item_name: `${orderPageName}-${transactionData.order_page_id}`,
          item_id: transactionData.id,
          price: transactionData.price,
          quantity: 1,
        },
      ],
    });
  };

  return {
    initializeGA4,
    reportTransactionGA4: reportTransaction,
    isInitializedGA4: GA4Pixel.isInitialized,
  };
};
