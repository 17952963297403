import { CalOptions, Event, HebrewCalendar, Location } from '@hebcal/core';
import moment from 'moment';
import Holidays, { HolidaysTypes } from 'date-holidays';
import { HolidayCalendar } from '../api';

let memoizedJewishCalendar: Event[] = [];
let memoizedUsCalendar: HolidaysTypes.Holiday[] = [];

export const getJewishHolidayCalendar = (): Event[] => {
  if (memoizedJewishCalendar.length > 0) {
    return memoizedJewishCalendar;
  }

  const options: CalOptions = {
    isHebrewYear: false,
    candlelighting: false,
    location: Location.lookup('Tel Aviv'),
    sedrot: false,
    omer: false,
    noRoshChodesh: true,
    molad: false,
    yomKippurKatan: false,
    noMinorFast: true,
    shabbatMevarchim: false,
    noSpecialShabbat: false,
  };
  const holidaysToDiscard = [
    'Shabbat Shirah',
    'Tu BiShvat',
    'Shabbat Shekalim',
    'Family Day',
    'Shabbat Zachor',
    'Shushan Purim',
    'Shabbat Parah',
    'Shabbat HaChodesh',
    'Shabbat HaGadol',
    'Yom HaAliyah',
    'Herzl Day',
    'Pesach Sheni',
    'Lag BaOmer',
    'Jabotinsky Day',
    'Shabbat Chazon',
    "Erev Tish'a B'Av",
    "Tish'a B'Av",
    'Shabbat Nachamu',
    "Tu B'Av",
    'Rosh Hashana LaBehemot',
    'Leil Selichot',
    'Yom HaAliyah School Observance',
    'Yitzhak Rabin Memorial Day',
    'Sigd',
    'Ben-Gurion Day',
    'Chag HaBanot',
    'Yom Yerushalayim',
    'Yom HaShoah',
  ];
  const currentYearCal = HebrewCalendar.calendar(options);

  const currentYear = moment().year();
  const nextYear = currentYear + 1;
  const nextYearCal = HebrewCalendar.calendar({ ...options, year: nextYear });
  const calendar = [...currentYearCal, ...nextYearCal];
  memoizedJewishCalendar = calendar.filter((item) => !holidaysToDiscard.includes(item.desc));

  return memoizedJewishCalendar;
};
export const getUsHolidayCalendar = (): HolidaysTypes.Holiday[] => {
  if (memoizedUsCalendar.length > 0) {
    return memoizedUsCalendar;
  }
  const hd = new Holidays('US', 'la', 'no');

  const currentYear = moment().year();
  const nextYear = currentYear + 1;
  const thisYearCalendar = hd.getHolidays(currentYear);
  const nextYearCalendar = hd.getHolidays(nextYear);
  memoizedUsCalendar = [...thisYearCalendar, ...nextYearCalendar];

  return memoizedUsCalendar;
};

const getIsraeliHoliday = (date: string, locale?: 'en' | 'he') => {
  const holidayCalendar = getJewishHolidayCalendar();
  const holiday = holidayCalendar.find((x) => moment(x.getDate().greg()).isSame(moment(date), 'day'));
  return holiday ? `${holiday?.emoji || ''}  ${holiday?.render(locale === 'he' ? 'he-x-NoNikud' : 'en')}` : undefined;
};

const getUsHoliday = (date: string) => {
  const holidayCalendar = getUsHolidayCalendar();
  const holiday = holidayCalendar.find((x) => x.date.startsWith(date));
  if (holiday) {
    return holiday.name;
  }
  return undefined;
};
export const getHoliday = (
  holidayCalendarCountry: HolidayCalendar,
  date: string,
  locale?: 'en' | 'he',
): string | undefined => {
  switch (holidayCalendarCountry) {
    case HolidayCalendar.ISR:
      return getIsraeliHoliday(date, locale);
    case HolidayCalendar.US:
      return getUsHoliday(date);
    default:
      return undefined;
  }
};
