import { Grid, Typography, useTheme } from '@mui/material';
import { groupBy } from 'lodash';
import moment from 'moment';
import { Moment } from 'moment/moment';
import { DayType, resolveDayType } from '../DayType';
import { PickerCondensedDay } from './PickerCondensedDay';
import { DateAvailabilityOut, Language } from '../../../../api';
import { DATE_FORMAT } from '../../../../calendar/CalendarWrapper';
import { useLanguage } from '../../../../common/GeneralUtils';

type CondensedDatePickerProps = {
  availableDatesDict: any;
  minDate: moment.Moment;
  onDateChange: (newValue: Moment | null) => void;
  localDate: moment.Moment | null;
};

export const CondensedDatePicker = ({
  availableDatesDict,
  minDate,
  onDateChange,
  localDate,
}: CondensedDatePickerProps) => {
  const language = useLanguage();
  const theme = useTheme();

  const handleDayClick = (date: string) => {
    onDateChange(moment(date));
  };
  const groupedData: {
    [date: string]: DateAvailabilityOut[];
  } = groupBy(Object.values(availableDatesDict), (obj: DateAvailabilityOut) => moment(obj.date).format('YYYY-MM'));
  return (
    <Grid container flexDirection="column" mt={2}>
      {Object.entries(groupedData).map(([month, monthData]) => (
        <Grid
          container
          flexDirection="column"
          key={month}
          mb={2}
          gap={1}
          border={`1px solid ${theme.customTheme.palette.border}`}
          borderRadius="8px"
          p={1}
          pb={2}>
          <Grid container key={`${month}-monthname`} alignItems="baseline">
            <Typography variant="h6">{moment(month).format('MMMM')}</Typography>
          </Grid>
          <Grid container gap={1}>
            {Object.entries(
              monthData.reduce<
                Record<
                  number,
                  {
                    dayType: DayType;
                    formattedDate: string;
                    isSelected: boolean;
                    date: string;
                  }[]
                >
              >((weeks, availableDate) => {
                const date = moment(availableDate.date);
                const firstSaturday = moment(date).startOf('month').day(6);
                if (firstSaturday.date() > 7) {
                  firstSaturday.add(7, 'days');
                }

                const weekNumberInMonth = Math.ceil((date.date() - firstSaturday.date() + 7) / 7);
                const dayType = resolveDayType({
                  date,
                  availableDatesDict,
                  minDate,
                });
                if (dayType === DayType.NOT_AVAILABLE_DAY) {
                  return weeks;
                }
                // If this week number doesn't exist in the weeks array, create it
                if (!weeks[weekNumberInMonth]) {
                  weeks[weekNumberInMonth] = [];
                }
                // Push the available date to the respective week
                weeks[weekNumberInMonth].push({
                  dayType,
                  formattedDate: date.format(language === Language.HE ? 'יום ddd, D' : 'ddd D'),
                  isSelected: localDate?.format(DATE_FORMAT) === availableDate.date,
                  date: availableDate.date,
                });
                return weeks;
              }, {}),
            ).map(([weekNumber, week]) => (
              <Grid container direction="row" key={weekNumber} spacing={2}>
                {week.map(({ dayType, formattedDate, isSelected, date }) => (
                  <Grid item key={date}>
                    <PickerCondensedDay dayType={dayType} isSelected={isSelected} onClick={() => handleDayClick(date)}>
                      {formattedDate}
                    </PickerCondensedDay>
                  </Grid>
                ))}
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
