import React, { createContext, ReactNode, useContext, useState } from 'react';
import moment from 'moment';
import { PropelUserModel } from '../../api';
import { DATE_FORMAT } from '../consts';
import { useEditOrderDrawer } from './EditOrder/EdItOrderDrawerState';
import { useDeleteDialog } from './DeleteDialog/DeleteDialogState';
import { useShiftDialog } from './ShiftDialog/ShiftDialogState';
import { userColorPalette } from '../userColorPalette';
import { CalendarContextType } from './CalendarContextType';
import { initialCalendarContextState } from './initialCalendarContextState';
import { useExperienceAvailabilityDrawer } from './ExperienceAvailabilityDrawer/ExperienceAvailabilityDrawer';

const CalendarContext = createContext<CalendarContextType>(initialCalendarContextState);

export const useCalendar = () => useContext(CalendarContext);

interface CalendarProviderProps {
  children: ReactNode;
}

export const CalendarProvider: React.FC<CalendarProviderProps> = ({ children }) => {
  const [users, setUsers] = useState<PropelUserModel[]>([]);
  const [userColors, setUserColors] = useState<Record<string, string>>({});
  const [selectedUsersIds, setSelectedUsersIds] = useState<string[]>([]);

  const [startDate, setStartDate] = useState<string>(moment().format(DATE_FORMAT));
  const [endDate, setEndDate] = useState<string>(moment().format(DATE_FORMAT));
  const [shouldBlockSwiping, setShouldBlockSwiping] = useState<boolean>(false);

  const setUsersInternal = (users: PropelUserModel[]) => {
    setUsers(users);
    const userColorMap = {};
    users.forEach((user, index) => {
      // @ts-ignore
      userColorMap[user.user_id] = userColorPalette[index % userColorPalette.length];
    });
    setUserColors(userColorMap);
  };
  const {
    open: openEditOrderDrawer,
    close: closeEditOrderDrawer,
    drawerState: editOrderDrawerState,
  } = useEditOrderDrawer();

  const {
    open: openDeleteOrderDialog,
    close: closeDeleteOrderDialog,
    drawerState: deleteOrderDialogState,
  } = useDeleteDialog();

  const {
    open: openDeleteEventDialog,
    close: closeDeleteEventDialog,
    drawerState: deleteEventDialogState,
  } = useDeleteDialog();

  const {
    open: openDeleteUnavailabilityDialog,
    close: closeDeleteUnavailabilityDialog,
    drawerState: deleteUnavailabilityDialogState,
  } = useDeleteDialog();

  const {
    open: openDeleteShiftDialog,
    close: closeDeleteShiftDialog,
    drawerState: deleteShiftDialogState,
  } = useDeleteDialog();

  const {
    open: openEditShiftDialog,
    close: closeEditShiftDialog,
    drawerState: editShiftDialogState,
  } = useShiftDialog();

  const {
    open: openExperienceAvailabilityDrawer,
    close: closeExperienceAvailabilityDrawer,
    drawerState: experienceAvailabilityDrawerState,
  } = useExperienceAvailabilityDrawer();
  return (
    <CalendarContext.Provider
      value={{
        openExperienceAvailabilityDrawer,
        closeExperienceAvailabilityDrawer,
        experienceAvailabilityDrawerState,

        openEditOrderDrawer,
        closeEditOrderDrawer,
        editOrderDrawerState,

        openDeleteOrderDialog,
        closeDeleteOrderDialog,
        deleteOrderDialogState,

        openDeleteEventDialog,
        closeDeleteEventDialog,
        deleteEventDialogState,

        openDeleteUnavailabilityDialog,
        closeDeleteUnavailabilityDialog,
        deleteUnavailabilityDialogState,

        openDeleteShiftDialog,
        closeDeleteShiftDialog,
        deleteShiftDialogState,

        openEditShiftDialog,
        closeEditShiftDialog,
        editShiftDialogState,

        users,
        setUsers: setUsersInternal,

        startDate,
        endDate,
        setStartDate,
        setEndDate,

        userColors,
        selectedUsersIds,
        setSelectedUsersIds,
        shouldBlockSwiping,
        setShouldBlockSwiping,
        isAnyThingOpen:
          editOrderDrawerState.isOpen ||
          deleteOrderDialogState.isOpen ||
          deleteEventDialogState.isOpen ||
          deleteUnavailabilityDialogState.isOpen ||
          deleteShiftDialogState.isOpen ||
          editShiftDialogState.isOpen ||
          experienceAvailabilityDrawerState.isOpen,
      }}>
      {children}
    </CalendarContext.Provider>
  );
};
