import './OrderPage.scss';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Box, Fade, Grid, Step, Stepper, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import { Gift } from 'react-feather';
import { includes } from 'lodash';
import DetailsStep from './steps/DetailsStep';
import { useOrderPage } from './orderPageContext/useOrderPage';
import PersonalDetailsStep from './steps/PersonalDetailsStep';
import DateAndTimeStep from './steps/dateAndTimeStep/DateAndTimeStep';
import { CodesService, OrderService, PaymentFormType, PaymentsService, VoucherService, VoucherStatus } from '../api';
import PaymentStep from './steps/PaymentStep';
import { useLanguage } from '../common/GeneralUtils';
import OrderUtils from './OrderUtils';
import TTLLocalStorage from '../common/TTLLocalStorage';
import ConfirmationDialog from '../common/ui/ConfimationDialog';
import MonkeybookWidgetFooter from '../common/ui/MonkeybookWidgetFooter';
import VoucherSummary from './steps/VoucherSummary';
import VoucherRedemptionSummary from './steps/VoucherRedemptionSummary';
import PluginUtils, { getPluginData, PluginType } from './PluginUtils';
import ErrorBox from './steps/ErrorBox';
import { OrderPageLabel } from './themedComponents/OrderPageLabel';
import { OrderPageTypography } from './themedComponents/OrderPageTypography';
import { OrderPageButton } from './themedComponents/OrderPageButton';
import { stepStyle } from './StepStyle';
import { OrderPageError } from './themedComponents/OrderPageError';
import { SpecificEventSummary } from './steps/SpecificEventSummary';
import { MultiLocationDetails } from './MultiLocationDetails';
import { OrderPageDivider } from './themedComponents/OrderPageDivider';
import { PriceSummary } from './PriceSummary';
import { OrderPageIntent } from './OrderPageIntent';
import { getUtmDataFromLocalStorage } from './orderCompletion/utmLocalStorage';

export type PersonalDetails = {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  custom_personal_details?: object;
  referral_source?: string;
  voucher_greeting_message?: string;
  recipient_email?: string;
  delivery_date?: string | null;
  delivery_time?: string | null;
  should_send_delayed?: boolean;
};

// this is used in case voucher is not valid for weekend and being redeemed
const calculateShouldHideWeekends = (orderPageState: any) => {
  // we care only if orderpage has weekend  plugin
  const pluginData = getPluginData(orderPageState.orderPage.plugins, PluginType.WEEKEND_AND_ISRAELI_HOLIDAYS);
  if (!pluginData) {
    return false;
  }
  const customDetails = orderPageState.details.custom_details;
  return (
    !PluginUtils.customDetailsHasPlugin(customDetails, PluginType.WEEKEND_AND_ISRAELI_HOLIDAYS) &&
    orderPageState.orderPageIntent === OrderPageIntent.REDEEM_GIFT_CARD
  );
};

enum OrderPageErrors {
  DATE_AND_TIME_NOT_SELECTED = 'dateAndTimeNotSelected',
  SAVE_ORDER_EXCEPTION = 'saveOrderException',
  PHONE_NUMBER_SAVE_ORDER_EXCEPTION = 'phoneNumberSaveOrderException',
  AT_LEAST_ONE_PERSON = 'atLeastOnePerson',
}

const OrderPage = () => {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    reloadDates,
    orderPageState,
    setOrderPageState,
    setActiveStep,
    setOrderCustomDetails,
    setActiveStepAndOrderPageIntent,
    setTime,
  } = useOrderPage();
  const formRef = useRef();
  const [detailsStepErrors] = useState({});
  const [error, setError] = useState<OrderPageErrors | undefined>(undefined);
  const [activeStep, setLocalActiveStep] = useState<number>(orderPageState?.activeStep || 0);
  const [isResetDialogOpen, setIsResetDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const specificEvent = !!orderPageState?.specificEvent;
  const orderPageLanguages = orderPageState?.account.customer_facing_widgets_languages;
  const [shouldDiasbleNext, setShouldDiasbleNext] = useState(false);
  const language = useLanguage();
  const posthog = usePostHog();
  const { voucher, voucherError, orderPageIntent, orderPage, specificOrderPage, specificEventError } = orderPageState;
  const personalDetailsRef = useRef<HTMLDivElement | null>(null);
  const persons = orderPageState.details?.persons || 1;
  const shouldShowVoucherFlow = voucher && voucher.status === VoucherStatus.ACTIVE;
  const [filteredAvailableDates, setFilteredAvailableDates] = useState(orderPageState.availableDates);
  const [sessionTtlMilliseconds, setSessionTtlMilliseconds] = useState<number | null>(null);
  const queryParams = new URLSearchParams(window.location.search);
  const embeddedWidget = queryParams.get('embed') === 'true';
  // we hide title in this case
  const hasSelectedOption = !!queryParams.get('selectedoption');
  const waitingListUrl = `/waiting-list/${orderPageState.account.id}/${orderPageState.experience.id}/${orderPage.id}/${
    orderPageState.details?.persons || 1
  }`;
  const waitListEnabled = orderPageState.orderPage.waiting_list && orderPageState.orderPage.waiting_list.enabled;
  useEffect(() => {
    setFilteredAvailableDates(orderPageState.availableDates);
  }, [orderPageState.availableDates]);

  const elementRef = useCallback((node: any) => {
    if (!node) return;
    const resizeObserver = new ResizeObserver(() => {
      const iframeHeight = document.body.offsetHeight;
      window.parent.postMessage({ height: iframeHeight }, '*');
    });
    resizeObserver.observe(node);
  }, []);

  let sessionTimeout: NodeJS.Timeout | null = null;

  const shouldHideWeekends = calculateShouldHideWeekends(orderPageState);

  const initSessionExpiredTimeout = (ttl?: number) => {
    if (!ttl) {
      ttl = OrderUtils.ORDER_PAGE_TTL * 1000;
    }

    setSessionTtlMilliseconds(ttl);
    sessionTimeout = setTimeout(() => {
      // todo make it nicer then alert.....
      // eslint-disable-next-line no-alert
      alert('Session expired. please reload');
      TTLLocalStorage.removeByPrefix('order-page-state-');
      window.location.reload();
    }, ttl);
  };

  useEffect(() => {
    void (() => {
      if (orderPageState.activeStep === 3) {
        const ttl = TTLLocalStorage.getTTLLeft(`order-page-state-${orderPageState.orderPage.id}`) || 0;
        if (ttl > 0) {
          initSessionExpiredTimeout(ttl);
        }
      }
    })();
  }, []);

  const moveToNextStep = (numberOfSteps = 1, intent?: OrderPageIntent) => {
    setLoading(false);
    if (intent) {
      setActiveStepAndOrderPageIntent(activeStep + numberOfSteps, intent);
    } else {
      setActiveStep(activeStep + numberOfSteps);
    }

    setLocalActiveStep((prevActiveStep) => prevActiveStep + numberOfSteps);
  };

  const handleDetailsDateTimeSelectionNext = (activeStep: number, intent?: OrderPageIntent) => {
    if (Object.keys(detailsStepErrors).length > 0) {
      return;
    }
    if (orderPageState.details.persons < 1) {
      setError(OrderPageErrors.AT_LEAST_ONE_PERSON);
      return;
    }
    if (
      intent !== OrderPageIntent.GIFT_CARD &&
      (orderPageIntent !== OrderPageIntent.REDEEM_GIFT_CARD || activeStep === 1) &&
      (!orderPageState.dateAndTimeDetails.date || !orderPageState.dateAndTimeDetails.time)
    ) {
      setError(OrderPageErrors.DATE_AND_TIME_NOT_SELECTED);
    } else if (orderPageIntent !== OrderPageIntent.REDEEM_GIFT_CARD) {
      moveToNextStep(2, intent);
    } else {
      moveToNextStep(1, intent);
    }
  };

  const getPaymentForm = async (order: any) => {
    return PaymentsService.getPaymentForm({
      language,
      account_id: orderPageState.account.id,
      experience_id: orderPageState.experience.id,
      order_page_id: orderPageState.orderPage.id,
      entity_id: order.id,
      payment_form_type:
        orderPageIntent === OrderPageIntent.GIFT_CARD ? PaymentFormType.VOUCHER : PaymentFormType.ORDER,
    });
  };
  const clearSessionExpiredTimeout = () => {
    if (sessionTimeout) {
      clearTimeout(sessionTimeout);
    }
  };
  const handleReset = () => {
    TTLLocalStorage.removeByPrefix('order-page-state-');
    clearSessionExpiredTimeout();
    window.location.reload();
  };
  const saveOrder = async (personalDetails?: any) => {
    if (orderPageIntent === OrderPageIntent.GIFT_CARD) {
      return VoucherService.createVoucher({
        language,
        ...(personalDetails || orderPageState.personalDetails),
        account_id: orderPageState.account.id,
        experience_id: orderPageState.experience.id,
        order_page_id: orderPageState.orderPage.id,
        ...orderPageState.details,
        can_spam: orderPageState.canSpam,
        utm_data: getUtmDataFromLocalStorage(),
      });
    }
    try {
      const order = await OrderService.saveOrder({
        language,
        account_id: orderPageState.account.id,
        experience_id: orderPageState.experience.id,
        order_page_id: orderPageState.orderPage.id,
        details: orderPageState.details,
        date_and_time_details: orderPageState.dateAndTimeDetails,
        personal_details: personalDetails || orderPageState.personalDetails,
        can_spam: orderPageState.canSpam,
        utm_data: getUtmDataFromLocalStorage(),
      });
      if (orderPageIntent === OrderPageIntent.REDEEM_GIFT_CARD) {
        await CodesService.applyCode({
          entity_id: order.id,
          code: voucher!.code,
        });
      }
      return order;
    } catch (e) {
      console.error(e);
      setError(OrderPageErrors.SAVE_ORDER_EXCEPTION);
      TTLLocalStorage.removeByPrefix('order-page-state-');
      clearSessionExpiredTimeout();
      setSessionTtlMilliseconds(null);
      setActiveStep(0);
      setLocalActiveStep(0);
      reloadDates();
      setLoading(false);
    }
    return undefined;
  };

  const handlePersonalDetailsNext = async () => {
    const data = formRef;
    if (!data.current) {
      return;
    }
    // @ts-ignore
    await data.current.submitForm();
    const { isValid } = data.current;
    if (isValid) {
      setLoading(true);
      // @ts-ignore
      const values = data.current.values as PersonalDetails;
      const personalDetails = {
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        phone: values.phone.replace('-', ''),
        custom_personal_details: values.custom_personal_details,
        referral_source: values.referral_source,
        voucher_greeting_message: values.voucher_greeting_message,
        recipient_email: values.recipient_email,
        delivery_date: values.delivery_date,
        delivery_time: values.delivery_time,
        should_send_delayed: !!values.recipient_email && Boolean(values.delivery_date && values.delivery_time),
      };
      let url = '';
      let order = null;
      if (orderPageState.account.has_payment_integration) {
        order = await saveOrder(personalDetails);
        posthog.capture('Saved Order', {
          orderPageId: orderPageState.orderPage.id,
          accountId: orderPageState.account.id,
        });
        if (orderPageIntent !== OrderPageIntent.REDEEM_GIFT_CARD) {
          if (orderPageIntent === OrderPageIntent.BUY_NOW) {
            initSessionExpiredTimeout(); // no timeouts in guftcards as it's not blocking calendar.
          }
          try {
            url = await getPaymentForm(order);
          } catch (e: any) {
            console.error(e);
            setError(
              e?.body?.includes('717')
                ? OrderPageErrors.PHONE_NUMBER_SAVE_ORDER_EXCEPTION
                : OrderPageErrors.SAVE_ORDER_EXCEPTION,
            );
            TTLLocalStorage.removeByPrefix('order-page-state-');
            clearSessionExpiredTimeout();
            setSessionTtlMilliseconds(null);
            setActiveStep(0);
            setLocalActiveStep(0);
            reloadDates();
            setLoading(false);
            return;
          }
        }
      }
      setOrderPageState({
        ...orderPageState,
        activeStep: activeStep + 1,
        personalDetails,
        paymentUrl: url,
        order_id: order?.id,
        returningCustomer: order?.returning_customer || false,
      });
      setLoading(false);
      setLocalActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handlePaymentFormNext = async () => {
    setLoading(true);
    await saveOrder();
    navigate('/order/completed');
    setLoading(false);
  };
  const handleNext = async (intent?: OrderPageIntent) => {
    if (activeStep === 0 || activeStep === 1) {
      if (personalDetailsRef.current) {
        personalDetailsRef.current.scrollIntoView({ behavior: 'smooth' });
      }
      handleDetailsDateTimeSelectionNext(activeStep, intent);
    } else if (activeStep === 2) {
      await handlePersonalDetailsNext();
    } else if (activeStep === 3) {
      await handlePaymentFormNext();
    }
  };

  const handleBack = () => {
    if (orderPageIntent === OrderPageIntent.REDEEM_GIFT_CARD) {
      setActiveStepAndOrderPageIntent(activeStep - 1, OrderPageIntent.REDEEM_GIFT_CARD);
      setLocalActiveStep((prevActiveStep) => prevActiveStep - 1);
    } else {
      if (activeStep === 2) {
        // reset intent as we wont know if they want a voucher or what
        setActiveStepAndOrderPageIntent(activeStep - 1, OrderPageIntent.BUY_NOW);
      } else {
        setActiveStep(activeStep - 2);
      }
      setLocalActiveStep((prevActiveStep) => prevActiveStep - 2);
    }
  };

  const { date } = orderPageState.dateAndTimeDetails;
  const nextButtonText = () => {
    switch (activeStep) {
      case 0:
        return t('next');
      case 1:
        return t('next');
      case 2:
        return orderPageIntent === OrderPageIntent.REDEEM_GIFT_CARD ? t('redeem') : t('next');
      case 3:
        return t('finish');
      default:
        return t('next');
    }
  };

  if (voucherError) {
    return (
      <ErrorBox
        account={orderPageState.account}
        orderPageLabel={orderPageState.orderPage.label[language]}
        error={voucherError}
      />
    );
  }
  if (specificEventError) {
    return (
      <ErrorBox
        account={orderPageState.account}
        orderPageLabel={orderPageState.orderPage.label[language]}
        error={specificEventError}
        date={orderPageState.dateAndTimeDetails?.date}
        waitingListUrl={waitingListUrl}
        waitListEnabled={waitListEnabled}
      />
    );
  }
  const shouldHideExtraPriceSummary =
    !isSmallScreen || orderPageState.specificOrderPage?.onlyVouchers || activeStep > 0;

  return (
    <Grid
      container
      display="flex"
      justifyContent="space-around"
      sx={{ '::-webkit-scrollbar': { display: 'none' } }}
      style={{
        height: '100%',
        width: '100%',
      }}
      ref={personalDetailsRef}>
      <Grid
        ref={elementRef}
        container
        flexDirection="column"
        style={{
          borderRadius: '12px',
          background: theme.customTheme.palette.background,
          height: '100%',
          width: '100%',
          maxWidth: '710px',
        }}
        className="order-page-wrapper">
        {orderPageState.account.name === 'Speculo' && (
          <>
            <script async src="https://www.googletagmanager.com/gtag/js?id=UA-148720460-1" />
            <script src="/scripts/speculo-gtag.js" />
            <script src="/scripts/speculo-facebook.js" />
            <noscript>
              <img
                height="1"
                width="1"
                style={{ display: 'none' }}
                src="https://www.facebook.com/tr?id=935698493466004&ev=PageView&noscript=1"
                alt="facebook link"
              />
            </noscript>
          </>
        )}
        {orderPageState.account.id === '64e246f85f3e1733728cb75f' && (
          <>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-SZ6K3X7MKK" />
            <script src="/scripts/yaar-gtag.js" />
            <script src="/scripts/yaar-facebook.js" />
            <noscript>
              <img
                height="1"
                width="1"
                style={{ display: 'none' }}
                src="https://www.facebook.com/tr?id=935698493466004&ev=PageView&noscript=1"
                alt="facebook link"
              />
            </noscript>
          </>
        )}

        <Grid
          px={isSmallScreen ? 2 : 4}
          container
          flexDirection="column"
          className={`order-page-box${activeStep !== 0 ? ' order-page-box-padding' : ''}`}
          style={{
            maxWidth: '650px',
            color: theme.customTheme.palette.text.primary,
            border: `2px solid ${theme.customTheme.palette.border}`,
          }}
          boxShadow={4}>
          {!embeddedWidget && (
            <Grid container justifyContent="center" mb={2}>
              <img width="200" src={orderPageState.account?.logo} />
            </Grid>
          )}
          {!hasSelectedOption && (
            <Grid container justifyContent="center">
              <OrderPageTypography variant="h1" style={{ textAlign: 'center', width: '100%' }}>
                {orderPageState.orderPage.label[language]}
              </OrderPageTypography>
            </Grid>
          )}
          <Grid container mt={3} mb={1}>
            <Grid container hidden={isSmallScreen} display={isSmallScreen ? 'none' : 'flex'} flexDirection="column">
              <Stepper activeStep={activeStep}>
                <Step key={1} sx={stepStyle(theme)}>
                  <OrderPageLabel name={t('order_page.step1')} />
                </Step>
                {orderPageIntent === OrderPageIntent.REDEEM_GIFT_CARD && (
                  <Step key={2} sx={stepStyle(theme)}>
                    <OrderPageLabel name={t('order_page.step2')} />
                  </Step>
                )}
                <Step key={3} sx={stepStyle(theme)}>
                  <OrderPageLabel name={t('order_page.step3')} />
                </Step>
                <Step key={4} sx={stepStyle(theme)}>
                  <OrderPageLabel name={t('order_page.step4')} />
                </Step>
              </Stepper>
            </Grid>
            <OrderPageError
              isShowingError={includes(
                [
                  OrderPageErrors.SAVE_ORDER_EXCEPTION,
                  OrderPageErrors.PHONE_NUMBER_SAVE_ORDER_EXCEPTION,
                  OrderPageErrors.AT_LEAST_ONE_PERSON,
                ],
                error,
              )}
              message={
                error === OrderPageErrors.PHONE_NUMBER_SAVE_ORDER_EXCEPTION
                  ? t('orderPageErrors.saveExceptionNonIsraeliPhoneNumber')
                  : error === OrderPageErrors.AT_LEAST_ONE_PERSON
                  ? t('orderPageErrors.atLeastOnePerson')
                  : t('orderPageErrors.saveException')
              }
              setClearError={() => setError(undefined)}
            />
            {specificEvent && activeStep === 0 && (
              <SpecificEventSummary specificEvent={orderPageState.specificEvent!} />
            )}
            <Grid container wrap="nowrap" mt={activeStep !== 0 ? 0 : isSmallScreen ? 1 : 3} flexDirection="column">
              <Grid item hidden={activeStep !== 0}>
                {shouldShowVoucherFlow ? (
                  <VoucherSummary voucher={voucher} />
                ) : (
                  <DetailsStep errors={detailsStepErrors} />
                )}
              </Grid>
              {!shouldHideExtraPriceSummary && (
                <Grid item mt={3}>
                  <PriceSummary
                    orderPageState={orderPageState}
                    activeStep={activeStep}
                    persons={persons}
                    date={date}
                    orderPageIntent={orderPageIntent}
                  />
                </Grid>
              )}
              <Grid
                hidden={
                  activeStep > 1 ||
                  (orderPageIntent === OrderPageIntent.REDEEM_GIFT_CARD && activeStep !== 1) ||
                  specificOrderPage?.onlyVouchers ||
                  specificEvent
                }>
                <MultiLocationDetails
                  defaultLocationId={orderPage.location_id}
                  account={orderPageState.account}
                  initialAvailableDates={orderPageState.availableDates}
                  isLoadingDates={orderPageState.isLoadingDates}
                  setFilteredAvailableDates={setFilteredAvailableDates}
                />
                <DateAndTimeStep
                  account={orderPageState.account}
                  initialAvailableDates={orderPageState.availableDates}
                  locations={orderPageState.account.locations}
                  setShouldDisableNext={setShouldDiasbleNext}
                  availableDates={filteredAvailableDates}
                  setTime={setTime}
                  shouldHideWeekends={shouldHideWeekends}
                  orderPageIntent={orderPageIntent}
                  orderPage={orderPage}
                  date={orderPageState.dateAndTimeDetails.date}
                  time={orderPageState.dateAndTimeDetails.time}
                  isLoadingDates={orderPageState.isLoadingDates}
                  waitingListUrl={waitingListUrl}
                  orderDetails={orderPageState.details}
                  setDateTimeAndPrice={({
                    newDate,
                    newTime,
                    newPrice,
                  }: {
                    newDate?: string | null;
                    newTime?: number | null;
                    newPrice?: number;
                  }) =>
                    setOrderPageState({
                      ...orderPageState,
                      dateAndTimeDetails: {
                        date: newDate,
                        time: newTime,
                      },
                      details: {
                        ...orderPageState.details,
                        price: newPrice || orderPageState.details.price,
                      },
                    })
                  }
                  noMinDate={specificOrderPage?.noMinDate}
                  troubleshoot={specificOrderPage?.troubleshoot}
                  specificEvent={specificEvent}
                  preSelectedDate={specificOrderPage?.preSelectedDate}
                />
              </Grid>

              <Grid item hidden={activeStep !== 2}>
                <PersonalDetailsStep
                  formRef={formRef}
                  orderPage={orderPageState.orderPage}
                  setOrderCustomDetails={setOrderCustomDetails}
                  orderPageIntent={orderPageIntent}
                  holidayCalendar={orderPageState.account.holiday_calendar}
                />
              </Grid>

              {activeStep === 3 && orderPageIntent !== OrderPageIntent.REDEEM_GIFT_CARD && (
                <PaymentStep orderPageIntent={orderPageIntent} sessionTtlMilliseconds={sessionTtlMilliseconds} />
              )}
              {activeStep === 3 && orderPageIntent === OrderPageIntent.REDEEM_GIFT_CARD && <VoucherRedemptionSummary />}
            </Grid>
            <Grid container className="middle-box" mb={2} justifyContent="center">
              {error === OrderPageErrors.DATE_AND_TIME_NOT_SELECTED &&
                activeStep < 2 &&
                (!orderPageState.dateAndTimeDetails.date || !orderPageState.dateAndTimeDetails.time) && (
                  <Fade in timeout={1000}>
                    <Alert sx={{ width: '230px', marginTop: '16px' }} severity="error">
                      <OrderPageTypography color="error">{t('order_page.date_error')}</OrderPageTypography>
                    </Alert>
                  </Fade>
                )}
            </Grid>
            {activeStep !== 3 && orderPageState.account.has_payment_integration && (
              <Grid
                container
                px={0.5}
                mx={activeStep !== 0 && !isSmallScreen ? 4 : 0}
                className="footer-container"
                display="flex"
                justifyContent="space-between"
                direction={isSmallScreen && activeStep === 0 ? 'column' : 'row'}>
                {activeStep !== 0 && (
                  <OrderPageButton variant="outlined" color="inherit" disabled={activeStep === 0} onClick={handleBack}>
                    <OrderPageTypography color="button.outlined">{t('back')}</OrderPageTypography>
                  </OrderPageButton>
                )}
                {!isSmallScreen && (
                  <PriceSummary
                    orderPageState={orderPageState}
                    activeStep={activeStep}
                    persons={persons}
                    date={date}
                    orderPageIntent={orderPageIntent}
                  />
                )}

                <Grid
                  item
                  xs={isSmallScreen ? undefined : 6}
                  flexDirection="column"
                  display={specificOrderPage?.onlyVouchers && activeStep === 0 ? 'none' : 'flex'}
                  alignItems="flex-end">
                  <OrderPageButton
                    disabled={orderPageState.isLoadingDates || shouldDiasbleNext || specificOrderPage?.troubleshoot}
                    loadingButton
                    sx={{
                      width: '150px',
                    }}
                    loading={loading}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      posthog.startSessionRecording({});
                      posthog.capture('Button clicked', {
                        name: 'Start order',
                        orderPageId: orderPageState.orderPage.id,
                        accountId: orderPageState.account.id,
                      });
                      void handleNext();
                    }}
                    className="next-button">
                    <OrderPageTypography variant="h6" color="button.contained" bold>
                      {nextButtonText()}
                    </OrderPageTypography>
                  </OrderPageButton>
                </Grid>
              </Grid>
            )}

            {activeStep === 3 &&
              orderPageState.account.has_payment_integration &&
              orderPageIntent !== OrderPageIntent.REDEEM_GIFT_CARD && (
                <Box className="footer-container" sx={{ justifyContent: 'flex-start', display: 'flex' }}>
                  <OrderPageButton
                    variant="outlined"
                    color="inherit"
                    onClick={() => setIsResetDialogOpen(true)}
                    sx={{ mr: 1 }}>
                    <OrderPageTypography>{t('reset')}</OrderPageTypography>
                  </OrderPageButton>
                </Box>
              )}
          </Grid>
          {activeStep === 0 && orderPageIntent !== OrderPageIntent.REDEEM_GIFT_CARD && !specificEvent && (
            <Grid container flexDirection="column" gap={2} my={3}>
              {!specificOrderPage?.onlyVouchers && (
                <OrderPageDivider>
                  <OrderPageTypography>{t('order_page.divider')}</OrderPageTypography>
                </OrderPageDivider>
              )}
              <Grid
                item
                display="flex"
                justifyContent="space-around"
                flexDirection="column"
                alignItems="center"
                gap={2}>
                {!specificOrderPage?.onlyVouchers && <OrderPageTypography>{t('vouchersremark')}</OrderPageTypography>}
                <OrderPageButton
                  sx={{
                    width: '150px',
                  }}
                  startIcon={<Gift size={16} color={theme.customTheme.palette.text.button.outlined} />}
                  variant="outlined"
                  onClick={() => {
                    posthog.startSessionRecording({});
                    posthog.capture('Button clicked', {
                      name: 'Start voucher',
                      orderPageId: orderPageState.orderPage.id,
                    });
                    void handleNext(OrderPageIntent.GIFT_CARD);
                  }}
                  className="next-button"
                  disabled={specificOrderPage?.troubleshoot || orderPageState.isLoadingDates}>
                  <OrderPageTypography variant="h6" color="button.outlined">
                    {specificOrderPage?.onlyVouchers ? t('purchaseVoucher') : t('purchasegift')}
                  </OrderPageTypography>
                </OrderPageButton>
              </Grid>
            </Grid>
          )}
        </Grid>

        <MonkeybookWidgetFooter languages={orderPageLanguages} accountId={orderPageState.account.id} />

        <ConfirmationDialog
          title={t('order_page.reset_dialog.title')}
          ctaText={t('reset')}
          isOpen={isResetDialogOpen}
          onClose={(actionConfirmed: any) => {
            setIsResetDialogOpen(false);
            if (actionConfirmed) {
              handleReset();
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

export default OrderPage;
