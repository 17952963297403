import React, { useEffect, useState } from 'react';
import { Divider, Grid, TextField, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';

import { Form, Formik } from 'formik';
import * as yup from 'yup';
import moment from 'moment/moment';
import { DatePicker } from '@mui/x-date-pickers';
import Typography from '@mui/material/Typography';
import { AccountData, DiscountOut, DiscountService, DiscountType, OrderPageData } from '../api';
import TitledDrawer from '../common/ui/TitledDrawer';
import { DATE_FORMAT } from '../calendar/CalendarWrapper';
import { InfoWithTooltip } from '../common/ui/InfoWithTooltip';
import { useAuth } from '../session/InternalAuthProvider';
import { OrderPagesMultiSelect } from '../userSettings/OrderPagesMultiSelect';
import { getOrderPages } from '../common/AccountUtils';
import { getDateFormat } from '../common/getDateAndTimeFormat';
import { FormCheckbox } from '../experienceSettings/formComponents/FormCheckBox';

type EditDiscountDialogProps = {
  dialogIsOpen: boolean;
  setDialogIsOpen: (isOpen: boolean) => void;
  discount?: DiscountOut;
  onUpdate?: (updated: DiscountOut) => void;
  onAdd?: (updated: DiscountOut) => void;
};
type EditDiscountFormProps = {
  start_date: moment.Moment;
  end_date: moment.Moment;
  type: DiscountType;
  value: number;
  code: string;
  description: string;
  order_page_ids: string[];
  should_limit_usage: boolean;
  limit_usage_count: number;
  redemption_min_date?: moment.Moment;
  redemption_max_date?: moment.Moment;
  is_valid_only_for_vouchers?: boolean;
  is_valid_for_only_once_per_email?: boolean;
};

export const EditDiscountDialog = ({
  dialogIsOpen,
  setDialogIsOpen,
  discount,
  onUpdate,
  onAdd,
}: EditDiscountDialogProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasCodeUsedError, setHasCodeUsedError] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setHasCodeUsedError(false);
    }, 2500);
  }, [hasCodeUsedError]);
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const { longDateFormat } = getDateFormat(account);
  const orderPages = getOrderPages(account);
  const validationSchema = yup.object().shape({
    description: yup.string().required(t('calendar.edit_order.mandatoryField')),
    code: yup.string().required(t('calendar.edit_order.mandatoryField')),
    value: yup
      .number()
      .required(t('calendar.edit_order.mandatoryField'))
      .min(0, t('calendar.edit_order.positive'))
      .lessThan(101),
    order_page_ids: yup.array().min(1, t('calendar.edit_order.moreThanOne')),
    limit_usage_count: yup.number().when('should_limit_usage', {
      is: true,
      then: yup
        .number()
        .required(t('calendar.edit_order.mandatoryField'))
        .moreThan(0, t('calendar.edit_order.moreThanZero')),
    }),
  });
  const initialValues: EditDiscountFormProps = {
    start_date: moment(discount?.start_date),
    end_date: discount?.end_date ? moment(discount?.end_date) : moment().add(7, 'days'),
    type: DiscountType.PERCENT,
    value: discount?.value || 0,
    code: discount?.code || '',
    description: discount?.description || '',
    order_page_ids: discount?.order_page_ids || orderPages.map((x: OrderPageData) => x.id),
    should_limit_usage: discount?.should_limit_usage || false,
    limit_usage_count: discount?.limit_usage_count || 0,
    redemption_min_date: discount?.redemption_min_date ? moment(discount?.redemption_min_date) : undefined,
    redemption_max_date: discount?.redemption_max_date ? moment(discount?.redemption_max_date) : undefined,
    is_valid_only_for_vouchers: !!discount?.is_valid_only_for_vouchers,
    is_valid_for_only_once_per_email: !!discount?.is_valid_for_only_once_per_email,
  };
  const onSubmit = async (values: EditDiscountFormProps) => {
    try {
      setIsSubmitting(true);
      if (discount) {
        const updatedDiscount = await DiscountService.updateDiscount({
          id: discount.id,
          ...values,
          start_date: values.start_date.format(DATE_FORMAT),
          end_date: values.end_date.format(DATE_FORMAT),
          redemption_min_date: values.redemption_min_date?.format(DATE_FORMAT),
          redemption_max_date: values.redemption_max_date?.format(DATE_FORMAT),
          status: discount.status,
        });
        if (onUpdate) {
          onUpdate(updatedDiscount);
        }
      } else {
        const newDiscount = await DiscountService.createDiscount({
          ...values,
          start_date: values.start_date.format(DATE_FORMAT),
          end_date: values.end_date.format(DATE_FORMAT),
          redemption_min_date: values.redemption_min_date?.format(DATE_FORMAT),
          redemption_max_date: values.redemption_max_date?.format(DATE_FORMAT),
        });
        if (onAdd) {
          onAdd(newDiscount);
        }
      }
      setIsSubmitting(false);
      setDialogIsOpen(false);
    } catch (error: any) {
      console.error(error);
      setIsSubmitting(false);
      // if code its error 400
      if (error.status === 400) {
        setHasCodeUsedError(true);
      }
    }
  };
  let title = t('discounts.editDialog.addDiscount');
  if (discount) {
    title = t('discounts.editDialog.editDiscount');
  }
  return (
    <TitledDrawer title={title} open={dialogIsOpen} onClose={() => setDialogIsOpen(false)}>
      <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ setFieldValue, values, errors, touched, handleChange, submitForm }) => {
          return (
            <Form style={{ height: '100%' }}>
              <Grid
                container
                flexDirection="column"
                justifyContent="space-between"
                width={isSmallScreen ? window.innerWidth : '550px'}
                p={isSmallScreen ? '8px' : '16px'}
                pb="0px"
                overflow="hidden"
                height="100%">
                <Grid
                  gap={2}
                  p="8px"
                  container
                  flexDirection="column"
                  style={{ overflowY: 'scroll', maxHeight: 'calc(100% - 70px)' }}
                  flexWrap="nowrap"
                  overflow="scroll">
                  <Grid item flexWrap="nowrap" minWidth="150px">
                    <TextField
                      size="small"
                      id="description"
                      name="description"
                      variant="outlined"
                      label={t('calendar.edit_order.description')}
                      value={values.description}
                      onChange={handleChange}
                      error={touched.description && Boolean(errors.description)}
                      helperText={touched.description && errors.description}
                    />
                  </Grid>
                  <Grid item container flexWrap="nowrap" minWidth="150px" alignItems="center" gap={3}>
                    <Grid item maxWidth="186px">
                      <DatePicker
                        label={t('calendar.unavailability_dialog.start_date')}
                        onChange={(value) => setFieldValue('start_date', value, true)}
                        value={values.start_date}
                        format={longDateFormat}
                        disabled={!!discount}
                      />
                    </Grid>
                    <InfoWithTooltip title={t('discounts.editDialog.immutable')} />
                  </Grid>
                  <Grid item maxWidth="186px">
                    <DatePicker
                      label={t('calendar.unavailability_dialog.end_date')}
                      onChange={(value) => setFieldValue('end_date', value, true)}
                      value={values.end_date}
                      format={longDateFormat}
                      disablePast
                    />
                  </Grid>
                  <Grid item container flexWrap="nowrap" minWidth="150px" alignItems="center" gap={3}>
                    <TextField
                      size="small"
                      type="number"
                      id="value"
                      name="value"
                      variant="outlined"
                      label={t('discounts.editDialog.value')}
                      value={values.value}
                      onChange={handleChange}
                      error={touched.value && Boolean(errors.value)}
                      helperText={touched.value && errors.value}
                    />
                  </Grid>
                  <Grid item container flexWrap="nowrap" minWidth="150px" alignItems="center" gap={3}>
                    <TextField
                      size="small"
                      id="code"
                      name="code"
                      variant="outlined"
                      label={t('discounts.editDialog.code')}
                      value={values.code}
                      onChange={handleChange}
                      error={touched.code && Boolean(errors.code)}
                      helperText={touched.code && errors.code}
                    />
                  </Grid>
                  <Divider />
                  <Grid item container minWidth="150px" alignItems="flex-start" flexDirection="column" gap={1}>
                    <Grid item xs display="flex" alignItems="center" gap={1}>
                      <Typography>{t('discounts.resvalidityOnly')}</Typography>
                      <InfoWithTooltip title={t('discounts.resvalidityDescription')} />
                    </Grid>
                    <Grid container gap={3}>
                      <Grid item maxWidth="186px">
                        <DatePicker
                          label={t('calendar.unavailability_dialog.start_date')}
                          onChange={(value) => setFieldValue('redemption_min_date', value, true)}
                          value={values.redemption_min_date}
                          format={longDateFormat}
                          disablePast
                        />
                      </Grid>
                      <Grid item maxWidth="186px">
                        <DatePicker
                          label={t('calendar.unavailability_dialog.end_date')}
                          onChange={(value) => setFieldValue('redemption_max_date', value, true)}
                          value={values.redemption_max_date}
                          format={longDateFormat}
                          disablePast
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <FormCheckbox
                    fieldName={'is_valid_for_only_once_per_email'}
                    label={t('discounts.editDialog.onePerEmail')}
                    disabled={false}
                    isFieldUpdate={!!discount}
                  />
                  <FormCheckbox
                    fieldName={'is_valid_only_for_vouchers'}
                    label={t('discounts.editDialog.onlyVouchers')}
                    disabled={false}
                    isFieldUpdate={!!discount}
                  />
                  <Grid item container flexWrap="nowrap" minWidth="250px" alignItems="center" gap={3}>
                    <FormCheckbox
                      disabled={!!discount}
                      isFieldUpdate={!!discount}
                      fieldName={'should_limit_usage'}
                      onChange={() => {
                        void setFieldValue('should_limit_usage', !values.should_limit_usage);
                        void setFieldValue('limit_usage_count', 0);
                      }}
                      label={t('discounts.editDialog.limitUsageCount')}
                    />

                    <Grid width={'70px'} hidden={!values.should_limit_usage}>
                      <TextField
                        type="number"
                        disabled={!!discount || !values.should_limit_usage}
                        size="small"
                        id="limit_usage_count"
                        name="limit_usage_count"
                        variant="outlined"
                        // label={t('discounts.editDialog.limitUsageCount')}
                        value={values.limit_usage_count}
                        onChange={handleChange}
                        error={touched.limit_usage_count && Boolean(errors.limit_usage_count)}
                        helperText={touched.limit_usage_count && errors.limit_usage_count}
                      />
                    </Grid>
                    <InfoWithTooltip title={t('discounts.editDialog.immutable')} />
                  </Grid>
                  <Grid item container flexDirection="column" minWidth="250px">
                    <Typography fontWeight={700}>{t('discounts.editDialog.specifyOrderPages')}</Typography>
                    <OrderPagesMultiSelect
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      orderPagesFieldName="order_page_ids"
                      account={account}
                    />
                  </Grid>
                </Grid>
                <Grid container justifyContent="flex-end" mb={2}>
                  {hasCodeUsedError && <Typography color="error">{t('discounts.editDialog.codeUsed')}</Typography>}

                  <LoadingButton
                    loading={isSubmitting}
                    variant="contained"
                    sx={{ textTransform: 'none' }}
                    onClick={submitForm}>
                    {discount ? t('save_changes') : t('add')}
                  </LoadingButton>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </TitledDrawer>
  );
};
