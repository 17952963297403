import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { Chip, Divider, Grid, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { KeyedMutator } from 'swr/_internal';
import Typography from '@mui/material/Typography';
import { Delete, OpenInNew, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { AccountData, AccountLocationData, AccountService, Language, LocationsStatus } from '../../api';
import { FormTextField } from '../../experienceSettings/formComponents/FormTextField';
import { dynamicLabelSchema } from '../../experienceSettings/sections/informationSection/dynamicLabelSchema';
import { FormCheckbox } from '../../experienceSettings/formComponents/FormCheckBox';

type LocationFormProps = {
  location: AccountLocationData;
  languages: string[];
  setIsUpdatedSnackOpen: (isOpen: boolean) => void;
  readOnly: boolean;
  removeFromValues: () => void;
  account: AccountData;
  updateLocalAccount: KeyedMutator<AccountData>;
  setValues: any;
};

export const LocationItem = ({
  location,
  languages,
  setIsUpdatedSnackOpen,
  readOnly,
  removeFromValues,
  account,
  updateLocalAccount,
  setValues,
}: LocationFormProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const hasNoLocations = account.locations.filter((x) => x.status !== LocationsStatus.ARCHIVED).length === 0;

  const initialValues = {
    address: location.address || {},
    name: location.name || {},
    description: location.description || {},
    id: location.id || undefined,
    default: location.default || hasNoLocations || false,
    create_stripe_tax: false,
    stripe_tax_rate: 0,
  };

  const validationSchema = yup.object().shape({
    address: yup.object().shape(dynamicLabelSchema(t, languages as Language[], true)),
    name: yup.object().shape(dynamicLabelSchema(t, languages as Language[], true)),
    description: yup.object().shape(dynamicLabelSchema(t, languages as Language[])),
  });

  // @ts-ignore
  const handleSubmit = async (values, { resetForm }) => {
    const updatedLocation = { ...location, ...values };
    try {
      if (updatedLocation.id) {
        const updatedAccount = await AccountService.updateLocation({
          ...updatedLocation,
          id: updatedLocation.id,
        });
        await updateLocalAccount(updatedAccount);
        setValues({ locations: updatedAccount.locations });
      } else {
        const updatedAccount = await AccountService.createLocation({
          ...updatedLocation,
        });
        await updateLocalAccount(updatedAccount);
        setValues({ locations: updatedAccount.locations });
        resetForm();
      }
      setIsUpdatedSnackOpen(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async () => {
    if (!location.id) {
      removeFromValues();
      return;
    }
    try {
      const updatedAccount = await AccountService.deleteLocation(location.id);
      await updateLocalAccount(updatedAccount);
      setValues({ locations: updatedAccount.locations });

      setIsUpdatedSnackOpen(true);
    } catch (error) {
      console.error(error);
    }
  };
  const isCreateLocation = !location.id;
  const hasStripeIntegration = !!account.stripe_connect;
  const stripeTaxIdLink = account.stripe_connect?.is_stripe_test_account
    ? `https://dashboard.stripe.com/test/tax-rates/${location.stripe_tax_id}`
    : `https://dashboard.stripe.com/tax-rates/${location.stripe_tax_id}`;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({ isSubmitting, dirty, values }) => {
        return (
          <Form>
            <Grid
              container
              flexDirection="column"
              border={`${isCreateLocation ? 4 : 1}px solid ${isCreateLocation ? '#66AB5B' : 'black'}`}
              m={2}
              p={2}
              borderRadius="8px"
              xs={11}
              lg={6}>
              <Grid container flexWrap="nowrap">
                <Grid item container gap={2} alignItems="center" flexWrap="nowrap" flexDirection={'row'}>
                  <LoadingButton
                    loading={isSubmitting}
                    onClick={handleDelete}
                    disabled={isSubmitting || readOnly || initialValues.default}
                    variant="outlined">
                    {isSmallScreen ? <Delete /> : t('locations.delete')}
                  </LoadingButton>
                  <LoadingButton
                    loading={isSubmitting}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting || readOnly || !dirty}>
                    {isSmallScreen ? <Save /> : t('locations.save')}
                  </LoadingButton>
                </Grid>
                <Grid item alignSelf={'flex-end'}>
                  {initialValues.default && <Chip variant="outlined" label={t('locations.default')} color={'info'} />}
                </Grid>
              </Grid>
              {languages.map((lang, languageIndex) => (
                <Grid container gap={1} mt={2} key={lang} flexDirection="column">
                  <Typography fontWeight={700}>{t(`emailTemplates.${lang}`)}</Typography>
                  <Grid item>
                    <FormTextField
                      isFieldUpdate={!isCreateLocation}
                      width={300}
                      fieldName={`name.${lang}`}
                      type="text"
                      label={t('locations.name')}
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item>
                    <FormTextField
                      isFieldUpdate={!isCreateLocation}
                      width={300}
                      fieldName={`address.${lang}`}
                      type="text"
                      label={t('locations.address')}
                      disabled={readOnly}
                    />
                  </Grid>
                  <Grid item>
                    <FormTextField
                      isFieldUpdate={!isCreateLocation}
                      width={300}
                      fieldName={`description.${lang}`}
                      type="text"
                      label={t('locations.description')}
                      minRows={3}
                      caption={t('locations.descriptionCaptions')}
                      disabled={readOnly}
                    />
                  </Grid>
                  {!isCreateLocation && hasStripeIntegration && location.stripe_tax_rate && (
                    <Grid container alignItems="center">
                      <Grid item>
                        <Typography fontWeight="bold">
                          {t('locations.stripeTaxRate')}: {location.stripe_tax_rate}%
                        </Typography>
                      </Grid>
                      <IconButton onClick={() => window.open(stripeTaxIdLink, '_blank')}>
                        <OpenInNew style={{ width: '16px' }} />
                      </IconButton>
                    </Grid>
                  )}
                  {!isCreateLocation && hasStripeIntegration && !location.stripe_tax_rate && (
                    <Grid item>
                      <Typography>
                        Not charging sales tax for this location. To set up sales tax, contact support
                      </Typography>
                    </Grid>
                  )}
                  {isCreateLocation && hasStripeIntegration && (
                    <Grid container>
                      <Grid item>
                        <FormCheckbox
                          fieldName={'create_stripe_tax'}
                          disabled={false}
                          isFieldUpdate={!isCreateLocation}
                          label={t('locations.createStripeTax')}
                        />
                      </Grid>
                      <Grid item>
                        <FormTextField
                          width={80}
                          fieldName={'stripe_tax_rate'}
                          disabled={!values.create_stripe_tax}
                          isFieldUpdate={!isCreateLocation}
                          endAdornment="%"
                        />
                      </Grid>
                    </Grid>
                  )}

                  <Grid pt={1}>
                    {languages.length > 1 && languageIndex < languages.length - 1 && (
                      <Divider
                        style={{
                          marginRight: isSmallScreen ? undefined : 80,
                          marginLeft: isSmallScreen ? undefined : 80,
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
