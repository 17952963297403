import './DatesCarousel.scss';
import moment from 'moment';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Moment } from 'moment/moment';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { StaticDatePicker } from '@mui/x-date-pickers';
import { CalendarView, DATE_FORMAT, mapCalendarViewToNumOfDays } from './CalendarWrapper';

const getNumOfDaysCarouselText = (currentDate: string, numOfDays: number, isSmallScreen: boolean) => {
  const currentDateMom = moment(currentDate);
  const nextDateMom = moment(currentDate).add(numOfDays - 1, 'days');
  let carouselText = currentDateMom.format('MMM D');
  if (isSmallScreen) {
    return carouselText;
  }

  carouselText += ' - ';
  if (currentDateMom.month() !== nextDateMom.month()) {
    carouselText += nextDateMom.format('MMM ');
  }
  carouselText += nextDateMom.format('D');
  return carouselText;
};

const getMonthCarouselText = (currentDate: string) => {
  return moment(currentDate).format('MMMM YY');
};

const getDayCarouselText = (currentDate: string, isSmallScreen: boolean) => {
  if (isSmallScreen) {
    return moment(currentDate).format('MMM D');
  }
  return moment(currentDate).format('ddd, MMM D');
};

const getCarouselText = (currentDate: string, calendarView: CalendarView, isSmallScreen: boolean) => {
  if (calendarView === CalendarView.DAY) {
    return getDayCarouselText(currentDate, isSmallScreen);
  }
  if (calendarView === CalendarView.MONTH) {
    return getMonthCarouselText(currentDate);
  }
  return getNumOfDaysCarouselText(currentDate, mapCalendarViewToNumOfDays(calendarView), isSmallScreen);
};
type DatesCarouselProps = {
  currentDate: string;
  setCurrentDate: (currentDate: string) => void;
  calendarView: CalendarView;
  previousNextCallbacks: {
    previous: any;
    next: any;
  };
};
const DatesCarousel = (props: DatesCarouselProps) => {
  const { currentDate, setCurrentDate, calendarView, previousNextCallbacks } = props;
  const theme = useTheme();
  const { i18n } = useTranslation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [datesSelectorIsOpen, setDatesSelectorIsOpen] = useState(false);

  const carouselText = getCarouselText(currentDate, calendarView, isSmallScreen);

  const next = () => {
    if (calendarView === CalendarView.WEEK) {
      setCurrentDate(moment(currentDate).add('1', 'weeks').format(DATE_FORMAT));
    } else if (calendarView === CalendarView.MONTH) {
      setCurrentDate(moment(currentDate).add('1', 'months').format(DATE_FORMAT));
    } else {
      setCurrentDate(moment(currentDate).add(mapCalendarViewToNumOfDays(calendarView), 'days').format(DATE_FORMAT));
    }
  };

  const previous = () => {
    if (calendarView === CalendarView.WEEK) {
      setCurrentDate(moment(currentDate).subtract('1', 'weeks').format(DATE_FORMAT));
    } else if (calendarView === CalendarView.MONTH) {
      setCurrentDate(moment(currentDate).subtract('1', 'months').format(DATE_FORMAT));
    } else {
      setCurrentDate(
        moment(currentDate).subtract(mapCalendarViewToNumOfDays(calendarView), 'days').format(DATE_FORMAT),
      );
    }
  };

  previousNextCallbacks.next = next;
  previousNextCallbacks.previous = previous;

  let arrowLeft = <KeyboardArrowLeft className="arrow" />;
  let arrowRight = <KeyboardArrowRight className="arrow" />;
  if (i18n.dir() === 'rtl') {
    const temp = arrowLeft;
    arrowLeft = arrowRight;
    arrowRight = temp;
  }
  const onDateSelectorChange = (newValue: Moment | null) => {
    if (!newValue) {
      return;
    }
    if (calendarView === CalendarView.WEEK) {
      setCurrentDate(newValue.clone().startOf('week').format(DATE_FORMAT));
    } else if (calendarView === CalendarView.MONTH) {
      setCurrentDate(newValue.startOf('month').format(DATE_FORMAT));
    } else if (calendarView === CalendarView.DAY) {
      setCurrentDate(newValue.format(DATE_FORMAT));
    } else {
      setCurrentDate(newValue.format(DATE_FORMAT));
    }
    setDatesSelectorIsOpen(false);
  };

  return (
    <div>
      <div className="dates-carousel">
        <IconButton onClick={previous}>{arrowLeft}</IconButton>
        <Box
          className="flex-1 text-center"
          sx={{ cursor: 'pointer' }}
          onClick={() => {
            setDatesSelectorIsOpen(true);
          }}>
          {carouselText}
        </Box>
        <IconButton onClick={next}>{arrowRight}</IconButton>
      </div>
      <Dialog open={datesSelectorIsOpen} onClose={() => setDatesSelectorIsOpen(false)} sx={{ width: '100%' }}>
        <DialogContent sx={{ padding: 0, maxHeight: '80vh', overflowY: 'auto' }}>
          <StaticDatePicker
            value={moment(currentDate)}
            onChange={onDateSelectorChange}
            onClose={() => setDatesSelectorIsOpen(false)}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DatesCarousel;
