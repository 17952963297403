import { OrderPagePrice } from '../../OrderPageSettingsPage';

export const isBasePricing = (price: OrderPagePrice): boolean => {
  return !price.custom_per_unit_pricing?.length;
};

export const isPersonsNumberPrice = (price: OrderPagePrice): boolean => {
  return price.type === 'number' && price.data_type === 'persons';
};

export const isCustomUnitPricing = (price: OrderPagePrice): boolean => {
  return !!price.custom_per_unit_pricing?.length;
};
