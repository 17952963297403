import { Grid, Tab, Tabs, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../GeneralUtils';

type TabsPageProps = {
  tabContent: { [key: string]: ReactNode };
};

export const TabsPage = ({ tabContent }: TabsPageProps) => {
  const { t } = useTranslation();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [activeTab, setActiveTab] = useState(Object.keys(tabContent)[0]);
  const topGridRef = useRef<HTMLDivElement>(null);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
    window.location.hash = `#${newValue}`;
    if (topGridRef.current) {
      topGridRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.replace('#', '');
      if (Object.keys(tabContent).includes(hash)) {
        setActiveTab(hash);
        if (topGridRef.current) {
          topGridRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
      }
    };

    window.addEventListener('hashchange', handleHashChange);
    handleHashChange();

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [tabContent]);

  return (
    <Grid container flexDirection={isSmallScreen ? 'column' : 'row'} flexWrap="nowrap" ref={topGridRef}>
      <Grid
        container
        flexDirection={isSmallScreen ? 'column' : 'row'}
        xs={isSmallScreen ? undefined : 1}
        sx={{
          overflowY: 'hidden',
          overflowX: 'auto',
        }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          aria-label="language tabs"
          orientation={isSmallScreen ? 'horizontal' : 'vertical'}
          sx={{
            borderBottom: isSmallScreen ? 2 : undefined,
            borderRight: isSmallScreen ? undefined : 2,
            borderColor: 'divider',
          }}>
          {Object.keys(tabContent).map((tab) => (
            <Tab
              sx={{
                '& .MuiTouchRipple-root': {
                  width: isSmallScreen ? '60px' : '80px',
                },
                width: isSmallScreen ? '60px' : '80px',
                paddingX: 0,
                alignItems: isSmallScreen ? undefined : 'flex-start',
                justifyContent: isSmallScreen ? undefined : 'flex-start',
              }}
              key={`tab-SettingsPageTabs.${tab}`}
              label={
                <Typography variant="subtitle2" fontWeight="700">
                  {t(`tabs.${tab}`)}
                </Typography>
              }
              value={tab}
            />
          ))}
        </Tabs>
      </Grid>
      <Grid
        p={2}
        container
        flexDirection="column"
        xs={isSmallScreen ? undefined : 11}
        sx={{
          overflowY: 'auto',
          maxHeight: '90vh',
        }}>
        {tabContent[activeTab] || null}
      </Grid>
    </Grid>
  );
};
