import uuid from 'react-uuid';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { includes } from 'lodash';
import { getOrderPage, getOrderPages } from '../../common/AccountUtils';
import { OrderPageIntent } from '../OrderPageIntent';
import OrderUtils, { calculatePrice, SpecificEventError, VoucherError } from '../OrderUtils';
import TTLLocalStorage from '../../common/TTLLocalStorage';
import {
  AccountData,
  AccountService,
  EventService,
  SlimEventData,
  VoucherOut,
  VoucherService,
  VoucherStatus,
} from '../../api';
import { useLanguage } from '../../common/GeneralUtils';
import { OrderPageState } from './OrderPageState';
import { setUTMData } from '../orderCompletion/utmLocalStorage';

type initOrderPageStateProps = {
  account: AccountData;
  voucher?: VoucherOut | null;
  voucherError?: VoucherError;
  specificEvent?: SlimEventData | null;
  specificEventError?: SpecificEventError;
};

type useInitOrderPageStateProps = {
  orderPageState: OrderPageState;
  setOrderPageState: (orderPageState: OrderPageState) => void;
};

export const useInitOrderPageState = ({ orderPageState, setOrderPageState }: useInitOrderPageStateProps) => {
  const { accountId, orderPageId } = useParams();
  const language = useLanguage();
  const { i18n } = useTranslation();
  const posthog = usePostHog();
  const queryParams = new URLSearchParams(window.location.search);
  const onlyVouchers = queryParams.get('vouchers') === 'true';
  const onlyCouples = queryParams.get('couples') === 'true';
  const noMinDate = queryParams.get('nomindate') === 'true';
  const troubleshoot = queryParams.get('troubleshoot') === 'true';
  const preSelectedDate = queryParams.get('date') || undefined;
  const pTime = queryParams.get('time');
  const preSelectedTime = pTime ? parseInt(pTime, 10) : undefined;
  const voucherCode = queryParams.get('vouchercode');
  const specificEventId = queryParams.get('event');
  const initOrderPageState = ({
    account,
    voucher,
    voucherError,
    specificEvent,
    specificEventError,
  }: initOrderPageStateProps) => {
    const accountDefaultLanguage = account.default_system_language;
    if (accountDefaultLanguage !== language && !queryParams.get('language')) {
      void i18n.changeLanguage(accountDefaultLanguage);
    }
    const experience = account.experiences[0];

    let orderPage = getOrderPages(account)[0];
    if (orderPageId) {
      orderPage = getOrderPage(account, orderPageId);
    }

    orderPageState.orderPageIntent = OrderPageIntent.BUY_NOW;

    if (voucher) {
      orderPageState.orderPageIntent = OrderPageIntent.REDEEM_GIFT_CARD;
      orderPageState.isLoadingDates = true;
    }
    const tempDetails = {
      ...orderPageState.details,
      persons: voucher?.persons || orderPage.persons,
      duration: voucher?.duration || orderPage.duration,
      custom_details: voucher?.custom_details || OrderUtils.getDefaultCustomDetails(orderPage),
    };

    const getStateInLocalStorage = (orderPageId: string) => {
      const key = `order-page-state-${orderPageId}`;
      return TTLLocalStorage.getItem(key);
    };
    posthog.setPersonPropertiesForFlags({ accountId: account.id });
    posthog.identify(uuid(), {
      accountId: account.id,
      orderPageId: orderPage.id,
      environment: import.meta.env.VITE_REACT_APP_ENVIRONMENT || 'local',
      context: 'order-page',
    });
    if (troubleshoot) {
      posthog.capture('Troubleshoot started', { orderPageId: orderPage.id });
    }
    const specificEventDateAndTimeDetails = specificEvent
      ? {
          dateAndTimeDetails: {
            date: specificEvent?.date,
            time: specificEvent?.time,
          },
        }
      : {};
    const specificPreselectedDateAndTimeDetails = preSelectedDate
      ? {
          dateAndTimeDetails: {
            date: preSelectedDate,
            time: preSelectedTime,
          },
        }
      : {};
    const localStorageState = getStateInLocalStorage(orderPage.id);
    if (localStorageState) {
      setOrderPageState({
        ...orderPageState,
        ...localStorageState,
        specificEvent,
        ...specificEventDateAndTimeDetails,
        ...specificPreselectedDateAndTimeDetails,
        voucher,
        specificOrderPage: {
          onlyCouples,
          onlyVouchers,
          noMinDate,
          troubleshoot,
          preSelectedDate,
        },
      });
    } else {
      setOrderPageState({
        ...orderPageState,
        specificEvent: specificEvent || undefined,
        account,
        experience,
        orderPage,
        ...specificEventDateAndTimeDetails,
        ...specificPreselectedDateAndTimeDetails,
        details: {
          ...tempDetails,
          price: voucher ? voucher.price : calculatePrice(orderPage, tempDetails, orderPageState.dateAndTimeDetails),
        },
        isInitialized: true,
        voucher: voucher || undefined,
        voucherError,
        specificEventError,
        specificOrderPage: {
          onlyCouples,
          onlyVouchers,
          noMinDate,
          troubleshoot,
          preSelectedDate,
        },
      });
    }
  };
  useEffect(() => {
    void (async () => {
      const account = await AccountService.getAccount(accountId);

      let voucher;
      let voucherError;
      let specificEventError;
      let specificEvent = null;
      if (account?.marketing_integrations?.ga4_id) {
        ReactGA.initialize(account?.marketing_integrations?.ga4_id);
      }
      if (voucherCode) {
        posthog.startSessionRecording({});
        posthog.capture('Voucher redemption started', { voucherCode });
        try {
          voucher = await VoucherService.getVoucher(voucherCode);
          if (!includes([VoucherStatus.ACTIVE], voucher.status)) {
            if (voucher.status === VoucherStatus.USED) {
              voucherError = VoucherError.ALREADY_USED;
            }
            if (voucher.status === VoucherStatus.ARCHIVED) {
              voucherError = VoucherError.ARCHIVED;
            }
            voucher = null;
          }
        } catch (e: any) {
          if (e.status === 404) {
            voucherError = VoucherError.NOT_FOUND;
          }
          if (e.status === 400 && e.body.detail === 'voucher is not valid') {
            voucherError = VoucherError.EXPIRED;
          }
        }
      }
      if (specificEventId) {
        specificEvent = await EventService.getSlimEvent(specificEventId);
        if (specificEvent.tickets_left < 1) {
          specificEventError = SpecificEventError.NO_MORE_TICKETS;
        }
      }
      initOrderPageState({ account, voucher, voucherError, specificEvent, specificEventError });
    })();
    setUTMData();
  }, []);
};
