// src/order_page/steps/ErrorBox.tsx
import React from 'react';
import { Alert, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { SpecificEventError, VoucherError } from '../OrderUtils';
import { OrderPageTypography } from '../themedComponents/OrderPageTypography';
import { OrderPageButton } from '../themedComponents/OrderPageButton';
import { getDateFormat } from '../../common/getDateAndTimeFormat';
import { AccountData } from '../../api';

type ErrorBoxProps = {
  error: VoucherError | SpecificEventError;
  orderPageLabel: string;
  waitingListUrl?: string;
  date?: string;
  waitListEnabled?: boolean;
  account: AccountData;
};

const ErrorBox = ({ error, orderPageLabel, waitingListUrl, date, waitListEnabled, account }: ErrorBoxProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const { longDateFormat } = getDateFormat(account);
  let text;
  if (error in VoucherError) {
    switch (error as VoucherError) {
      case VoucherError.EXPIRED:
        text = t('vouchers.expired');
        break;
      case VoucherError.ALREADY_USED:
        text = t('vouchers.already_used');
        break;
      case VoucherError.NOT_FOUND:
        text = t('vouchers.not_found');
        break;
      case VoucherError.ARCHIVED:
        text = t('vouchers.archived');
        break;
      default:
        text = t('vouchers.not_found');
    }
  } else {
    switch (error) {
      case SpecificEventError.NO_MORE_TICKETS:
        text = t('vouchers.no_tickets', { date: moment(date).format(longDateFormat) });
        break;
      default:
        text = t('vouchers.not_found');
    }
  }

  return (
    <Grid
      container
      display="flex"
      justifyContent="space-around"
      sx={{ '::-webkit-scrollbar': { display: 'none' } }}
      style={{
        height: '100%',
        width: '100%',
      }}>
      <Grid
        container
        flexDirection="column"
        mt={isSmallScreen ? 0 : 5}
        style={{
          borderRadius: '12px',
          background: theme.customTheme.palette.background,
          height: '100%',
          width: '100%',
          maxWidth: '710px',
        }}>
        <Grid
          px={isSmallScreen ? 2 : 4}
          py={isSmallScreen ? 2 : 7}
          container
          flexDirection="column"
          alignItems="center"
          alignContent="center"
          style={{
            maxWidth: '710px',
            color: theme.customTheme.palette.text.primary,
            border: `2px solid ${theme.customTheme.palette.border}`,
            borderRadius: '12px',
          }}
          boxShadow={4}
          gap={6}>
          <OrderPageTypography variant="h1" style={{ textAlign: 'center', width: '100%' }}>
            {orderPageLabel}
          </OrderPageTypography>

          <Alert
            severity="error"
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <Typography variant="h5">{text}</Typography>
          </Alert>

          {error === SpecificEventError.NO_MORE_TICKETS && waitingListUrl && date && waitListEnabled && (
            <Grid>
              <Link to={`${waitingListUrl}/${date}`}>
                <OrderPageButton variant="contained">
                  <OrderPageTypography variant="h6" color="button.contained">
                    {t('order_page.waiting_list.link')}
                  </OrderPageTypography>
                </OrderPageButton>
              </Link>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ErrorBox;
