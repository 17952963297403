// src/emailTemplates/templateEditor/SubjectEditor.tsx
import React, { useEffect } from 'react';
import { Grid, TextField, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HelpOutline } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { includes } from 'lodash';
import {
  AccountData,
  EmailCommunicationSettingsData,
  EmailSettingsData,
  EmailTemplateType,
  Language,
} from '../../../api';
import { useLanguage } from '../../../common/GeneralUtils';

export const getInitialSubject = (
  templateType: EmailTemplateType,
  accountEmailsCommunicationSettings: EmailCommunicationSettingsData | undefined,
  selectedLanguage: Language,
): string | undefined => {
  if (!includes([EmailTemplateType.PRE, EmailTemplateType.POST, EmailTemplateType.CANCELLATION], templateType)) {
    return undefined;
  }

  const subject = (accountEmailsCommunicationSettings?.[templateType] as EmailSettingsData)?.subject?.[
    selectedLanguage
  ];
  if (subject) {
    return subject;
  }
  // Backend has sema predifined strings
  if (templateType === EmailTemplateType.PRE) {
    if (selectedLanguage === Language.HE) {
      return 'תזכורת ל{companyName}';
    }
    return 'Reminder for {companyName}';
  }
  if (templateType === EmailTemplateType.POST) {
    if (selectedLanguage === Language.HE) {
      return 'תודה שביקרתם ב{companyName}';
    }
    return 'Thank you for visiting {companyName}';
  }
  if (templateType === EmailTemplateType.CANCELLATION) {
    if (selectedLanguage === Language.HE) {
      return 'ביטול הזמנה מספר {orderNumber} ב{companyName}';
    }
    return 'Cancellation of order number {orderNumber} at {companyName}';
  }
  return subject;
};
const calculateDirection = (selectedLanguage: Language, pageLanguage: Language) => {
  if (selectedLanguage === Language.HE) {
    if (pageLanguage === Language.HE) {
      return 'ltr';
    }
    return 'rtl';
  }
  if (pageLanguage === Language.HE) {
    return 'rtl';
  }
  return 'ltr';
};
type EmailSubjectEditorProps = {
  templateType: EmailTemplateType;
  accountEmailsCommunicationSettings: EmailCommunicationSettingsData | undefined;
  selectedLanguage: Language;
  isEditingTemplatesEnabled: boolean;
  account: AccountData;
  subject: string | undefined;
  setSubject: (subject: string | undefined) => void;
};

export const EmailSubjectEditor = ({
  templateType,
  accountEmailsCommunicationSettings,
  selectedLanguage,
  isEditingTemplatesEnabled,
  account,
  subject,
  setSubject,
}: EmailSubjectEditorProps) => {
  const localisedCompanyName = account.label?.[selectedLanguage] || account.name;
  const localisedActivityName = account.experiences?.[0]?.order_pages[0]?.label?.[selectedLanguage] || '';
  const language = useLanguage();
  const { t } = useTranslation();

  useEffect(() => {
    setSubject(getInitialSubject(templateType, accountEmailsCommunicationSettings, selectedLanguage));
  }, [templateType, selectedLanguage, accountEmailsCommunicationSettings]);

  if (!includes([EmailTemplateType.PRE, EmailTemplateType.POST, EmailTemplateType.CANCELLATION], templateType)) {
    return null;
  }
  return (
    <Grid container maxWidth="800px" gap={1}>
      <Grid xs={11}>
        <TextField
          sx={{ direction: calculateDirection(selectedLanguage, language) }}
          fullWidth
          label={t('emailTemplates.subject')}
          value={subject || ''}
          onChange={(e) => setSubject(e.target.value)}
          disabled={!isEditingTemplatesEnabled}
        />
      </Grid>
      <Tooltip
        title={
          <Grid container flexDirection="column" gap={1}>
            <Grid>
              <Typography>{t('emailTemplates.subjectTooltip.nospam')}</Typography>
            </Grid>
            <Grid>
              <Typography>
                {t('emailTemplates.subjectTooltip.companyNamePlaceHolder', { name: localisedCompanyName })}
              </Typography>
            </Grid>
            <Grid>
              <Typography>
                {t('emailTemplates.subjectTooltip.activityNamePlaceHolder', {
                  name: localisedActivityName,
                })}
              </Typography>
            </Grid>
          </Grid>
        }
        arrow>
        <HelpOutline />
      </Tooltip>
    </Grid>
  );
};
