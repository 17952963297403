import React from 'react';
import { Box } from '@mui/material';
import TimeUtils from '../../../common/TimeUtils';
import { useAuth } from '../../../session/InternalAuthProvider';

const HoursBorders = () => {
  const { authState } = useAuth();
  const { account } = authState;
  const allDayHours = TimeUtils.getAllDayHours(account);
  return (
    <>
      {allDayHours.map((hourMoment, index) => {
        return (
          <Box
            key={index}
            sx={{
              borderBottom: '1px solid #e6e6e6',
              width: '100%',
              height: '50px',
              boxSizing: 'border-box',
            }}
          />
        );
      })}
    </>
  );
};

export default HoursBorders;
