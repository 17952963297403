import * as React from 'react';
import { useState } from 'react';
import {
  Alert,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { DatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Form, Formik } from 'formik';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import { usePostHog } from 'posthog-js/react';
import { useAuthInfo } from '@propelauth/react';
import {
  AccountData,
  HolidayCalendar,
  RepeatingUserAvailabilityCreateDTO,
  UserAvailabilityOut,
  UserAvailabilityService,
  UserAvailabilityType,
  WorkDayType,
  WorkingDaysData,
  WorkingHoursData,
} from '../../../api';
import { UseRepeatingDays } from '../../components/UseRepeatingDays';
import TitledDrawer from '../../../common/ui/TitledDrawer';
import { DeletionMode, UserAvailabilityDeletionConfirmation } from './UserAvailabilityDeletionConfirmation';
import { AvailabilityCircle } from '../../../userSettings/AvailabilityCircle';
import { useAuth } from '../../../session/InternalAuthProvider';
import { getDateFormat } from '../../../common/getDateAndTimeFormat';

const DATE_FORMAT = 'YYYY-MM-DD';
type UnavailabilityDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  userAvailability?: UserAvailabilityOut;
  currentDate: string;
  updateUserAvailability: (itemToUpdate: UserAvailabilityOut) => void;
  addUserAvailability: (itemToAdd: UserAvailabilityOut) => void;
  addUserAvailabilities: (itemsToAdd: UserAvailabilityOut[]) => void;
  deleteUserAvailability: (itemToDelete: UserAvailabilityOut) => void;
  reFetchUserAvailabilities: () => void;
  userWorkingDays?: WorkingDaysData;
  selectedUserId?: string;
  fullUserName?: string;
};

export enum DialogIntent {
  CREATE = 'create',
  UPDATE_SINGLE = 'update_single',
  UPDATE_REPEATING = 'update_recurrent',
}

function getDialogIntent(userAvailability: UserAvailabilityOut | undefined): DialogIntent {
  const isEditingUserAvailability = !!userAvailability;
  if (!isEditingUserAvailability) {
    return DialogIntent.CREATE;
  }
  const editingSingleAvailability = userAvailability && !userAvailability.recurrent_availability_id;
  if (editingSingleAvailability) {
    return DialogIntent.UPDATE_SINGLE;
  }
  return DialogIntent.UPDATE_REPEATING;
}

export const UserAvailabilityDialog = ({
  open,
  setOpen,
  userAvailability,
  currentDate,
  addUserAvailability,
  addUserAvailabilities,
  updateUserAvailability,
  reFetchUserAvailabilities,
  userWorkingDays,
  deleteUserAvailability,
  selectedUserId,
  fullUserName,
}: UnavailabilityDialogProps) => {
  const posthog = usePostHog();
  const { user: loggedInUser } = useAuthInfo();
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const { longDateFormat } = getDateFormat(account);
  const theme = useTheme();
  const dialogIntent = getDialogIntent(userAvailability);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isSaving, setIsSaving] = useState(false);
  const [startDateOpen, setStartDateOpen] = useState(false);
  const [isStartTimeOpen, setIsStartTimeOpen] = useState(false);
  const [isEndTimeOpen, setIsEndTimeOpen] = useState(false);
  const [isDeleteConfirmationDialogOpen, setIsDeleteConfirmationDialogOpen] = useState(false);
  const { t } = useTranslation();
  const shouldShowEditingOtherUserAlert = loggedInUser?.userId !== selectedUserId;
  const workingDayHours = userWorkingDays
    ? (userWorkingDays as Record<string, WorkingHoursData>)[
        moment(currentDate).locale('en').format('dddd').toLowerCase()
      ]
    : undefined;
  const resolveInitialType = () => {
    if (userAvailability?.type) {
      return userAvailability.type;
    }

    if (workingDayHours?.work_day_type !== WorkDayType.NOT_WORKING) {
      return UserAvailabilityType.BLOCKED;
    }
    return UserAvailabilityType.AVAILABLE;
  };
  const initialValues: RepeatingUserAvailabilityCreateDTO & {
    recurrent: boolean;
  } = {
    start_time: userAvailability?.start_time || 28800,
    end_time: userAvailability?.end_time || 57600,
    description: userAvailability?.description || '',
    all_day: userAvailability?.all_day || false,
    date: userAvailability?.date || currentDate,
    user_id: userAvailability?.user_id || selectedUserId || '',
    days: userAvailability?.days || [0, 1, 2, 3, 4, 5, 6],
    end_date: userAvailability?.end_date || currentDate,
    recurrent: !!userAvailability?.recurrent_availability_id,
    type: resolveInitialType(),
  };
  const { RepeatingDaysComponent, setEndDate } = UseRepeatingDays({
    minDate: moment(),
    initialDays: initialValues.days,
    initialEndDate: initialValues.end_date,
  });

  const handleClose = (e?: any, reason?: any) => {
    if (reason !== 'backdropClick') {
      setOpen(false);
    }
  };

  const validationSchema = yup.object().shape({
    all_day: yup.boolean().required('This field is required'),
    start_time: yup.number().when('all_day', {
      is: false,
      then: yup.number().required('Start time is required when all day is not checked').min(0).max(86400),
      otherwise: yup.number().nullable(),
    }),
    end_time: yup.number().when('all_day', {
      is: false,
      then: yup.number().required('End time is required when all day is not checked').min(0).max(86400),
      otherwise: yup.number().nullable(),
    }),
    description: yup.string().optional().max(255, 'Description can be at most 255 characters long'),
    date: yup
      .string()
      .required('Date is required')
      .matches(/^\d{4}-\d{2}-\d{2}$/, 'Date must be in YYYY-MM-DD format'),
    user_id: yup
      .string()
      .required('Employee ID is required')
      .max(100, 'Employee ID can be at most 100 characters long'),
    recurrent: yup.boolean(),
    type: yup
      .mixed<UserAvailabilityType>()
      .oneOf(Object.values(UserAvailabilityType))
      .required('Availability type is required'),
  });

  const onSubmit = async (
    values: RepeatingUserAvailabilityCreateDTO & {
      recurrent: boolean;
    },
  ) => {
    posthog.capture('UserAvailability Saved', values);

    setIsSaving(true);
    switch (dialogIntent) {
      case DialogIntent.CREATE: {
        if (values.recurrent) {
          const items = await UserAvailabilityService.createUserAvailabilityRepeating(values);
          addUserAvailabilities(items);
        } else {
          const item = await UserAvailabilityService.createUserAvailability(values);
          addUserAvailability(item);
        }
        break;
      }
      case DialogIntent.UPDATE_SINGLE: {
        const item = await UserAvailabilityService.editUserAvailability({
          id: userAvailability?.id || '',
          ...values,
        });
        updateUserAvailability(item);
        break;
      }
      case DialogIntent.UPDATE_REPEATING: {
        await UserAvailabilityService.editRepeatingUserAvailability({
          recurrent_availability_id: userAvailability?.recurrent_availability_id || '',
          from_date: values.date,
          ...values,
        });
        reFetchUserAvailabilities();
        break;
      }
      default:
        throw Error('unsupported availability intent');
    }
    setIsSaving(false);
    handleClose();
  };
  const onDeleteConfirmed = async (deletionOption: DeletionMode) => {
    switch (deletionOption) {
      case DeletionMode.SINGLE: {
        await UserAvailabilityService.deleteUserAvailability(userAvailability?.id || '');
        // @ts-ignore
        deleteUserAvailability(userAvailability);
        break;
      }
      case DeletionMode.FROM_NOW_ON: {
        await UserAvailabilityService.deleteRepeatingUserAvailability(
          userAvailability?.recurrent_availability_id || '',
          userAvailability?.date || '',
        );
        reFetchUserAvailabilities();
        break;
      }
      default:
        throw Error('unsupported availability intent');
    }

    setIsDeleteConfirmationDialogOpen(false);
    handleClose();
  };

  const getDialogTitle = () => {
    switch (dialogIntent) {
      case DialogIntent.CREATE:
        return t('userAvailability.addTitle');
      case DialogIntent.UPDATE_SINGLE:
        return t('userAvailability.updateSingle');
      case DialogIntent.UPDATE_REPEATING:
        return t('userAvailability.updateRepeating');
      default:
        return t('userAvailability.addTitle');
    }
  };

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values, handleSubmit, handleChange, setFieldValue, submitForm }) => (
        <Form onSubmit={handleSubmit}>
          <TitledDrawer onClose={handleClose} open={open} title={getDialogTitle()}>
            <Grid
              container
              flexDirection="column"
              width={isSmallScreen ? window.innerWidth : undefined}
              p={isSmallScreen ? '8px' : '16px'}
              pb="0px"
              height="100%">
              <Grid
                p="8px"
                container
                flexDirection="column"
                style={{ overflowY: 'scroll', maxHeight: 'calc(100% - 70px)' }}
                flexWrap="nowrap"
                height="100%"
                gap={2}>
                {shouldShowEditingOtherUserAlert && (
                  <Grid my={1}>
                    <Alert severity="warning">{t('userAvailability.editotherUser', { user: fullUserName })}</Alert>
                  </Grid>
                )}
                <Grid>
                  <DatePicker
                    open={startDateOpen}
                    onOpen={() => setStartDateOpen(true)}
                    onClose={() => setStartDateOpen(false)}
                    label={t('calendar.unavailability_dialog.date')}
                    format={longDateFormat}
                    name="date"
                    value={moment(values.date)}
                    onChange={(newValue) => {
                      void setFieldValue('date', newValue?.format(DATE_FORMAT));
                      if (moment(newValue)?.isSameOrAfter(values.end_date) && newValue) {
                        setEndDate(newValue);
                        void setFieldValue('end_date', newValue?.format(DATE_FORMAT));
                      }
                    }}
                    disablePast
                  />
                </Grid>
                <Grid item>
                  <FormControl component="fieldset">
                    <RadioGroup row id="type" name="type" value={values.type} onChange={handleChange}>
                      <FormControlLabel
                        value={UserAvailabilityType.AVAILABLE}
                        control={<Radio />}
                        label={
                          <Grid container alignItems="center" gap={0.5}>
                            <AvailabilityCircle type={UserAvailabilityType.AVAILABLE} />
                            {t('userAvailability.avliable')}
                          </Grid>
                        }
                      />
                      <FormControlLabel
                        value={UserAvailabilityType.BLOCKED}
                        control={<Radio />}
                        label={
                          <Grid container alignItems="center" gap={0.5}>
                            <AvailabilityCircle type={UserAvailabilityType.BLOCKED} />
                            {t('userAvailability.blocked')}
                          </Grid>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  borderRadius="8px"
                  border="1px solid #c4c4c4"
                  p={1}
                  pb={values.all_day ? 1 : 2}
                  maxWidth={values.all_day ? '231px' : undefined}>
                  <Grid item>
                    <FormControlLabel
                      value="start"
                      control={
                        <Switch name="all_day" checked={values.all_day} onChange={handleChange} color="primary" />
                      }
                      label={t('calendar.unavailability_dialog.all_day')}
                    />
                  </Grid>

                  {!values.all_day && (
                    <Grid container gap={2}>
                      <MobileTimePicker
                        name="start_time"
                        minTime={moment().set({ hour: 8, minute: 0, second: 0, millisecond: 0 })}
                        ampm={account.holiday_calendar === HolidayCalendar.US}
                        open={isStartTimeOpen}
                        onOpen={() => setIsStartTimeOpen(true)}
                        onClose={() => setIsStartTimeOpen(false)}
                        label={t('calendar.unavailability_dialog.start_time')}
                        value={moment.unix(values.start_time || 0).tz('utc')}
                        onChange={(newValue) => setFieldValue('start_time', newValue?.unix())}
                      />

                      <MobileTimePicker
                        name="end_time"
                        minTime={moment.unix(values.start_time || 0)}
                        ampm={account.holiday_calendar === HolidayCalendar.US}
                        open={isEndTimeOpen}
                        onOpen={() => setIsEndTimeOpen(true)}
                        onClose={() => setIsEndTimeOpen(false)}
                        label={t('calendar.unavailability_dialog.end_time')}
                        value={moment.unix(values.end_time || 0).tz('utc')}
                        onChange={(newValue) => setFieldValue('end_time', newValue?.unix())}
                      />
                    </Grid>
                  )}
                </Grid>
                {dialogIntent !== DialogIntent.UPDATE_SINGLE && (
                  <Grid
                    item
                    borderRadius="8px"
                    border="1px solid #c4c4c4"
                    p={1}
                    maxWidth={!values.recurrent ? '400px' : undefined}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Switch />}
                        checked={values.recurrent}
                        label={t('userAvailability.repeating')}
                        onChange={(e: any) => {
                          void setFieldValue('recurrent', e.target.checked);
                          if (!e.target.checked) {
                            setEndDate(moment(values.date).clone().startOf('day'));
                          }
                        }}
                      />
                    </FormGroup>

                    {values.recurrent && (
                      <Grid>
                        <RepeatingDaysComponent
                          setFormikDays={(newDays) => setFieldValue('days', newDays)}
                          setFormikEndDate={(newEndDate) => setFieldValue('end_date', newEndDate)}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
                <Grid item>
                  <TextField
                    name={'description'}
                    variant="outlined"
                    sx={{ width: '231px' }}
                    label={t('calendar.unavailability_dialog.description')}
                    value={values.description}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>

              <Grid
                p={2}
                container
                justifyContent={dialogIntent === DialogIntent.CREATE ? 'flex-end' : 'space-between'}
                gap={2}>
                {dialogIntent !== DialogIntent.CREATE && (
                  <Button
                    color="error"
                    variant="contained"
                    sx={{ textTransform: 'none' }}
                    onClick={() => setIsDeleteConfirmationDialogOpen(true)}>
                    {t('delete')}
                  </Button>
                )}
                <LoadingButton
                  loading={isSaving}
                  variant="contained"
                  sx={{ textTransform: 'none' }}
                  onClick={submitForm}>
                  {t('save')}
                </LoadingButton>
              </Grid>
              <UserAvailabilityDeletionConfirmation
                dialogIntent={dialogIntent}
                onDeleteCallback={onDeleteConfirmed}
                isOpen={isDeleteConfirmationDialogOpen}
                setIsOpen={setIsDeleteConfirmationDialogOpen}
                dateToDeleteFrom={initialValues.date}
              />
            </Grid>
          </TitledDrawer>
        </Form>
      )}
    </Formik>
  );
};
