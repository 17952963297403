import './ToggleButtonGroupUiObject.scss';
import React, { useEffect, useState } from 'react';
import { ToggleButtonGroup } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useLanguage } from '../../common/GeneralUtils';
import { OrderPageToggleButton } from '../themedComponents/OrderPageToggleButton';
import { OrderPageState } from '../orderPageContext/OrderPageState';
import { OrderPageTypography } from '../themedComponents/OrderPageTypography';

type ToggleButtonGroupUiObjectProps = {
  uiObject: any;
  orderState: OrderPageState;
  setOrderDetailValue: (name: string, value: string) => void;
  setCustomOrderDetailValue: (name: string, value: string) => void;
  setOrderCustomDetails: (details: any) => void;
};
const ToggleButtonGroupUiObject = ({
  uiObject,
  orderState,
  setOrderDetailValue,
  setCustomOrderDetailValue,
  setOrderCustomDetails,
}: ToggleButtonGroupUiObjectProps) => {
  const [searchParams] = useSearchParams();
  const selectedOption = searchParams.get('selectedoption') || undefined;

  const language = useLanguage();
  const objectName = uiObject.name;
  const uiObjectValueFromState = orderState.details.custom_details[objectName];
  const defaultValue =
    uiObject.data_type === 'custom'
      ? uiObjectValueFromState || uiObject.options[0].value
      : `${orderState.details[uiObject.data_type as keyof typeof orderState.details]}`;
  // ^ todo remove when there are more types

  useEffect(() => {
    if (uiObject.data_type === 'custom') {
      // there is some race ocndition in state setting
      // todo it better. mb initiation has to be already initiated with all relevant stuff
      setTimeout(() => setCustomOrderDetailValue(uiObject.name, defaultValue), 500);
    }
  }, []);

  const [value, setValue] = useState(defaultValue);

  const onValueChange = (e: any, val: any) => {
    if (val != null) {
      // for deselection
      setValue(val);
      if (uiObject.data_type === 'custom') {
        const data = {
          [uiObject.name]: val,
        };

        setOrderCustomDetails(data);
      } else {
        setOrderDetailValue(uiObject.data_type, val);
      }
    }
  };
  // hack because some race wit state setting erases it condition
  useEffect(() => {
    if (selectedOption) {
      setTimeout(() => {
        onValueChange(null, selectedOption);
      }, 500);
    }
  }, [selectedOption]);
  // we done want to see selection in ui when we have preselected
  if (selectedOption) {
    return null;
  }
  return (
    <div className="toggle-button-ui-object">
      <OrderPageTypography variant="h3" bold>
        {uiObject.label[language]}
      </OrderPageTypography>

      <div className="toggle-button-container">
        <ToggleButtonGroup value={value} exclusive onChange={onValueChange}>
          {uiObject.options.map((option: any) => {
            return (
              <OrderPageToggleButton key={option.value} className="toggle-button" value={`${option.value}`}>
                {option.label[language]}
              </OrderPageToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </div>
    </div>
  );
};

export default ToggleButtonGroupUiObject;
