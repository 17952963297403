import { Theme } from '@mui/material/styles/createTheme';
// had to hack this as couldnt generalise component. feels like mui bug. Make a generic step component make icon disapear
export const stepStyle = (theme: Theme) => {
  return {
    '& .MuiStepLabel-root .Mui-completed': {
      color: theme.customTheme.palette.primary,
      '& .MuiSvgIcon-root': {
        backgroundColor: theme.customTheme.palette.text.primary,
        borderRadius: '50%',
      },
    },

    '& .MuiStepLabel-root .Mui-active': {
      color: theme.customTheme.palette.primary,
    },
    '& .MuiStepIcon-text': {
      fill: theme.customTheme.palette.text.primary,
    },

    '& .MuiStepLabel-root .MuiStepIcon-text': {
      fill: theme.customTheme.palette.primary,
    },
    '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
      fill: theme.customTheme.palette.text.primary,
      fontWeight: 700,
    },
  };
};
