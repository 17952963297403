import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { useAuthInfo } from '@propelauth/react';
import { Alert, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { UserAvailabilityCalendar } from './userAvailabilityCalendar/UserAvailabilityCalendar';
import { TabsPage } from '../common/components/TabsPage';
import { PermanentUserAvailability } from './permenantUserAvailability/PermanentUserAvailability';
import { AccountData, PropelUserModel, UserPreferencesService, UserService } from '../api';
import { useAuth } from '../session/InternalAuthProvider';
import RotatingLoader from '../common/ui/RotatingLoader';
import { getUserFullName, getUserFullNameFromUserId } from '../calendar/components/UsersMultiSelect';

enum UserAvailabilityPageTabs {
  CALENDAR_AVAILABILITY = 'calendaravailability',
  PERMANENT_AVAILABILITY = 'permanentavailability',
}

export const UserAvailabilityPage = () => {
  const { t } = useTranslation();

  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const { user: loggedInUser, accessHelper } = useAuthInfo();

  const [users, setUsers] = useState<PropelUserModel[]>([]);
  const shouldAllowSelectUser = accessHelper?.isAtLeastRole(account.propel_account_id, 'Admin');

  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(loggedInUser?.userId);
  const isViewingOtherUser = loggedInUser?.userId !== selectedUserId;

  const selectedPropelUser = users.find((user) => user.user_id === selectedUserId);
  const fullUserName = `${selectedPropelUser?.first_name} ${selectedPropelUser?.last_name}`;
  useEffect(() => {
    const fetchUsers = async () => {
      const users = await UserService.getUsers();
      if (setUsers) {
        setUsers(users);
      }
    };
    void fetchUsers();
  }, []);
  const {
    data: userPreferences,
    isLoading: isLoadingUserPreferences,
    mutate: refetchUserPrefs,
  } = useSWR({ opId: 'getUserPreferences' }, () => UserPreferencesService.getUserPreferences(selectedUserId), {
    refreshInterval: 20000,
  });
  const tabContent = {
    [UserAvailabilityPageTabs.CALENDAR_AVAILABILITY]: (
      <UserAvailabilityCalendar
        userPreferences={userPreferences}
        isLoadingUserPreferences={isLoadingUserPreferences}
        refetchUserPrefs={refetchUserPrefs}
        selectedUserId={selectedUserId}
        fullUserName={fullUserName}
      />
    ),
    [UserAvailabilityPageTabs.PERMANENT_AVAILABILITY]: (
      <PermanentUserAvailability
        userPreferences={userPreferences}
        isLoadingUserPreferences={isLoadingUserPreferences}
        refetchUserPrefs={refetchUserPrefs}
        selectedUserId={selectedUserId}
      />
    ),
  };
  return (
    <Grid container flexDirection="column" gap={2}>
      <Grid container gap={2} alignItems="center">
        {shouldAllowSelectUser ? (
          isEmpty(users) ? (
            <Grid>
              <RotatingLoader width={'24px'} />
            </Grid>
          ) : (
            <Grid py={1}>
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel id="demo-simple-select-label">{t('calendar.shift_dialog.employee')}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  inputProps={{ minWidth: '200px' }}
                  size="small"
                  value={selectedUserId}
                  label={t('calendar.shift_dialog.employee')}
                  onChange={(e) => setSelectedUserId(e.target.value)}
                  renderValue={(userId: string) => getUserFullNameFromUserId(users, userId)}>
                  {users
                    ?.map((user: PropelUserModel) => ({ ...user, fullName: getUserFullName(user) }))
                    .sort((a: any, b: any) => a.fullName.localeCompare(b.fullName))
                    .map((user: any) => (
                      <MenuItem key={`MenuItem-${user.user_id}`} value={user.user_id}>
                        {user.fullName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          )
        ) : null}
        {isViewingOtherUser && (
          <Grid my={1} maxWidth={'500px'}>
            <Alert severity="warning">{t('userAvailability.otherUser', { user: fullUserName })}</Alert>
          </Grid>
        )}
      </Grid>
      <TabsPage tabContent={tabContent} />
    </Grid>
  );
};
