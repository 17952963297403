import { Box } from '@mui/material';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import uuid from 'react-uuid';
import Typography from '@mui/material/Typography';
import { Variant } from '@mui/material/styles/createTypography';

const TextWithTooltip = (props: { text: string; tooltip: string; width: string; variant?: Variant | 'inherit' }) => {
  const { text, tooltip, width, variant } = props;
  const key = uuid();
  return (
    <>
      <Tooltip
        place="bottom"
        content={tooltip}
        id={`tooltip-${key}`}
        variant="light"
        border="1px solid black"
        style={{ maxWidth: '250px', whiteSpace: 'normal' }}
      />
      <Box
        data-tooltip-id={`tooltip-${key}`}
        sx={{
          maxWidth: width,
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}>
        <Typography variant={variant || undefined}>{text}</Typography>
      </Box>
    </>
  );
};

export default TextWithTooltip;
