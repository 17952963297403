import { Grid, Typography } from '@mui/material';
import React from 'react';
import { AccountData, Currency, LocationsStatus } from '../api';
import { useAuth } from '../session/InternalAuthProvider';
import { UseAuthPermissions } from '../session/UseAuthPermissions';

function priceFormatterFromCurrency(
  price: number,
  currency: Currency,
  taxRate?: number,
  withTax = false,
  shouldNotSpecifyTax = false,
): JSX.Element {
  const locale = currency.toLowerCase() === 'ils' ? 'il-IL' : 'en-US';
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits: 0,
  });
  const formattedPrice = formatter.format(price);
  const shouldRenderTax = taxRate && withTax;
  if (!shouldRenderTax) {
    return (
      <Grid>
        <Typography>{formattedPrice}</Typography>
      </Grid>
    );
  }
  return (
    <Grid container gap={1} alignItems="center">
      <Typography>{formattedPrice}</Typography>
      <Typography fontSize={'12px'} fontWeight={300}>
        {shouldNotSpecifyTax ? `+ sales tax` : ` + ${taxRate}% sales tax`}
      </Typography>
    </Grid>
  );
}

export function usePriceFormatter(): (price: number, withTax?: boolean) => JSX.Element | undefined {
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const hasStripeIntegration = !!account.stripe_connect;
  const hasMultipleLocations = account.locations.filter((x) => x.status === LocationsStatus.ACTIVE).length > 1;
  const shouldNotSpecifyTax = hasStripeIntegration && hasMultipleLocations;
  const { readOnly } = UseAuthPermissions();
  if (!account || readOnly) {
    return () => undefined;
  }

  return (price: number, withTax = false) =>
    priceFormatterFromCurrency(
      price,
      account.currency,
      account.locations?.[0]?.stripe_tax_rate,
      withTax,
      shouldNotSpecifyTax,
    );
}
