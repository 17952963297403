import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { Today } from '@mui/icons-material';
import moment from 'moment/moment';
import { isEmpty } from 'lodash';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { MonthViewSkeleton } from '../../calendar/views/MonthViewSkeleton';
import DatesCarousel from '../../calendar/DatesCarousel';
import { CalendarView, DATE_FORMAT } from '../../calendar/CalendarWrapper';
import { EmployeeAvailabilityCell } from './EmployeeAvailabilityCell';
import { useUserAvailability } from './swr/useUserAvailability';
import { UserAvailabilityDialog } from '../../calendar/dialogs/UserAvailabilityDialog/UserAvailabilityDialog';
import { UserAvailabilityType, UserPreferencesOut } from '../../api';
import RotatingLoader from '../../common/ui/RotatingLoader';
import { AvailabilityCircle } from '../AvailabilityCircle';

type PermanentUserAvailabilityProps = {
  userPreferences?: UserPreferencesOut;
  isLoadingUserPreferences: boolean;
  refetchUserPrefs: () => Promise<any>;
  selectedUserId?: string;
  fullUserName?: string;
};
export const UserAvailabilityCalendar = ({
  userPreferences,
  isLoadingUserPreferences,
  refetchUserPrefs,
  selectedUserId,
  fullUserName,
}: PermanentUserAvailabilityProps) => {
  const today = moment().startOf('month').format(DATE_FORMAT);
  const { t } = useTranslation();
  const [currentViewDate, setCurrentViewDate] = useState(today);
  const [selectedCellDate, setSelectedCellDate] = useState(moment().format(DATE_FORMAT));
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isRefetching, setIsRefetching] = useState(false);

  const previousNextCallbacks = {} as {
    previous: any;
    next: any;
  };
  const {
    isLoading: isLoadingUserAvailabilities,
    items: userAvailabilities,
    updateItem: updateUserAvailability,
    addItem: addUserAvailability,
    deleteItem: deleteUserAvailability,
    addItems: addUserAvailabilities,
    reFetch: reFetchUserAvailabilities,
  } = useUserAvailability({
    startDate: moment(currentViewDate).startOf('month').subtract(2, 'month').format(DATE_FORMAT),
    endDate: moment(currentViewDate).startOf('month').add(6, 'month').format(DATE_FORMAT),
    userId: selectedUserId,
  });

  useEffect(() => {
    const refetchDataWithNewUser = async () => {
      setIsRefetching(true);
      await reFetchUserAvailabilities();
      await refetchUserPrefs();
      setIsRefetching(false);
    };

    void refetchDataWithNewUser();
  }, [selectedUserId]);

  const isLoading = isLoadingUserAvailabilities || isLoadingUserPreferences || isRefetching;
  const userWorkingDays = userPreferences?.working_days;
  const onCellClicked = (date: string) => {
    if (moment(date).isBefore(moment())) {
      return;
    }
    setSelectedCellDate(date);
    setEditDialogOpen(true);
  };
  const selectedDaysAvailability = userAvailabilities[selectedCellDate];
  // eslint-disable-next-line no-return-assign
  const onPermanentAvailabilityClick = () => {
    window.location.hash = 'permanentavailability';
  };

  return (
    <Grid container flexDirection="column">
      <Typography variant="h5" fontWeight={700}>
        {t('userAvailability.calendarAvailability')}
      </Typography>
      <Grid container alignItems="center" mt={isSmallScreen ? 2 : 1}>
        <DatesCarousel
          currentDate={currentViewDate}
          setCurrentDate={setCurrentViewDate}
          calendarView={CalendarView.MONTH}
          previousNextCallbacks={previousNextCallbacks}
        />
        <IconButton
          onClick={() => setCurrentViewDate(today)}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}>
          <Today />
        </IconButton>

        <Grid container alignItems="center" gap={1} mt={1} mx={isSmallScreen ? 1 : undefined}>
          <AvailabilityCircle type={'working_days'} />
          <Typography
            component={'span'}
            onClick={onPermanentAvailabilityClick}
            sx={{
              color: 'inherit',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}>
            {t('userAvailability.standardSchedule')}
          </Typography>
          |
          <AvailabilityCircle type={UserAvailabilityType.AVAILABLE} />
          <Typography>{t('userAvailability.avliable')}</Typography>
          |
          <AvailabilityCircle type={UserAvailabilityType.BLOCKED} />
          <Typography>{t('userAvailability.blocked')}</Typography>
        </Grid>
      </Grid>
      {isLoading ? (
        <Grid container minHeight={300}>
          <RotatingLoader />
        </Grid>
      ) : (
        <MonthViewSkeleton
          currentDate={currentViewDate}
          DayComponent={(props) => (
            <EmployeeAvailabilityCell
              userWorkingDays={userWorkingDays}
              userAvailabilities={userAvailabilities}
              dateToRender={moment(props.date).format('YYYY-MM-DD')}
              onCellClicked={onCellClicked}
            />
          )}
        />
      )}

      {editDialogOpen && (
        <UserAvailabilityDialog
          userWorkingDays={userWorkingDays}
          open={editDialogOpen}
          setOpen={setEditDialogOpen}
          currentDate={selectedCellDate}
          userAvailability={isEmpty(selectedDaysAvailability) ? undefined : selectedDaysAvailability[0]}
          updateUserAvailability={updateUserAvailability}
          addUserAvailability={addUserAvailability}
          addUserAvailabilities={addUserAvailabilities}
          reFetchUserAvailabilities={reFetchUserAvailabilities}
          deleteUserAvailability={deleteUserAvailability}
          selectedUserId={selectedUserId}
          fullUserName={fullUserName}
        />
      )}
    </Grid>
  );
};
