// src/accountSettings/OrderCompletionPageSettings.tsx
import React from 'react';
import { Form, Formik } from 'formik';
import { Divider, Grid, useMediaQuery, useTheme } from '@mui/material';

import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { KeyedMutator } from 'swr/_internal';
import Typography from '@mui/material/Typography';
import { OpenInNew } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { AccountData, AccountService, OrderCompletedPageSettingsData } from '../api';
import { FormTextField } from '../experienceSettings/formComponents/FormTextField';
import { getOrderPages } from '../common/AccountUtils';
import { useLanguage } from '../common/GeneralUtils';

type OrderCompletionPageSettingsProps = {
  account: AccountData;
  updateLocalAccount: KeyedMutator<AccountData>;
  setIsUpdatedSnackOpen: (value: boolean) => void;
};

export const OrderCompletionPageSettings = ({
  account,
  updateLocalAccount,
  setIsUpdatedSnackOpen,
}: OrderCompletionPageSettingsProps) => {
  const language = useLanguage();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const initialValues: OrderCompletedPageSettingsData = {
    title: account?.order_completed_page_settings?.title || {},
    subtitle: account?.order_completed_page_settings?.subtitle || {},
  };

  const validationSchema = yup.object().shape({
    title: yup.object().shape({
      en: yup.string(),
      he: yup.string(),
    }),
    subtitle: yup.object().shape({
      en: yup.string(),
      he: yup.string(),
    }),
  });

  const onSubmit = async (
    values: OrderCompletedPageSettingsData,
    {
      setSubmitting,
    }: {
      setSubmitting: (isSubmitting: boolean) => void;
    },
  ) => {
    try {
      setSubmitting(true);
      const updatedSettings = await AccountService.updateOrderCompletedPageSettings(values);
      await updateLocalAccount({ ...account, order_completed_page_settings: updatedSettings });
      setIsUpdatedSnackOpen(true);
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false);
    }
  };
  const firstOrderPageId = getOrderPages(account)[0]?.id;
  const linkUrl = firstOrderPageId
    ? `${window.location.host}/order/completed?account_id=${account.id}&order_page_id=${firstOrderPageId}&language=${language}`
    : undefined;
  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ isSubmitting, dirty }) => (
        <Form>
          <Grid container p={0} flexWrap="nowrap" alignItems="flex-start" width="100%">
            <Grid container flexDirection="column" gap={2} xs={9}>
              <Grid container justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="h4">{t('accountSettings.orderCompletionPage')}</Typography>
                {linkUrl && (
                  <a
                    href={`https://${linkUrl}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Grid
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 0.5,
                      }}>
                      <Typography>{t('accountSettings.preview')}</Typography>
                      <OpenInNew fontSize="small" />
                    </Grid>
                  </a>
                )}
              </Grid>

              <Grid item mb={2}>
                <Typography variant="body1" color="grey">
                  {t('accountSettings.orderCompletionPageDescription')}
                </Typography>
              </Grid>
              {account.customer_facing_widgets_languages.map((lang, languageIndex) => (
                <Grid container gap={1} mt={2} key={lang} flexDirection="column">
                  <Typography fontWeight={700}>{t(`emailTemplates.${lang}`)}</Typography>
                  <Grid item>
                    <FormTextField
                      isFieldUpdate
                      width={300}
                      fieldName={`title.${lang}`}
                      type="text"
                      label={t('accountSettings.orderCompletionTitle')}
                      disabled={false}
                    />
                  </Grid>
                  <Grid item>
                    <FormTextField
                      isFieldUpdate
                      width={300}
                      fieldName={`subtitle.${lang}`}
                      type="text"
                      label={t('accountSettings.orderCompletionSubtitle')}
                      minRows={3}
                      disabled={false}
                    />
                  </Grid>
                  <Grid pt={1}>
                    {account.customer_facing_widgets_languages.length > 1 &&
                      languageIndex < account.customer_facing_widgets_languages.length - 1 && (
                        <Divider
                          style={{
                            marginRight: isSmallScreen ? undefined : 80,
                            marginLeft: isSmallScreen ? undefined : 80,
                          }}
                        />
                      )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid container justifyContent="flex-end" mt={2} xs={2}>
              <LoadingButton
                loading={isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || !dirty}>
                {t('save')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
