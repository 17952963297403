import { FormControl, Grid, InputLabel, MenuItem, Select, TextField, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAccountAuthenticated } from '../../../session/swr/useGetAccountAuthenticated';
import { LocationsStatus } from '../../../api';
import { useLanguage } from '../../../common/GeneralUtils';
import { ManageLocations } from './ManageLocations';

type LocationPickerProps = {
  onSelected: (locationId: string) => void;
  withoutLabel?: boolean;
  initialLocationId?: string;
  withoutManageLink?: boolean;
};

export const LocationPicker = ({
  onSelected,
  withoutLabel,
  initialLocationId,
  withoutManageLink,
}: LocationPickerProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();
  const { account } = useGetAccountAuthenticated({});
  const activeLocations = account.locations.filter((x) => x.status === LocationsStatus.ACTIVE);
  const defaultLocation = activeLocations.find((x) => x.default);
  const [selectedLocation, setSelectedLocation] = useState<string | undefined>(
    initialLocationId || defaultLocation?.id,
  );
  const language = useLanguage();

  useEffect(() => {
    if (selectedLocation) {
      onSelected(selectedLocation);
    }
  }, [selectedLocation]);
  useEffect(() => {
    if (initialLocationId) {
      setSelectedLocation(initialLocationId);
    }
  }, [initialLocationId]);
  if (activeLocations.length === 0) {
    return (
      <Grid container gap={1}>
        <TextField value={t('locationPicker.noLocationsMessage')} disabled />
        <ManageLocations />
      </Grid>
    );
  }

  if (activeLocations.length === 1) {
    return (
      <Grid container gap={1}>
        <TextField
          label={withoutLabel ? undefined : t('locationPicker.location')}
          value={activeLocations[0].address?.[language]}
          disabled
        />
        {!withoutManageLink && <ManageLocations />}
      </Grid>
    );
  }

  return (
    <FormControl fullWidth>
      <Grid container gap={1}>
        {!withoutLabel && <InputLabel>{t('locationPicker.location')}</InputLabel>}
        <Select
          sx={{ width: isSmallScreen ? '300px' : undefined }}
          label={withoutLabel ? t('locationPicker.location') : ' '}
          value={selectedLocation}
          onChange={(e) => setSelectedLocation(e.target.value)}>
          {activeLocations.map((location) => (
            <MenuItem key={location.id} value={location.id}>
              {location.address?.[language]}
            </MenuItem>
          ))}
        </Select>
        {!withoutManageLink && <ManageLocations />}
      </Grid>
    </FormControl>
  );
};
