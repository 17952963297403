// src/experienceSettings/sections/pricingSection/CustomUnitPricingFields.tsx
import React from 'react';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Field, FieldProps } from 'formik';
import { Add, Clear } from '@mui/icons-material';
import { FormTextField } from '../../formComponents/FormTextField';
import { OrderPagePrice } from '../../OrderPageSettingsPage';

type CustomUnitPricingFieldsProps = {
  price: OrderPagePrice;
  index: number;
  formIsDisabled: boolean;
  currencySymbol: string;
};

export const CustomUnitPricingFields: React.FC<CustomUnitPricingFieldsProps> = ({
  price,
  index,
  formIsDisabled,
  currencySymbol,
}) => {
  const { t } = useTranslation();

  const handleAddPrice = (form: any) => {
    const newPricing = price.custom_per_unit_pricing ? [...price.custom_per_unit_pricing, 0] : [0];
    void form.setFieldValue(`price[${index}].custom_per_unit_pricing`, newPricing);
  };

  const handleRemovePrice = (form: any, priceIndex: number) => {
    const newPricing = price.custom_per_unit_pricing?.filter((_, i) => i !== priceIndex);
    void form.setFieldValue(`price[${index}].custom_per_unit_pricing`, newPricing);
  };

  return (
    <Grid container flexDirection="column" gap={1}>
      <Typography fontWeight="bold">{t('editOrderPage.customPricing')}</Typography>
      <Field name={`price[${index}].custom_per_unit_pricing`}>
        {({ form }: FieldProps) => (
          <Grid container gap={2} flexWrap="wrap" alignItems="center">
            {price.custom_per_unit_pricing?.map((unitPrice, unitIndex) => (
              <Grid item key={unitIndex}>
                <FormTextField
                  type="number"
                  isFieldUpdate
                  width={110}
                  fieldName={`price[${index}].custom_per_unit_pricing[${unitIndex}]`}
                  disabled={formIsDisabled}
                  label={`${unitIndex + 1} ${unitIndex === 0 ? t('editOrderPage.person') : t('editOrderPage.persons')}`}
                  endAdornment={
                    <IconButton
                      disabled={formIsDisabled}
                      onClick={() => handleRemovePrice(form, unitIndex)}
                      size="small">
                      <Clear />
                    </IconButton>
                  }
                  startAdornment={currencySymbol}
                />
              </Grid>
            ))}
            <Grid item>
              <Button
                disabled={formIsDisabled}
                onClick={() => handleAddPrice(form)}
                variant="outlined"
                sx={{ height: '40px' }}>
                <Add />
              </Button>
            </Grid>
          </Grid>
        )}
      </Field>
      <Grid container alignItems="center" mt={1}>
        <Typography>{t('editOrderPage.forAdditional')}</Typography>
        <Grid item mx={1}>
          <FormTextField
            type="number"
            isFieldUpdate
            width={100}
            fieldName={`price[${index}].price_per_additional_value`}
            disabled={formIsDisabled}
            endAdornment={currencySymbol}
            label={''}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
