/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum EmailTemplateType {
  CONFIRMATION = 'confirmation',
  PRE = 'pre',
  POST = 'post',
  VOUCHER = 'voucher',
  CANCELLATION = 'cancellation',
}
