import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Divider, Grid } from '@mui/material';
import React from 'react';
import { Form, Formik } from 'formik';
import { omit } from 'lodash';
import { usePostHog } from 'posthog-js/react';
import { KeyedMutator } from 'swr/_internal';
import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
import { AccountData, AccountService, OrderPageData, PluginData, PluginTypes } from '../../../api';
import { OrderPagePrice, OrderPageUpdate } from '../../OrderPageSettingsPage';
import { UseAuthPermissions } from '../../../session/UseAuthPermissions';
import { FormTextField } from '../../formComponents/FormTextField';
import { getCurrencySymbol } from '../../../order_page/utils';
import { useAuth } from '../../../session/InternalAuthProvider';
import { FormCheckbox } from '../../formComponents/FormCheckBox';
import { isBasePricing, isCustomUnitPricing, isPersonsNumberPrice } from './utils';
import { BasePricingFields } from './BasePricingFields';
import { CustomUnitPricingFields } from './CustomUnitPricingFields';

type PricingSectionProps = {
  orderPage: OrderPageData;
  orderPageInitialValues: OrderPageUpdate;
  updateLocalAccount: KeyedMutator<AccountData>;
  setIsUpdatedSnackOpen: (value: boolean) => void;
};

export type PricingFormValues = {
  price: OrderPagePrice[];
  weekend_percentage_enabled?: boolean;
  weekend_percentage?: number;
};

function parseToPerc(value: number): number {
  return Math.round(value * 100 - 100);
}

function parseToDecimal(value: number): number {
  return 1 + value / 100;
}

export const PricingSection = ({
  orderPage,
  orderPageInitialValues,
  updateLocalAccount,
  setIsUpdatedSnackOpen,
}: PricingSectionProps) => {
  const { t } = useTranslation();
  const { authState } = useAuth();

  const { readOnly } = UseAuthPermissions();
  const posthog = usePostHog();
  const currencySymbol = getCurrencySymbol(authState.account as unknown as AccountData);
  const validationSchema = yup.object().shape({
    weekend_percentage_enabled: yup.boolean(),
    weekend_percentage: yup
      .number()

      .when('weekend_percentage_enabled', {
        is: true,
        then: yup
          .number()
          .required('Weekend percentage is required')
          .moreThan(0, 'Percentage must be more than 0')
          .lessThan(100, 'Percentage must be less than 100'),
      }),
  });
  const weekend_percentage_enabled = orderPage.plugins?.find(
    (plugin: PluginData) => plugin.type === PluginTypes.WEEKEND_AND_ISRAELI_HOLIDAYS,
  );
  const initialValues: PricingFormValues = {
    price: orderPage.price as OrderPagePrice[],
    weekend_percentage_enabled: !!weekend_percentage_enabled,
    // @ts-ignore
    weekend_percentage: weekend_percentage_enabled ? parseToPerc(weekend_percentage_enabled.multiply) : 0,
  };

  const handleSubmit = async (values: PricingFormValues) => {
    try {
      posthog.capture('Editing settings', { section: 'pricingSection', settings: values });
      const updatedAccount = await AccountService.updateOrderPage(
        omit(
          {
            ...orderPageInitialValues,
            duration: (orderPageInitialValues.duration || 0) * 60,
            ...values,
            weekend_percentage: parseToDecimal(values.weekend_percentage || 0),
            id: orderPage.id,
          },
          'availability_settings.restrictions',
          'objects',
        ),
      );
      await updateLocalAccount(updatedAccount);
      setIsUpdatedSnackOpen(true);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({ values, isSubmitting, dirty, handleSubmit }) => {
        const formIsDisabled = readOnly || isSubmitting;
        return (
          <Form onSubmit={handleSubmit}>
            <Grid container justifyContent="space-between" flexWrap="nowrap" alignItems="flex-start">
              <Grid container flexDirection="column" gap={1}>
                {values.price.map((price, i) => {
                  if (isPersonsNumberPrice(price) && isBasePricing(price)) {
                    return (
                      <BasePricingFields
                        key={i}
                        price={price}
                        index={i}
                        formIsDisabled={formIsDisabled}
                        currencySymbol={currencySymbol}
                      />
                    );
                  }
                  if (isCustomUnitPricing(price)) {
                    return (
                      <CustomUnitPricingFields
                        key={i}
                        price={price}
                        index={i}
                        formIsDisabled={formIsDisabled}
                        currencySymbol={currencySymbol}
                      />
                    );
                  }
                  return (
                    <Grid>
                      <Typography>{t('editOrderPage.notSupported')}</Typography>
                    </Grid>
                  );
                })}
                <Grid width="200px" my={4}>
                  <Divider />
                </Grid>
                <Grid container flexDirection="column">
                  <Typography fontWeight={'700'}>{t('editOrderPage.additions')}</Typography>
                  <Grid container flexWrap="nowrap" gap={2} alignItems="center">
                    <Grid item>
                      <FormCheckbox
                        label={t('editOrderPage.weekendNHolidays')}
                        fieldName={'weekend_percentage_enabled'}
                        disabled={formIsDisabled}
                        isFieldUpdate
                      />
                    </Grid>

                    <Grid item>
                      <FormTextField
                        width={70}
                        fieldName={'weekend_percentage'}
                        disabled={formIsDisabled || !values.weekend_percentage_enabled}
                        isFieldUpdate
                        endAdornment={'%'}
                      />
                    </Grid>

                    <Grid item>
                      <Typography variant="caption">{t('editOrderPage.notobvious')}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <LoadingButton
                size="large"
                loading={isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
                disabled={!dirty || formIsDisabled}>
                {t('editOrderPage.orderPageBufferSubmit')}
              </LoadingButton>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
