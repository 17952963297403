import React, { useCallback, useState } from 'react';
import { Alert, Grid, useMediaQuery, useTheme } from '@mui/material';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useLanguage } from '../../common/GeneralUtils';
import NumberSelector from '../../common/ui/NumberSelector';
import { OrderPageTypography } from '../themedComponents/OrderPageTypography';
import { PartialLabelData, PriceTypes, UIObjectData } from '../../api';
import { OrderPageState } from '../orderPageContext/OrderPageState';
import { MULTIPLE_TICKETS_DETAILS_FIELD_NAME } from '../OrderUtils';
import { priceFormatter } from '../utils';

type TicketData = {
  id: string;
  label: PartialLabelData;
};

type MultipleTicketsUIObjectData = UIObjectData & {
  min_total_participants: number;
  max_total_participants?: number;
  label: PartialLabelData;
  dashboard_no_limit?: boolean;
  tickets: TicketData[];
};

type MultipleTicketsUiObjectProps = {
  uiObject: MultipleTicketsUIObjectData;
  orderState: OrderPageState;
  setMultipleTicketsDetails: (values: Record<string, number>) => void;
  isLoadingDates: boolean;
};

export const MultipleTicketsUiObject = ({
  uiObject,
  orderState,
  setMultipleTicketsDetails,
  isLoadingDates,
}: MultipleTicketsUiObjectProps) => {
  const language = useLanguage();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const initialValues = uiObject.tickets.reduce((acc, ticket) => {
    const multipleTickets = orderState.details.custom_details[MULTIPLE_TICKETS_DETAILS_FIELD_NAME] as Record<
      string,
      number
    >;
    if (multipleTickets) {
      acc[ticket.id] = multipleTickets[ticket.id] || 0;
    } else {
      acc[ticket.id] = 0;
    }

    return acc;
  }, {} as Record<string, number>);

  const [ticketValues, setTicketValues] = useState(initialValues);

  const debouncedSetMultipleTicketsDetails = useCallback(
    debounce((newValues: Record<string, number>) => {
      setMultipleTicketsDetails(newValues);
    }, 300),
    [setMultipleTicketsDetails],
  );

  const handleValueChange = (id: string, value: number) => {
    const newValues = { ...ticketValues, [id]: value };
    setTicketValues(newValues);
    debouncedSetMultipleTicketsDetails(newValues);
  };
  const prices = orderState.orderPage.price.find((x: any) => x.type === PriceTypes.MULTIPLE_TICKETS_PRICE)?.options;
  const totalTickets = Object.values(ticketValues).reduce((sum, val) => sum + val, 0);
  const isAtMaxParticipants = !!uiObject.max_total_participants && totalTickets >= uiObject.max_total_participants;
  const shouldShowMinAlert = totalTickets < uiObject.min_total_participants;
  return (
    <Grid container flexDirection="column" mb={3} gap={1}>
      <OrderPageTypography variant="h3" bold>
        {uiObject.label[language]}
      </OrderPageTypography>
      {shouldShowMinAlert && (
        <Alert severity={'warning'} variant={'outlined'} sx={{ maxWidth: '450px' }}>
          <Grid item>
            <OrderPageTypography variant={'body1'} color={'warning'}>
              {t('multipleTicketsUiObject.minAlert', { min: uiObject.min_total_participants })}
            </OrderPageTypography>
          </Grid>
        </Alert>
      )}
      <Grid container flexDirection={'column'} gap={3} xs={isSmallScreen ? 12 : 8}>
        {uiObject.tickets.map((ticket) => (
          <Grid container justifyContent="space-between" key={ticket.id} alignItems="center">
            <Grid gap={1}>
              <OrderPageTypography variant={'h3'}>{ticket.label[language]}</OrderPageTypography>
              <OrderPageTypography variant={'h6'} color="border">
                {t('multipleTicketsUiObject.perperson', {
                  price: priceFormatter(
                    prices.find((x: any) => x.ticket_id === ticket.id).price_per_unit,
                    orderState.account,
                  ),
                })}
              </OrderPageTypography>
            </Grid>
            <NumberSelector
              isRemoveDisabled={ticketValues[ticket.id] === 0}
              isAddButtonDisabled={isAtMaxParticipants}
              value={ticketValues[ticket.id]}
              setValue={(val: number) => handleValueChange(ticket.id, val)}
              disabled={isLoadingDates}
              min={0}
              max={uiObject.max_total_participants}
            />
          </Grid>
        ))}
      </Grid>
      {isAtMaxParticipants && (
        <Alert severity={'warning'} variant={'outlined'} sx={{ maxWidth: '350px' }}>
          <Grid item>
            <OrderPageTypography variant={'body1'} color={'warning'}>
              {t('multipleTicketsUiObject.maxAlert', { max: uiObject.max_total_participants })}
            </OrderPageTypography>
          </Grid>
        </Alert>
      )}
    </Grid>
  );
};
