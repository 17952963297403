import React, { useState } from 'react';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Grid, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { Delete, Edit, RemoveCircleOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { UploadWidgetResult } from '@bytescale/upload-widget';
import { useDragDropContext } from './DragDropProvider';
import DroppableContainer from './DroppableContainer';
import { SortableItem } from './SortableItem';
import ConfirmationDialog from '../../../common/ui/ConfimationDialog';
import { DraggableItem, ImageItem } from './DraggableItems';
import { BuildingBlockType, Language } from '../../../api';
import { useLanguage } from '../../../common/GeneralUtils';
import { EditTextDialog } from './EditTextDialog';
import { ImageUploadWidget } from './bytescale/ImageUploadWidget';

type EmailTemplateTemplateDndProps = {
  selectedLanguage: Language;
  borderColor?: string;
  linkColor?: string;
};
export const EmailTemplateTemplateDnd = ({
  selectedLanguage,
  borderColor,
  linkColor,
}: EmailTemplateTemplateDndProps) => {
  const { t } = useTranslation();

  const theme = useTheme();
  const systemLanguage = useLanguage();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { items, updateItem, removeItem } = useDragDropContext();

  const [selectedItem, setSelectedItem] = useState<DraggableItem | undefined>();
  const [isEditItemDialogOpen, setIsEditItemDialogOpen] = useState(false);
  const [isRemoveDialogOpen, setIsRemoveDialogOpen] = useState(false);
  const gridDirection = selectedLanguage === systemLanguage ? 'row' : 'row-reverse';

  const onImageUpload = (item: DraggableItem, files: UploadWidgetResult[]) => {
    const url = files[0]?.fileUrl;
    if (url) {
      updateItem({ ...item, src: url } as ImageItem);
    }
  };
  const onPhotoRemove = (item: DraggableItem) => {
    updateItem({ ...item, src: '' } as ImageItem);
  };
  return (
    <Grid container>
      <DroppableContainer id="droppable">
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          <Grid container flexDirection="column" gap={2} flexWrap="nowrap">
            {items.map((item) => (
              <SortableItem key={item.id} id={item.id} staticBlock={item.isStatic} gridDirection={gridDirection}>
                <Grid container alignItems="center" justifyContent="space-between" flexDirection={gridDirection}>
                  <Grid xs={isSmallScreen ? 12 : 10}>
                    {item.render({ t, isSmallScreen, gridDirection, borderColor, linkColor })}
                  </Grid>
                  {!item.isStatic && !isSmallScreen && (
                    <Grid container flexWrap="nowrap" xs={2} alignItems="center">
                      {item?.type === BuildingBlockType.TEXT && (
                        <IconButton
                          onClick={() => {
                            setSelectedItem(item);
                            setIsEditItemDialogOpen(true);
                          }}>
                          <Edit />
                        </IconButton>
                      )}
                      {item?.type === BuildingBlockType.IMAGE &&
                        ((item as ImageItem).src?.length > 0 ? (
                          <IconButton onClick={() => onPhotoRemove(item)}>
                            <RemoveCircleOutline />
                          </IconButton>
                        ) : (
                          <ImageUploadWidget
                            onImageUpload={(files: UploadWidgetResult[]) => onImageUpload(item, files)}
                          />
                        ))}
                      <IconButton
                        onClick={() => {
                          setSelectedItem(item);
                          setIsRemoveDialogOpen(true);
                        }}>
                        <Delete />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </SortableItem>
            ))}
          </Grid>
        </SortableContext>
      </DroppableContainer>
      {selectedItem?.type === BuildingBlockType.TEXT && (
        <EditTextDialog
          isEditItemDialogOpen={isEditItemDialogOpen}
          setSelectedItem={setSelectedItem}
          selectedLanguage={selectedLanguage}
          selectedItem={selectedItem}
          setIsEditItemDialogOpen={setIsEditItemDialogOpen}
          updateItem={updateItem}
        />
      )}
      <ConfirmationDialog
        title={t('emailTemplates.deleteDialog.title')}
        content={<Typography>{t('userAvailability.deleteDialog.final')}</Typography>}
        isOpen={isRemoveDialogOpen}
        onClose={(actionConfirmed) => {
          setIsRemoveDialogOpen(false);
          if (actionConfirmed) {
            void removeItem(selectedItem!);
          }
        }}
      />
    </Grid>
  );
};
