import { Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { WorkingDays } from '../../experienceSettings/workingSchedule/WorkingDays';
import { PermanentUserAvailabilityDTO, UserAvailabilityService, UserPreferencesOut } from '../../api';
import RotatingLoader from '../../common/ui/RotatingLoader';

type PermanentUserAvailabilityProps = {
  userPreferences?: UserPreferencesOut;
  isLoadingUserPreferences: boolean;
  refetchUserPrefs: () => Promise<any>;
  selectedUserId?: string;
};

export const PermanentUserAvailability = ({
  userPreferences,
  isLoadingUserPreferences,
  refetchUserPrefs,
  selectedUserId,
}: PermanentUserAvailabilityProps) => {
  const { t } = useTranslation();
  const [isRefetching, setIsRefetching] = useState(true);
  useEffect(() => {
    const refetchDataWithNewUser = async () => {
      setIsRefetching(true);
      await refetchUserPrefs();
      setIsRefetching(false);
    };

    void refetchDataWithNewUser();
  }, [selectedUserId]);

  if (isLoadingUserPreferences || isRefetching) {
    return <RotatingLoader />;
  }
  const initialValues: PermanentUserAvailabilityDTO = {
    working_days: userPreferences!.working_days!,
    user_id: selectedUserId!,
  };

  const handleSubmit = async (
    values: PermanentUserAvailabilityDTO,
    { setSubmitting }: FormikHelpers<PermanentUserAvailabilityDTO>,
  ) => {
    try {
      await UserAvailabilityService.editPermanentUserAvailability({
        working_days: values.working_days,
        user_id: selectedUserId!,
      });
      await refetchUserPrefs();
    } catch (error) {
      console.error('Failed to update user preferences:', error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, setFieldValue, isSubmitting }) => (
        <Form>
          <Grid container flexDirection="column">
            <WorkingDays
              readOnly={false}
              initialWorkingDays={initialValues.working_days}
              workingDays={values.working_days}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
              hideFixedOption
              title={t('userAvailability.standardSchedule')}
            />
            <Grid container justifyContent="flex-end" mt={2}>
              <Button type="submit" variant="contained" disabled={isSubmitting}>
                {t('save')}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
