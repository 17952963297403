import React, { useState } from 'react';
import { AccordionDetails, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { AccountData, OrderData, OrderPageData } from '../../../../../api';
import PersonalDetailsStep, { PageContext } from '../../../../../order_page/steps/PersonalDetailsStep';
import { Accordion, AccordionPanelsNames, AccordionSummary } from '../Accordion';
import { OrderPageIntent } from '../../../../../order_page/OrderPageIntent';
import { useAuth } from '../../../../../session/InternalAuthProvider';
import { DATE_FORMAT, TIME_FORMAT } from '../../../../consts';

type PersonalDetailsPanelProps = {
  orderOrVoucher: Partial<OrderData> & {
    voucher_greeting_message?: string;
    recipient_email?: string;
    delivery_timestamp?: string;
  };
  personalDetailsFormRef: React.RefObject<any>;
  orderPage: OrderPageData;
  isOneOfVoucherIntents?: boolean;
  setOrderCustomDetails: (details: any) => void;
  expanded?: AccordionPanelsNames;
  handleChange: any;
};
export const PersonalDetailsPanel = ({
  orderOrVoucher,
  personalDetailsFormRef,
  orderPage,
  isOneOfVoucherIntents,
  setOrderCustomDetails,
  expanded,
  handleChange,
}: PersonalDetailsPanelProps) => {
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const { t } = useTranslation();
  const [previewName, setPreviewName] = useState(`${orderOrVoucher?.firstname} ${orderOrVoucher?.lastname}`);
  return (
    <Accordion
      onChange={handleChange}
      expandedPanelName={expanded}
      panelName={AccordionPanelsNames.PersonalDetailsPanel}>
      <AccordionSummary title={t('calendar.edit_order.personal')} summaryComponent={<Grid>{previewName}</Grid>} />
      <AccordionDetails>
        <PersonalDetailsStep
          formRef={personalDetailsFormRef}
          orderPage={orderPage}
          firstname={orderOrVoucher.firstname}
          lastname={orderOrVoucher.lastname}
          email={orderOrVoucher.email}
          phone={orderOrVoucher.phone}
          custom_personal_details={orderOrVoucher.custom_personal_details}
          orderPageIntent={isOneOfVoucherIntents ? OrderPageIntent.GIFT_CARD : OrderPageIntent.BUY_NOW}
          setOrderCustomDetails={setOrderCustomDetails}
          pageContext={PageContext.ADMIN}
          setPreviewName={setPreviewName}
          orderCustomDetails={orderOrVoucher.custom_details}
          voucherGreetingMessage={orderOrVoucher.voucher_greeting_message}
          recipientEmail={orderOrVoucher.recipient_email}
          referralSource={orderOrVoucher.referral_source}
          deliveryDate={
            orderOrVoucher.delivery_timestamp
              ? moment.utc(orderOrVoucher.delivery_timestamp).tz(account.timezone).format(DATE_FORMAT)
              : undefined
          }
          deliveryTime={
            orderOrVoucher.delivery_timestamp
              ? moment.utc(orderOrVoucher.delivery_timestamp).tz(account.timezone).format(TIME_FORMAT)
              : undefined
          }
          holidayCalendar={account.holiday_calendar}
        />
      </AccordionDetails>
    </Accordion>
  );
};
