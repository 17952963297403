import { Grid, Skeleton } from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const BoxSkeleton = () => {
  return (
    <Grid container padding={2} mt={-1.8}>
      <Skeleton width="30px" height="27px" />
      <Skeleton width="100px" height="27px" />
      <Skeleton width="100px" height="27px" />
    </Grid>
  );
};
export const StyledGrid = styled(Grid)(() => ({
  width: '130px',
  height: '100px',
  borderRadius: '8px',
  border: '1px solid #CBCBCB',
  gap: '4px',
  padding: '8px',
  flexDirection: 'column',
}));
type DailyDashboardBoxProps = {
  isLoading: boolean;
  value?: number;
  personNumber?: number;
  title: string;
  units?: string;
};
export const DailyDashboardBox = ({ isLoading, value, title, units, personNumber }: DailyDashboardBoxProps) => {
  const { t } = useTranslation();

  return isLoading ? (
    <StyledGrid item container>
      <BoxSkeleton />
    </StyledGrid>
  ) : (
    <StyledGrid item container flexWrap="nowrap">
      <Typography fontWeight={700}>{title}</Typography>
      <Grid item container gap={0.5} alignItems="flex-end" flexWrap="nowrap" flexDirection="column">
        <Grid container gap={0.5}>
          <Typography variant="subtitle2">{value || 0}</Typography>
          {units && (
            <Typography variant="subtitle2" fontWeight={100}>
              {units.toLowerCase()}
            </Typography>
          )}
        </Grid>
        {personNumber && personNumber > 0 ? (
          <Grid container gap={0.5}>
            <Typography variant="subtitle2">{personNumber || 0}</Typography>
            <Typography variant="subtitle2" fontWeight={300}>
              {t('dashboard.totalPersons').toLowerCase()}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </StyledGrid>
  );
};
